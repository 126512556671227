@charset "UTF-8";
/**
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the BSD-3 License as modified (the “License”); you may obtain a copy
 * of the license at https://github.com/palantir/blueprint/blob/master/LICENSE
 * and https://github.com/palantir/blueprint/blob/master/PATENTS
 */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
@font-face {
  font-family: "Icons16";
  font-style: normal;
  font-weight: normal;
  src: url("../resources/icons/icons-16.eot?#iefix") format("embedded-opentype"), url("../resources/icons/icons-16.woff") format("woff"), url("../resources/icons/icons-16.ttf") format("truetype"); }

@font-face {
  font-family: "Icons20";
  font-style: normal;
  font-weight: normal;
  src: url("../resources/icons/icons-20.eot?#iefix") format("embedded-opentype"), url("../resources/icons/icons-20.woff") format("woff"), url("../resources/icons/icons-20.ttf") format("truetype"); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
hr {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid rgba(16, 22, 26, 0.15); }
  .pt-dark hr {
    border-color: rgba(255, 255, 255, 0.15); }

::selection {
  background: rgba(125, 188, 255, 0.6); }

body {
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #182026; }

small {
  font-size: 12px; }

/*
Fonts

Markup:
<div class="{{.modifier}}">Blueprint components react overlay date picker.</div>

.pt-monospace-text - Use a monospace font (ideal for code)

Styleguide fonts
*/
.pt-monospace-text {
  text-transform: none;
  font-family: monospace;
  font-size: smaller; }

/*
Headings

Markup:
<h1>H1 heading</h1>
<h2>H2 heading</h2>
<h3>H3 heading</h3>
<h4>H4 heading</h4>
<h5>H5 heading</h5>
<h6>H6 heading</h6>

Styleguide headings
*/
h1 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.8em;
  font-size: 40px; }

h2 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.8em;
  font-size: 27px; }

h3 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.8em;
  font-size: 24px; }

h4 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.9em;
  font-size: 20px; }

h5 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.9em;
  font-size: 17px; }

h6 {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.9em;
  font-size: 15px; }

/*
UI text

Markup:
<div class="{{.modifier}}">Blueprint components react overlay date picker.</div>

.pt-ui-text - Default UI text. This is applied to the document body as part of core styles.
.pt-ui-text-large - Larger UI text.

Styleguide pt-ui-text
*/
.pt-ui-text {
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
  font-size: 14px;
  font-weight: 400; }

.pt-ui-text-large {
  line-height: 1.25;
  font-size: 16px; }

/*
Running text

Markup:
<div class="{{.modifier}}">
  <p>Longform text, such as rendered Markdown documents, benefit from additional spacing and slightly
large font size. Apply <code>.pt-running-text</code> to the parent element to adjust spacing for the following
elements:</p>
  <ul>
    <li><code>&lt;p></code> tags have increased line-height and font size.</li>
    <li><code>&lt;h*></code> tag margins are adjusted to provide clear separation between sections in a document.</li>
    <li><code>&lt;ul></code> and <code>&lt;ol></code> tags receive [`.pt-list`](#typography.lists) styles for legibility.</li>
  </ul>
  <h3>New section</h3>
  <p>And another paragraph.</p>
</div>

.pt-running-text - Apply larger font size and additional spacing.

Styleguide pt-running-text
*/
.pt-running-text {
  line-height: 1.5;
  font-size: 16px; }
  .pt-running-text h1 {
    margin-top: 40px;
    margin-bottom: 20px; }
  .pt-running-text h2 {
    margin-top: 40px;
    margin-bottom: 20px; }
  .pt-running-text h3 {
    margin-top: 40px;
    margin-bottom: 20px; }
  .pt-running-text h4 {
    margin-top: 40px;
    margin-bottom: 20px; }
  .pt-running-text h5 {
    margin-top: 40px;
    margin-bottom: 20px; }
  .pt-running-text h6 {
    margin-top: 40px;
    margin-bottom: 20px; }

.pt-running-text-small {
  line-height: 1.5;
  font-size: 14px; }

p {
  margin: 0 0 10px;
  padding: 0; }

/*
Links

Simply use an `<a href="">` tag as you normally would. No class is necessary for Blueprint styles.
Links are underlined only when hovered.

Putting an icon inside a link will cause it to inherit the link's text color.

Styleguide typography.links
*/
a {
  text-decoration: none;
  color: #106ba3; }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #106ba3; }
  a .pt-icon, a .pt-icon-standard, a .pt-icon-large {
    color: inherit; }
  a code,
  .pt-dark a code {
    color: inherit; }
  .pt-dark a,
  .pt-dark a:hover {
    color: #48aff0; }
    .pt-dark a .pt-icon, .pt-dark a .pt-icon-standard, .pt-dark a .pt-icon-large,
    .pt-dark a:hover .pt-icon,
    .pt-dark a:hover .pt-icon-standard,
    .pt-dark a:hover .pt-icon-large {
      color: inherit; }

/*
Preformatted text

Markup:
<code>$ npm install</code>
<pre>
%pt-select {
  @include pt-button();
  @include prefixer(appearance, none, webkit moz);
  border-radius: $pt-border-radius;
  height: $pt-button-height;
  padding: 0 ($input-padding-horizontal * 3) 0 $input-padding-horizontal;
}
</pre>
<pre><code>export function hasModifier(modifiers: ts.ModifiersArray, ...modifierKinds: ts.SyntaxKind[]) {
    if (modifiers == null || modifierKinds == null) {
        return false;
    }
    return modifiers.some((m) => {
        return modifierKinds.some((k) => m.kind === k);
    });
}</code></pre>

Styleguide preformatted
*/
pre,
code {
  text-transform: none;
  font-family: monospace;
  font-size: smaller; }

code {
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2);
  background: rgba(255, 255, 255, 0.7);
  padding: 2px 5px;
  color: #5c7080; }
  .pt-dark code {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
    color: #bfccd6; }

pre {
  display: block;
  margin: 10px 0;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15);
  background: rgba(255, 255, 255, 0.7);
  padding: 13px 15px 12px;
  line-height: 1.4;
  color: #182026;
  font-size: 13px;
  word-break: break-all;
  word-wrap: break-word; }
  pre > code {
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    padding: 0;
    white-space: pre-wrap;
    color: inherit;
    font-size: inherit; }
  .pt-dark pre {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
    color: #f5f8fa; }
    .pt-dark pre > code {
      box-shadow: none;
      background: transparent; }

/*
Block quotes

Markup:
<blockquote>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  </p>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  </p>
</blockquote>

Styleguide blockquote
*/
blockquote {
  line-height: 1.5;
  font-size: 16px;
  margin: 0 0 10px;
  border-left: solid 4px rgba(167, 182, 194, 0.5);
  padding: 0 20px; }
  blockquote p:last-child {
    margin-bottom: 0; }
  .pt-dark blockquote {
    border-color: rgba(115, 134, 148, 0.5); }

/*
Lists

Markup:
<ul class="{{.modifier}}">
  <li>Item the first</li>
  <li>Item the second</li>
  <li>Item the third</li>
</ul>
<ol class="{{.modifier}}">
  <li>Item the first</li>
  <li>Item the second</li>
  <li>Item the third</li>
</ol>

.pt-list - Add a little spacing between items for readability.
.pt-list-unstyled - Remove all list styling (including indicators) so you can add your own.

Styleguide lists
*/
ol,
ul {
  margin: 10px 0;
  padding-left: 40px; }

.pt-list li:not(:last-child),
.pt-running-text ul li:not(:last-child),
.pt-running-text ol li:not(:last-child) {
  padding-bottom: 5px; }

.pt-list li :last-child,
.pt-running-text ul li :last-child,
.pt-running-text ol li :last-child {
  margin-bottom: 0; }

.pt-list ol,
.pt-list ul,
.pt-running-text ul ol,
.pt-running-text ul ul,
.pt-running-text ol ol,
.pt-running-text ol ul {
  margin-top: 5px; }

.pt-list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none; }
  .pt-list-unstyled li {
    padding: 0; }

/*
Text utilities

Markup:
<div class="{{.modifier}}" style="width: 320px;">
  Blueprint components react overlay date picker. Breadcrumbs dialog progress non-ideal state.
</div>

.pt-text-muted - Changes text color to a gentler gray.
.pt-text-overflow-ellipsis - Truncates a single line of text with an ellipsis if it overflows its
  container.

Styleguide utilities
*/
.pt-text-muted {
  color: #5c7080; }
  .pt-dark .pt-text-muted {
    color: #bfccd6; }

.pt-text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

/*
Right-to-left text

Markup:
<h4>Arabic:</h4>
<p class="pt-rtl">
  لكل لأداء بمحاولة من. مدينة الواقعة يبق أي, وإعلان وقوعها، حول كل, حدى عجّل مشروط الخاسرة قد.
  من الذود تكبّد بين, و لها واحدة الأراضي. عل الصفحة والروسية يتم, أي للحكومة استعملت شيء. أم وصل زهاء اليا
</p>
<h4>Hebrew:</h4>
<p class="pt-rtl">
  כדי על עזרה יידיש הבהרה, מלא באגים טכניים דת. תנך או ברית ביולי. כתב בה הטבע למנוע, דת כלים פיסיקה החופשית זכר.
  מתן החלל מאמרשיחהצפה ב. הספרות אנציקלופדיה אם זכר, על שימושי שימושיים תאולוגיה עזה
</p>

Styleguide pt-rtl
*/
.pt-rtl {
  text-align: right; }

.pt-dark {
  color: #f5f8fa; }
  .pt-dark h1 {
    color: #f5f8fa; }
  .pt-dark h2 {
    color: #f5f8fa; }
  .pt-dark h3 {
    color: #f5f8fa; }
  .pt-dark h4 {
    color: #f5f8fa; }
  .pt-dark h5 {
    color: #f5f8fa; }
  .pt-dark h6 {
    color: #f5f8fa; }

:focus {
  outline: rgba(19, 124, 189, 0.5) auto 2px;
  outline-offset: 2px;
  -moz-outline-radius: 6px; }

.pt-focus-disabled :focus {
  outline: none !important; }
  .pt-focus-disabled :focus ~ .pt-control-indicator {
    outline: none !important; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
.pt-alert {
  max-width: 400px;
  padding: 20px; }

.pt-alert-body {
  display: flex; }
  .pt-alert-body .pt-icon {
    margin-right: 20px;
    font-size: 40px; }

.pt-alert-footer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px; }
  .pt-alert-footer .pt-button {
    margin-left: 10px; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Breadcrumbs

Markup:
<ul class="pt-breadcrumbs">
  <li><a class="pt-breadcrumbs-collapsed" href="#"></a></li>
  <li><a class="pt-breadcrumb pt-disabled">Folder one</a></li>
  <li><a class="pt-breadcrumb" href="#">Folder two</a></li>
  <li><a class="pt-breadcrumb" href="#">Folder three</a></li>
  <li><span class="pt-breadcrumb pt-breadcrumb-current">File</span></li>
</ul>

Styleguide pt-breadcrumbs
*/
.pt-breadcrumbs {
  display: inline-block;
  margin: 0;
  cursor: default;
  padding: 0;
  list-style: none;
  vertical-align: top;
  line-height: 30px; }
  .pt-breadcrumbs > li {
    float: left; }
    .pt-breadcrumbs > li::after {
      line-height: 1;
      font-family: "Icons16", sans-serif;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      padding: 0 5px;
      color: #5c7080;
      content: ""; }
    .pt-breadcrumbs > li:last-child::after {
      display: none; }

.pt-breadcrumb,
.pt-breadcrumb-current,
.pt-breadcrumbs-collapsed {
  display: inline-block;
  line-height: 19px;
  font-size: 16px; }

.pt-breadcrumb,
.pt-breadcrumbs-collapsed {
  color: #5c7080; }

.pt-breadcrumb:hover {
  text-decoration: none; }

.pt-breadcrumb.pt-disabled {
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.pt-breadcrumb-current {
  color: inherit;
  font-weight: 600; }
  .pt-breadcrumb-current .pt-input {
    vertical-align: baseline;
    font-size: inherit;
    font-weight: inherit; }

.pt-breadcrumbs-collapsed {
  margin-right: 2px;
  border: none;
  border-radius: 3px;
  background: #ced9e0;
  cursor: pointer;
  padding: 0 5px; }
  .pt-breadcrumbs-collapsed::before {
    line-height: 1;
    font-family: "Icons20", sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 19px;
    content: ""; }
  .pt-breadcrumbs-collapsed:hover {
    background: #bfccd6;
    text-decoration: none;
    color: #182026; }

.pt-dark .pt-breadcrumb,
.pt-dark .pt-breadcrumbs-collapsed {
  color: #bfccd6; }

.pt-dark .pt-breadcrumbs > li::after {
  color: #bfccd6; }

.pt-dark .pt-breadcrumb.pt-disabled {
  color: rgba(191, 204, 214, 0.5); }

.pt-dark .pt-breadcrumb-current {
  color: #f5f8fa; }

.pt-dark .pt-breadcrumbs-collapsed {
  background: rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-breadcrumbs-collapsed:hover {
    background: rgba(16, 22, 26, 0.6);
    color: #f5f8fa; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Button

Markup:
<a role="button" class="pt-button {{.modifier}}" {{:modifier}} tabindex="0">Anchor</a>
<button type="button" class="pt-button pt-icon-add {{.modifier}}" {{:modifier}}>Button</button>

:disabled - Disabled state
.pt-disabled - Disabled appearance
.pt-intent-primary - Primary intent
.pt-intent-success - Success intent
.pt-intent-warning - Warning intent
.pt-intent-danger - Danger intent
.pt-active - Active appearance
.pt-large - Larger size
.pt-small - Smaller size
.pt-fill - Fill parent container

Styleguide pt-button
*/
.pt-button {
  display: inline-block;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 10px;
  vertical-align: middle;
  font-size: 14px;
  min-width: 30px;
  min-height: 30px;
  line-height: 30px;
  /*
  Buttons with icons

  Markup:
  <button type="button" class="pt-button pt-icon-add">Default button</button>
  <button type="button" class="pt-button pt-icon-refresh"></button>
  <button type="button" class="pt-button pt-large pt-icon-add">Large button</button>
  <button type="button" class="pt-button pt-large pt-icon-refresh"></button>

  Styleguide pt-button.pt-icon
  */
  /*
  Advanced icon usage

  Markup:
  <button type="button" class="pt-button pt-intent-success">
    Next step
    <span class="pt-icon-standard pt-icon-arrow-right pt-align-right"></span>
  </button>
  <button type="button" class="pt-button">
    <span class="pt-icon-standard pt-icon-user"></span>
    Profile settings
    <span class="pt-icon-standard pt-icon-caret-down pt-align-right"></span>
  </button>
  <button type="button" class="pt-button pt-intent-danger">
    Reset
    <span class="pt-icon-standard pt-icon-refresh pt-align-right"></span>
  </button>
  <button type="button" class="pt-button pt-large">
    <span class="pt-icon-standard pt-icon-document"></span>
    upload.txt
    <span class="pt-icon-standard pt-icon-cross pt-align-right"></span>
  </button>

  Styleguide pt-button.pt-icon-advanced
  */
  /*
  Minimal buttons

  Markup:
  <a role="button" class="pt-button pt-minimal {{.modifier}}" {{:modifier}} tabindex="0">Anchor</a>
  <button type="button" class="pt-button pt-minimal pt-icon-add {{.modifier}}" {{:modifier}}>Button</button>

  .pt-disabled - Disabled appearance
  .pt-intent-primary - Primary intent
  .pt-intent-success - Success intent
  .pt-intent-warning - Warning intent
  .pt-intent-danger - Danger intent

  Styleguide pt-button.pt-minimal
  */ }
  .pt-button:disabled, .pt-button.pt-disabled {
    cursor: not-allowed; }
  .pt-button.pt-fill {
    width: 100%; }
  .pt-button:not([class*="pt-intent-"]) {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-color: #f5f8fa;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    color: #182026; }
    .pt-button:not([class*="pt-intent-"]):hover {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
      background-clip: padding-box;
      background-color: #ebf1f5; }
    .pt-button:not([class*="pt-intent-"]):active, .pt-button:not([class*="pt-intent-"]).pt-active {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #d8e1e8;
      background-image: none; }
    .pt-button:not([class*="pt-intent-"]):disabled, .pt-button:not([class*="pt-intent-"]).pt-disabled {
      outline: none;
      box-shadow: none;
      background-color: rgba(206, 217, 224, 0.5);
      background-image: none;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
      .pt-button:not([class*="pt-intent-"]):disabled.pt-active, .pt-button:not([class*="pt-intent-"]).pt-disabled.pt-active {
        background: rgba(206, 217, 224, 0.7); }
  .pt-button.pt-intent-primary {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    background-color: #137cbd;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #ffffff; }
    .pt-button.pt-intent-primary:hover, .pt-button.pt-intent-primary:active, .pt-button.pt-intent-primary.pt-active {
      color: #ffffff; }
    .pt-button.pt-intent-primary:hover {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
      background-color: #106ba3; }
    .pt-button.pt-intent-primary:active, .pt-button.pt-intent-primary.pt-active {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #0e5a8a;
      background-image: none; }
    .pt-button.pt-intent-primary:disabled, .pt-button.pt-intent-primary.pt-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(19, 124, 189, 0.5);
      background-image: none;
      color: rgba(255, 255, 255, 0.6); }
  .pt-button.pt-intent-success {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    background-color: #0f9960;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #ffffff; }
    .pt-button.pt-intent-success:hover, .pt-button.pt-intent-success:active, .pt-button.pt-intent-success.pt-active {
      color: #ffffff; }
    .pt-button.pt-intent-success:hover {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
      background-color: #0d8050; }
    .pt-button.pt-intent-success:active, .pt-button.pt-intent-success.pt-active {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #0a6640;
      background-image: none; }
    .pt-button.pt-intent-success:disabled, .pt-button.pt-intent-success.pt-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(15, 153, 96, 0.5);
      background-image: none;
      color: rgba(255, 255, 255, 0.6); }
  .pt-button.pt-intent-warning {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    background-color: #d9822b;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #ffffff; }
    .pt-button.pt-intent-warning:hover, .pt-button.pt-intent-warning:active, .pt-button.pt-intent-warning.pt-active {
      color: #ffffff; }
    .pt-button.pt-intent-warning:hover {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
      background-color: #bf7326; }
    .pt-button.pt-intent-warning:active, .pt-button.pt-intent-warning.pt-active {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #a66321;
      background-image: none; }
    .pt-button.pt-intent-warning:disabled, .pt-button.pt-intent-warning.pt-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(217, 130, 43, 0.5);
      background-image: none;
      color: rgba(255, 255, 255, 0.6); }
  .pt-button.pt-intent-danger {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    background-color: #db3737;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #ffffff; }
    .pt-button.pt-intent-danger:hover, .pt-button.pt-intent-danger:active, .pt-button.pt-intent-danger.pt-active {
      color: #ffffff; }
    .pt-button.pt-intent-danger:hover {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
      background-color: #c23030; }
    .pt-button.pt-intent-danger:active, .pt-button.pt-intent-danger.pt-active {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #a82a2a;
      background-image: none; }
    .pt-button.pt-intent-danger:disabled, .pt-button.pt-intent-danger.pt-disabled {
      border-color: transparent;
      box-shadow: none;
      background-color: rgba(219, 55, 55, 0.5);
      background-image: none;
      color: rgba(255, 255, 255, 0.6); }
  .pt-button[class*="pt-intent-"] .pt-button-spinner .pt-spinner-head {
    stroke: #ffffff; }
  .pt-button.pt-loading {
    position: relative; }
    .pt-button.pt-loading[class*="pt-icon-"]::before {
      visibility: hidden; }
    .pt-button.pt-loading .pt-button-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .pt-button.pt-loading > :not(.pt-button-spinner) {
      visibility: hidden; }
  .pt-button[class*="pt-icon-"]::before {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin-right: 7px;
    color: #5c7080; }
  .pt-button .pt-icon, .pt-button .pt-icon-standard, .pt-button .pt-icon-large {
    margin-right: 7px;
    color: #5c7080; }
    .pt-button .pt-icon.pt-align-right, .pt-button .pt-icon-standard.pt-align-right, .pt-button .pt-icon-large.pt-align-right {
      margin-right: 0;
      margin-left: 7px; }
  .pt-dark .pt-button:not([class*="pt-intent-"]) {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    background-color: #394b59;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    color: #f5f8fa; }
    .pt-dark .pt-button:not([class*="pt-intent-"]):hover, .pt-dark .pt-button:not([class*="pt-intent-"]):active, .pt-dark .pt-button:not([class*="pt-intent-"]).pt-active {
      color: #f5f8fa; }
    .pt-dark .pt-button:not([class*="pt-intent-"]):hover {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
      background-color: #30404d; }
    .pt-dark .pt-button:not([class*="pt-intent-"]):active, .pt-dark .pt-button:not([class*="pt-intent-"]).pt-active {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #202b33;
      background-image: none; }
    .pt-dark .pt-button:not([class*="pt-intent-"]):disabled, .pt-dark .pt-button:not([class*="pt-intent-"]).pt-disabled {
      box-shadow: none;
      background-color: rgba(57, 75, 89, 0.5);
      background-image: none;
      color: rgba(191, 204, 214, 0.5); }
      .pt-dark .pt-button:not([class*="pt-intent-"]):disabled.pt-active, .pt-dark .pt-button:not([class*="pt-intent-"]).pt-disabled.pt-active {
        background: rgba(57, 75, 89, 0.7); }
    .pt-dark .pt-button:not([class*="pt-intent-"]) .pt-button-spinner .pt-spinner-head {
      background: rgba(16, 22, 26, 0.5);
      stroke: #8a9ba8; }
    .pt-dark .pt-button:not([class*="pt-intent-"])[class*="pt-icon-"]::before {
      color: #bfccd6; }
    .pt-dark .pt-button:not([class*="pt-intent-"]) .pt-icon, .pt-dark .pt-button:not([class*="pt-intent-"]) .pt-icon-standard, .pt-dark .pt-button:not([class*="pt-intent-"]) .pt-icon-large {
      color: #bfccd6; }
  .pt-dark .pt-button[class*="pt-intent-"] {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-button[class*="pt-intent-"]:hover {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-button[class*="pt-intent-"]:active, .pt-dark .pt-button[class*="pt-intent-"].pt-active {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .pt-dark .pt-button[class*="pt-intent-"]:disabled, .pt-dark .pt-button[class*="pt-intent-"].pt-disabled {
      box-shadow: none;
      background-image: none;
      color: rgba(255, 255, 255, 0.3); }
    .pt-dark .pt-button[class*="pt-intent-"] .pt-button-spinner .pt-spinner-head {
      stroke: #8a9ba8; }
  .pt-button:disabled::before, .pt-button.pt-disabled::before, .pt-button[class*="pt-intent-"]::before {
    color: inherit !important; }
  .pt-button:disabled .pt-icon, .pt-button:disabled .pt-icon-standard, .pt-button:disabled .pt-icon-large, .pt-button.pt-disabled .pt-icon, .pt-button.pt-disabled .pt-icon-standard, .pt-button.pt-disabled .pt-icon-large, .pt-button[class*="pt-intent-"] .pt-icon, .pt-button[class*="pt-intent-"] .pt-icon-standard, .pt-button[class*="pt-intent-"] .pt-icon-large {
    color: inherit !important; }
  .pt-button.pt-minimal {
    box-shadow: none;
    background: none; }
    .pt-button.pt-minimal:hover {
      box-shadow: none;
      background: rgba(167, 182, 194, 0.3);
      text-decoration: none;
      color: #182026; }
    .pt-button.pt-minimal:active, .pt-button.pt-minimal.pt-active {
      box-shadow: none;
      background: rgba(115, 134, 148, 0.3);
      color: #182026; }
    .pt-button.pt-minimal:disabled, .pt-button.pt-minimal:disabled:hover, .pt-button.pt-minimal.pt-disabled, .pt-button.pt-minimal.pt-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
    .pt-dark .pt-button.pt-minimal {
      box-shadow: none;
      background: none;
      color: inherit; }
      .pt-dark .pt-button.pt-minimal:hover, .pt-dark .pt-button.pt-minimal:active, .pt-dark .pt-button.pt-minimal.pt-active {
        box-shadow: none;
        background: none; }
      .pt-dark .pt-button.pt-minimal:hover {
        background: rgba(138, 155, 168, 0.15); }
      .pt-dark .pt-button.pt-minimal:active, .pt-dark .pt-button.pt-minimal.pt-active {
        background: rgba(138, 155, 168, 0.3);
        color: #f5f8fa; }
      .pt-dark .pt-button.pt-minimal:disabled, .pt-dark .pt-button.pt-minimal:disabled:hover, .pt-dark .pt-button.pt-minimal.pt-disabled, .pt-dark .pt-button.pt-minimal.pt-disabled:hover {
        background: none;
        cursor: not-allowed;
        color: rgba(191, 204, 214, 0.5); }
    .pt-button.pt-minimal.pt-intent-primary {
      color: #106ba3; }
      .pt-button.pt-minimal.pt-intent-primary:hover, .pt-button.pt-minimal.pt-intent-primary:active, .pt-button.pt-minimal.pt-intent-primary.pt-active {
        box-shadow: none;
        background: none;
        color: #106ba3; }
      .pt-button.pt-minimal.pt-intent-primary:hover {
        background: rgba(19, 124, 189, 0.15);
        color: #106ba3; }
      .pt-button.pt-minimal.pt-intent-primary:active, .pt-button.pt-minimal.pt-intent-primary.pt-active {
        background: rgba(19, 124, 189, 0.3);
        color: #106ba3; }
      .pt-button.pt-minimal.pt-intent-primary:disabled, .pt-button.pt-minimal.pt-intent-primary.pt-disabled {
        background: none;
        color: rgba(16, 107, 163, 0.5); }
      .pt-button.pt-minimal.pt-intent-primary .pt-button-spinner .pt-spinner-head {
        stroke: #106ba3; }
      .pt-dark .pt-button.pt-minimal.pt-intent-primary {
        color: #48aff0; }
        .pt-dark .pt-button.pt-minimal.pt-intent-primary:hover {
          background: rgba(19, 124, 189, 0.2);
          color: #48aff0; }
        .pt-dark .pt-button.pt-minimal.pt-intent-primary:active, .pt-dark .pt-button.pt-minimal.pt-intent-primary.pt-active {
          background: rgba(19, 124, 189, 0.3);
          color: #48aff0; }
        .pt-dark .pt-button.pt-minimal.pt-intent-primary:disabled, .pt-dark .pt-button.pt-minimal.pt-intent-primary.pt-disabled {
          background: none;
          color: rgba(72, 175, 240, 0.5); }
    .pt-button.pt-minimal.pt-intent-success {
      color: #0d8050; }
      .pt-button.pt-minimal.pt-intent-success:hover, .pt-button.pt-minimal.pt-intent-success:active, .pt-button.pt-minimal.pt-intent-success.pt-active {
        box-shadow: none;
        background: none;
        color: #0d8050; }
      .pt-button.pt-minimal.pt-intent-success:hover {
        background: rgba(15, 153, 96, 0.15);
        color: #0d8050; }
      .pt-button.pt-minimal.pt-intent-success:active, .pt-button.pt-minimal.pt-intent-success.pt-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .pt-button.pt-minimal.pt-intent-success:disabled, .pt-button.pt-minimal.pt-intent-success.pt-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
      .pt-button.pt-minimal.pt-intent-success .pt-button-spinner .pt-spinner-head {
        stroke: #0d8050; }
      .pt-dark .pt-button.pt-minimal.pt-intent-success {
        color: #3dcc91; }
        .pt-dark .pt-button.pt-minimal.pt-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #3dcc91; }
        .pt-dark .pt-button.pt-minimal.pt-intent-success:active, .pt-dark .pt-button.pt-minimal.pt-intent-success.pt-active {
          background: rgba(15, 153, 96, 0.3);
          color: #3dcc91; }
        .pt-dark .pt-button.pt-minimal.pt-intent-success:disabled, .pt-dark .pt-button.pt-minimal.pt-intent-success.pt-disabled {
          background: none;
          color: rgba(61, 204, 145, 0.5); }
    .pt-button.pt-minimal.pt-intent-warning {
      color: #bf7326; }
      .pt-button.pt-minimal.pt-intent-warning:hover, .pt-button.pt-minimal.pt-intent-warning:active, .pt-button.pt-minimal.pt-intent-warning.pt-active {
        box-shadow: none;
        background: none;
        color: #bf7326; }
      .pt-button.pt-minimal.pt-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15);
        color: #bf7326; }
      .pt-button.pt-minimal.pt-intent-warning:active, .pt-button.pt-minimal.pt-intent-warning.pt-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .pt-button.pt-minimal.pt-intent-warning:disabled, .pt-button.pt-minimal.pt-intent-warning.pt-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
      .pt-button.pt-minimal.pt-intent-warning .pt-button-spinner .pt-spinner-head {
        stroke: #bf7326; }
      .pt-dark .pt-button.pt-minimal.pt-intent-warning {
        color: #ffb366; }
        .pt-dark .pt-button.pt-minimal.pt-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #ffb366; }
        .pt-dark .pt-button.pt-minimal.pt-intent-warning:active, .pt-dark .pt-button.pt-minimal.pt-intent-warning.pt-active {
          background: rgba(217, 130, 43, 0.3);
          color: #ffb366; }
        .pt-dark .pt-button.pt-minimal.pt-intent-warning:disabled, .pt-dark .pt-button.pt-minimal.pt-intent-warning.pt-disabled {
          background: none;
          color: rgba(255, 179, 102, 0.5); }
    .pt-button.pt-minimal.pt-intent-danger {
      color: #c23030; }
      .pt-button.pt-minimal.pt-intent-danger:hover, .pt-button.pt-minimal.pt-intent-danger:active, .pt-button.pt-minimal.pt-intent-danger.pt-active {
        box-shadow: none;
        background: none;
        color: #c23030; }
      .pt-button.pt-minimal.pt-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15);
        color: #c23030; }
      .pt-button.pt-minimal.pt-intent-danger:active, .pt-button.pt-minimal.pt-intent-danger.pt-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .pt-button.pt-minimal.pt-intent-danger:disabled, .pt-button.pt-minimal.pt-intent-danger.pt-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
      .pt-button.pt-minimal.pt-intent-danger .pt-button-spinner .pt-spinner-head {
        stroke: #c23030; }
      .pt-dark .pt-button.pt-minimal.pt-intent-danger {
        color: #ff7373; }
        .pt-dark .pt-button.pt-minimal.pt-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #ff7373; }
        .pt-dark .pt-button.pt-minimal.pt-intent-danger:active, .pt-dark .pt-button.pt-minimal.pt-intent-danger.pt-active {
          background: rgba(219, 55, 55, 0.3);
          color: #ff7373; }
        .pt-dark .pt-button.pt-minimal.pt-intent-danger:disabled, .pt-dark .pt-button.pt-minimal.pt-intent-danger.pt-disabled {
          background: none;
          color: rgba(255, 115, 115, 0.5); }
  .pt-button.pt-large,
  .pt-large .pt-button {
    min-width: 40px;
    min-height: 40px;
    line-height: 40px;
    padding: 0 15px;
    font-size: 16px; }
    .pt-button.pt-large[class*="pt-icon-"]::before,
    .pt-large .pt-button[class*="pt-icon-"]::before {
      margin-right: 10px; }
    .pt-button.pt-large .pt-icon, .pt-button.pt-large .pt-icon-standard, .pt-button.pt-large .pt-icon-large,
    .pt-large .pt-button .pt-icon,
    .pt-large .pt-button .pt-icon-standard,
    .pt-large .pt-button .pt-icon-large {
      margin-right: 10px; }
      .pt-button.pt-large .pt-icon.pt-align-right, .pt-button.pt-large .pt-icon-standard.pt-align-right, .pt-button.pt-large .pt-icon-large.pt-align-right,
      .pt-large .pt-button .pt-icon.pt-align-right,
      .pt-large .pt-button .pt-icon-standard.pt-align-right,
      .pt-large .pt-button .pt-icon-large.pt-align-right {
        margin-right: 0;
        margin-left: 10px; }
  .pt-button.pt-small,
  .pt-small .pt-button {
    min-width: 24px;
    min-height: 24px;
    line-height: 24px;
    padding: 0 7px; }
  .pt-button[class*="pt-icon-"]:empty {
    padding: 0; }
    .pt-button[class*="pt-icon-"]:empty::before {
      margin-right: 0; }

a.pt-button {
  text-align: center;
  text-decoration: none;
  transition: none; }
  a.pt-button, a.pt-button:hover, a.pt-button:active {
    color: #182026; }
  a.pt-button.pt-disabled {
    color: rgba(92, 112, 128, 0.5); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.pt-select select {
  display: inline-block;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 10px;
  vertical-align: middle;
  font-size: 14px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  height: 30px;
  padding: 0 25px 0 10px; }
  .pt-select select:hover {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5; }
  .pt-select select:active, .pt-select select.pt-active {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #d8e1e8;
    background-image: none; }
  .pt-select select:disabled, .pt-select select.pt-disabled {
    outline: none;
    box-shadow: none;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
    .pt-select select:disabled.pt-active, .pt-select select.pt-disabled.pt-active {
      background: rgba(206, 217, 224, 0.7); }

.pt-select.pt-minimal select {
  box-shadow: none;
  background: none; }
  .pt-select.pt-minimal select:hover {
    box-shadow: none;
    background: rgba(167, 182, 194, 0.3);
    text-decoration: none;
    color: #182026; }
  .pt-select.pt-minimal select:active, .pt-select.pt-minimal select.pt-active {
    box-shadow: none;
    background: rgba(115, 134, 148, 0.3);
    color: #182026; }
  .pt-select.pt-minimal select:disabled, .pt-select.pt-minimal select:disabled:hover, .pt-select.pt-minimal select.pt-disabled, .pt-select.pt-minimal select.pt-disabled:hover {
    background: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-dark .pt-select.pt-minimal select, .pt-select.pt-minimal .pt-dark select {
    box-shadow: none;
    background: none;
    color: inherit; }
    .pt-dark .pt-select.pt-minimal select:hover, .pt-select.pt-minimal .pt-dark select:hover, .pt-dark .pt-select.pt-minimal select:active, .pt-select.pt-minimal .pt-dark select:active, .pt-dark .pt-select.pt-minimal select.pt-active, .pt-select.pt-minimal .pt-dark select.pt-active {
      box-shadow: none;
      background: none; }
    .pt-dark .pt-select.pt-minimal select:hover, .pt-select.pt-minimal .pt-dark select:hover {
      background: rgba(138, 155, 168, 0.15); }
    .pt-dark .pt-select.pt-minimal select:active, .pt-select.pt-minimal .pt-dark select:active, .pt-dark .pt-select.pt-minimal select.pt-active, .pt-select.pt-minimal .pt-dark select.pt-active {
      background: rgba(138, 155, 168, 0.3);
      color: #f5f8fa; }
    .pt-dark .pt-select.pt-minimal select:disabled, .pt-select.pt-minimal .pt-dark select:disabled, .pt-dark .pt-select.pt-minimal select:disabled:hover, .pt-select.pt-minimal .pt-dark select:disabled:hover, .pt-dark .pt-select.pt-minimal select.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-disabled, .pt-dark .pt-select.pt-minimal select.pt-disabled:hover, .pt-select.pt-minimal .pt-dark select.pt-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: rgba(191, 204, 214, 0.5); }
  .pt-select.pt-minimal select.pt-intent-primary {
    color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:hover, .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal select.pt-intent-primary.pt-active {
      box-shadow: none;
      background: none;
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:hover {
      background: rgba(19, 124, 189, 0.15);
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal select.pt-intent-primary.pt-active {
      background: rgba(19, 124, 189, 0.3);
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:disabled, .pt-select.pt-minimal select.pt-intent-primary.pt-disabled {
      background: none;
      color: rgba(16, 107, 163, 0.5); }
    .pt-select.pt-minimal select.pt-intent-primary .pt-button-spinner .pt-spinner-head {
      stroke: #106ba3; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-primary, .pt-select.pt-minimal .pt-dark select.pt-intent-primary {
      color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:hover {
        background: rgba(19, 124, 189, 0.2);
        color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:active, .pt-dark .pt-select.pt-minimal select.pt-intent-primary.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-primary.pt-active {
        background: rgba(19, 124, 189, 0.3);
        color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-primary.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-primary.pt-disabled {
        background: none;
        color: rgba(72, 175, 240, 0.5); }
  .pt-select.pt-minimal select.pt-intent-success {
    color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:hover, .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal select.pt-intent-success.pt-active {
      box-shadow: none;
      background: none;
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:hover {
      background: rgba(15, 153, 96, 0.15);
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal select.pt-intent-success.pt-active {
      background: rgba(15, 153, 96, 0.3);
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:disabled, .pt-select.pt-minimal select.pt-intent-success.pt-disabled {
      background: none;
      color: rgba(13, 128, 80, 0.5); }
    .pt-select.pt-minimal select.pt-intent-success .pt-button-spinner .pt-spinner-head {
      stroke: #0d8050; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-success, .pt-select.pt-minimal .pt-dark select.pt-intent-success {
      color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-success:hover {
        background: rgba(15, 153, 96, 0.2);
        color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal .pt-dark select.pt-intent-success:active, .pt-dark .pt-select.pt-minimal select.pt-intent-success.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-success.pt-active {
        background: rgba(15, 153, 96, 0.3);
        color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-success:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-success.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-success.pt-disabled {
        background: none;
        color: rgba(61, 204, 145, 0.5); }
  .pt-select.pt-minimal select.pt-intent-warning {
    color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:hover, .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal select.pt-intent-warning.pt-active {
      box-shadow: none;
      background: none;
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:hover {
      background: rgba(217, 130, 43, 0.15);
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal select.pt-intent-warning.pt-active {
      background: rgba(217, 130, 43, 0.3);
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:disabled, .pt-select.pt-minimal select.pt-intent-warning.pt-disabled {
      background: none;
      color: rgba(191, 115, 38, 0.5); }
    .pt-select.pt-minimal select.pt-intent-warning .pt-button-spinner .pt-spinner-head {
      stroke: #bf7326; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-warning, .pt-select.pt-minimal .pt-dark select.pt-intent-warning {
      color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:hover {
        background: rgba(217, 130, 43, 0.2);
        color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:active, .pt-dark .pt-select.pt-minimal select.pt-intent-warning.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-warning.pt-active {
        background: rgba(217, 130, 43, 0.3);
        color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-warning.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-warning.pt-disabled {
        background: none;
        color: rgba(255, 179, 102, 0.5); }
  .pt-select.pt-minimal select.pt-intent-danger {
    color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:hover, .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal select.pt-intent-danger.pt-active {
      box-shadow: none;
      background: none;
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:hover {
      background: rgba(219, 55, 55, 0.15);
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal select.pt-intent-danger.pt-active {
      background: rgba(219, 55, 55, 0.3);
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:disabled, .pt-select.pt-minimal select.pt-intent-danger.pt-disabled {
      background: none;
      color: rgba(194, 48, 48, 0.5); }
    .pt-select.pt-minimal select.pt-intent-danger .pt-button-spinner .pt-spinner-head {
      stroke: #c23030; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-danger, .pt-select.pt-minimal .pt-dark select.pt-intent-danger {
      color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:hover {
        background: rgba(219, 55, 55, 0.2);
        color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:active, .pt-dark .pt-select.pt-minimal select.pt-intent-danger.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-danger.pt-active {
        background: rgba(219, 55, 55, 0.3);
        color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-danger.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-danger.pt-disabled {
        background: none;
        color: rgba(255, 115, 115, 0.5); }

.pt-select.pt-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px; }

.pt-dark .pt-select select {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #394b59;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  color: #f5f8fa; }
  .pt-dark .pt-select select:hover, .pt-dark .pt-select select:active, .pt-dark .pt-select select.pt-active {
    color: #f5f8fa; }
  .pt-dark .pt-select select:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    background-color: #30404d; }
  .pt-dark .pt-select select:active, .pt-dark .pt-select select.pt-active {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #202b33;
    background-image: none; }
  .pt-dark .pt-select select:disabled, .pt-dark .pt-select select.pt-disabled {
    box-shadow: none;
    background-color: rgba(57, 75, 89, 0.5);
    background-image: none;
    color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-select select:disabled.pt-active, .pt-dark .pt-select select.pt-disabled.pt-active {
      background: rgba(57, 75, 89, 0.7); }
  .pt-dark .pt-select select .pt-button-spinner .pt-spinner-head {
    background: rgba(16, 22, 26, 0.5);
    stroke: #8a9ba8; }

.pt-select select:disabled {
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.pt-select::after {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  right: 7px;
  line-height: 30px;
  color: #5c7080;
  content: "";
  pointer-events: none; }
  .pt-disabled.pt-select::after {
    color: rgba(92, 112, 128, 0.5); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Button groups

Markup:
<div class="pt-button-group {{.modifier}}">
  <a class="pt-button pt-icon-database" tabindex="0" role="button">Queries</a>
  <a class="pt-button pt-icon-function" tabindex="0" role="button">Functions</a>
  <a class="pt-button" tabindex="0" role="button">
    Options <span class="pt-icon-standard pt-icon-caret-down pt-align-right"></span>
  </a>
</div>
<br /><br />
<div class="pt-button-group {{.modifier}}">
  <a class="pt-button pt-icon-chart" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-control" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-graph" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-camera" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-map" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-code" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-th" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-time" tabindex="0" role="button"></a>
  <a class="pt-button pt-icon-compressed" tabindex="0" role="button"></a>
</div>
<br /><br />
<div class="pt-button-group {{.modifier}}">
  <button type="button" class="pt-button pt-intent-success">Save</button>
  <button type="button" class="pt-button pt-intent-success pt-icon-caret-down"></button>
</div>

.pt-large - Use large buttons
.pt-minimal - Use minimal buttons. Note that these minimal buttons will not automatically
              truncate text in an ellipsis because of the divider line added in CSS.

Styleguide pt-button-group
*/
.pt-button-group {
  display: inline-flex;
  /*
  Responsive

  Markup:
  <div class="pt-button-group pt-large pt-fill">
    <a class="pt-button pt-intent-primary pt-fixed" tabindex="0" role="button">Start</a>
    <a class="pt-button pt-intent-primary" tabindex="0" role="button">Left</a>
    <a class="pt-button pt-intent-primary pt-active" tabindex="0" role="button">Middle</a>
    <a class="pt-button pt-intent-primary" tabindex="0" role="button">Right</a>
    <a class="pt-button pt-intent-primary pt-fixed" tabindex="0" role="button">End</a>
  </div>
  <br />
  <div class="pt-button-group pt-fill">
    <button class="pt-button pt-icon-arrow-left"></button>
    <button class="pt-button pt-fill">Middle</button>
    <button class="pt-button pt-icon-arrow-right"></button>
  </div>

  Styleguide pt-button-group.pt-fill
  */
  /*
  Vertical button groups

  Markup:
  <div class="pt-button-group pt-vertical">
    <a class="pt-button pt-icon-search-template" role="button" tabindex="0"></a>
    <a class="pt-button pt-icon-zoom-in" role="button" tabindex="0"></a>
    <a class="pt-button pt-icon-zoom-out" role="button" tabindex="0"></a>
    <a class="pt-button pt-icon-zoom-to-fit" role="button" tabindex="0"></a>
  </div>
  <div class="pt-button-group pt-vertical">
    <button type="button" class="pt-button pt-active">Home</button>
    <button type="button" class="pt-button">Pages</button>
    <button type="button" class="pt-button">Blog</button>
    <button type="button" class="pt-button">Calendar</button>
  </div>
  <div class="pt-button-group pt-vertical pt-align-left pt-large">
    <button type="button" class="pt-button pt-intent-primary pt-icon-document">Text</button>
    <button type="button" class="pt-button pt-intent-primary pt-icon-media pt-active">Media</button>
    <button type="button" class="pt-button pt-intent-primary pt-icon-link">Sources</button>
  </div>

  Styleguide pt-button-group.pt-vertical
  */ }
  .pt-button-group .pt-button {
    flex: 0 0 auto;
    position: relative;
    z-index: 4; }
    .pt-button-group .pt-button:focus {
      z-index: 5; }
    .pt-button-group .pt-button:hover {
      z-index: 6; }
    .pt-button-group .pt-button:active, .pt-button-group .pt-button.pt-active {
      z-index: 7; }
    .pt-button-group .pt-button:disabled, .pt-button-group .pt-button.pt-disabled {
      z-index: 3; }
    .pt-button-group .pt-button[class*="pt-intent-"] {
      z-index: 9; }
      .pt-button-group .pt-button[class*="pt-intent-"]:focus {
        z-index: 10; }
      .pt-button-group .pt-button[class*="pt-intent-"]:hover {
        z-index: 11; }
      .pt-button-group .pt-button[class*="pt-intent-"]:active, .pt-button-group .pt-button[class*="pt-intent-"].pt-active {
        z-index: 12; }
      .pt-button-group .pt-button[class*="pt-intent-"]:disabled, .pt-button-group .pt-button[class*="pt-intent-"].pt-disabled {
        z-index: 8; }
  .pt-button-group:not(.pt-minimal) > .pt-popover-target:not(:first-child) .pt-button,
  .pt-button-group:not(.pt-minimal) > .pt-button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .pt-button-group:not(.pt-minimal) > .pt-popover-target:not(:last-child) .pt-button,
  .pt-button-group:not(.pt-minimal) > .pt-button:not(:last-child) {
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .pt-button-group.pt-minimal .pt-button {
    box-shadow: none;
    background: none;
    margin-right: 11px;
    border-radius: 3px !important;
    overflow: visible; }
    .pt-button-group.pt-minimal .pt-button:hover {
      box-shadow: none;
      background: rgba(167, 182, 194, 0.3);
      text-decoration: none;
      color: #182026; }
    .pt-button-group.pt-minimal .pt-button:active, .pt-button-group.pt-minimal .pt-button.pt-active {
      box-shadow: none;
      background: rgba(115, 134, 148, 0.3);
      color: #182026; }
    .pt-button-group.pt-minimal .pt-button:disabled, .pt-button-group.pt-minimal .pt-button:disabled:hover, .pt-button-group.pt-minimal .pt-button.pt-disabled, .pt-button-group.pt-minimal .pt-button.pt-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
    .pt-dark .pt-button-group.pt-minimal .pt-button {
      box-shadow: none;
      background: none;
      color: inherit; }
      .pt-dark .pt-button-group.pt-minimal .pt-button:hover, .pt-dark .pt-button-group.pt-minimal .pt-button:active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-active {
        box-shadow: none;
        background: none; }
      .pt-dark .pt-button-group.pt-minimal .pt-button:hover {
        background: rgba(138, 155, 168, 0.15); }
      .pt-dark .pt-button-group.pt-minimal .pt-button:active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-active {
        background: rgba(138, 155, 168, 0.3);
        color: #f5f8fa; }
      .pt-dark .pt-button-group.pt-minimal .pt-button:disabled, .pt-dark .pt-button-group.pt-minimal .pt-button:disabled:hover, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-disabled, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-disabled:hover {
        background: none;
        cursor: not-allowed;
        color: rgba(191, 204, 214, 0.5); }
    .pt-button-group.pt-minimal .pt-button.pt-intent-primary {
      color: #106ba3; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-primary:hover, .pt-button-group.pt-minimal .pt-button.pt-intent-primary:active, .pt-button-group.pt-minimal .pt-button.pt-intent-primary.pt-active {
        box-shadow: none;
        background: none;
        color: #106ba3; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-primary:hover {
        background: rgba(19, 124, 189, 0.15);
        color: #106ba3; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-primary:active, .pt-button-group.pt-minimal .pt-button.pt-intent-primary.pt-active {
        background: rgba(19, 124, 189, 0.3);
        color: #106ba3; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-primary:disabled, .pt-button-group.pt-minimal .pt-button.pt-intent-primary.pt-disabled {
        background: none;
        color: rgba(16, 107, 163, 0.5); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-primary .pt-button-spinner .pt-spinner-head {
        stroke: #106ba3; }
      .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary {
        color: #48aff0; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary:hover {
          background: rgba(19, 124, 189, 0.2);
          color: #48aff0; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary:active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary.pt-active {
          background: rgba(19, 124, 189, 0.3);
          color: #48aff0; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary:disabled, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-primary.pt-disabled {
          background: none;
          color: rgba(72, 175, 240, 0.5); }
    .pt-button-group.pt-minimal .pt-button.pt-intent-success {
      color: #0d8050; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-success:hover, .pt-button-group.pt-minimal .pt-button.pt-intent-success:active, .pt-button-group.pt-minimal .pt-button.pt-intent-success.pt-active {
        box-shadow: none;
        background: none;
        color: #0d8050; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-success:hover {
        background: rgba(15, 153, 96, 0.15);
        color: #0d8050; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-success:active, .pt-button-group.pt-minimal .pt-button.pt-intent-success.pt-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-success:disabled, .pt-button-group.pt-minimal .pt-button.pt-intent-success.pt-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-success .pt-button-spinner .pt-spinner-head {
        stroke: #0d8050; }
      .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success {
        color: #3dcc91; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #3dcc91; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success:active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success.pt-active {
          background: rgba(15, 153, 96, 0.3);
          color: #3dcc91; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success:disabled, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-success.pt-disabled {
          background: none;
          color: rgba(61, 204, 145, 0.5); }
    .pt-button-group.pt-minimal .pt-button.pt-intent-warning {
      color: #bf7326; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-warning:hover, .pt-button-group.pt-minimal .pt-button.pt-intent-warning:active, .pt-button-group.pt-minimal .pt-button.pt-intent-warning.pt-active {
        box-shadow: none;
        background: none;
        color: #bf7326; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15);
        color: #bf7326; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-warning:active, .pt-button-group.pt-minimal .pt-button.pt-intent-warning.pt-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-warning:disabled, .pt-button-group.pt-minimal .pt-button.pt-intent-warning.pt-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-warning .pt-button-spinner .pt-spinner-head {
        stroke: #bf7326; }
      .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning {
        color: #ffb366; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #ffb366; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning:active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning.pt-active {
          background: rgba(217, 130, 43, 0.3);
          color: #ffb366; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning:disabled, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-warning.pt-disabled {
          background: none;
          color: rgba(255, 179, 102, 0.5); }
    .pt-button-group.pt-minimal .pt-button.pt-intent-danger {
      color: #c23030; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-danger:hover, .pt-button-group.pt-minimal .pt-button.pt-intent-danger:active, .pt-button-group.pt-minimal .pt-button.pt-intent-danger.pt-active {
        box-shadow: none;
        background: none;
        color: #c23030; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15);
        color: #c23030; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-danger:active, .pt-button-group.pt-minimal .pt-button.pt-intent-danger.pt-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .pt-button-group.pt-minimal .pt-button.pt-intent-danger:disabled, .pt-button-group.pt-minimal .pt-button.pt-intent-danger.pt-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
      .pt-button-group.pt-minimal .pt-button.pt-intent-danger .pt-button-spinner .pt-spinner-head {
        stroke: #c23030; }
      .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger {
        color: #ff7373; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #ff7373; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger:active, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger.pt-active {
          background: rgba(219, 55, 55, 0.3);
          color: #ff7373; }
        .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger:disabled, .pt-dark .pt-button-group.pt-minimal .pt-button.pt-intent-danger.pt-disabled {
          background: none;
          color: rgba(255, 115, 115, 0.5); }
    .pt-button-group.pt-minimal .pt-button:focus {
      outline-style: solid; }
    .pt-button-group.pt-minimal .pt-button::after {
      margin: 5px;
      background: rgba(16, 22, 26, 0.15);
      width: 1px;
      display: inline-block;
      position: absolute;
      top: 10%;
      bottom: 10%;
      left: 100%;
      content: ""; }
      .pt-dark .pt-button-group.pt-minimal .pt-button::after {
        background: rgba(255, 255, 255, 0.15); }
  .pt-button-group.pt-minimal > .pt-popover-target:last-child .pt-button,
  .pt-button-group.pt-minimal > .pt-button:last-child {
    margin-right: 0; }
    .pt-button-group.pt-minimal > .pt-popover-target:last-child .pt-button::after,
    .pt-button-group.pt-minimal > .pt-button:last-child::after {
      display: none; }
  .pt-button-group.pt-fill {
    display: flex; }
  .pt-button-group .pt-button.pt-fill,
  .pt-button-group.pt-fill .pt-button:not(.pt-fixed) {
    flex: 1 1 auto; }
  .pt-button-group.pt-vertical {
    flex-direction: column;
    align-items: stretch;
    vertical-align: top; }
    .pt-button-group.pt-vertical .pt-button {
      margin-right: 0 !important; }
    .pt-button-group.pt-vertical > .pt-popover-target:first-child .pt-button,
    .pt-button-group.pt-vertical > .pt-button:first-child {
      border-radius: 3px 3px 0 0; }
    .pt-button-group.pt-vertical > .pt-popover-target:last-child .pt-button,
    .pt-button-group.pt-vertical > .pt-button:last-child {
      border-radius: 0 0 3px 3px; }
    .pt-button-group.pt-vertical > .pt-popover-target:not(:last-child) .pt-button,
    .pt-button-group.pt-vertical > .pt-button:not(:last-child) {
      margin-bottom: -1px; }
    .pt-button-group.pt-vertical.pt-minimal .pt-button:not(:last-child) {
      margin-bottom: 11px; }
    .pt-button-group.pt-vertical.pt-minimal .pt-button::after {
      top: 100%;
      right: 0;
      bottom: auto;
      left: 0;
      width: auto;
      height: 1px; }
  .pt-button-group.pt-align-left .pt-button {
    text-align: left; }
  .pt-dark .pt-button-group:not(.pt-minimal) > .pt-popover-target:not(:last-child) .pt-button,
  .pt-dark .pt-button-group:not(.pt-minimal) > .pt-button:not(:last-child) {
    margin-right: 1px; }
  .pt-dark .pt-button-group.pt-vertical > .pt-popover-target:not(:last-child) .pt-button,
  .pt-dark .pt-button-group.pt-vertical > .pt-button:not(:last-child) {
    margin-bottom: 1px; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Callout

Markup:
<div class="pt-callout {{.modifier}}">
  <h5>Callout Heading</h5>
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex, delectus!
</div>

.pt-intent-primary - Primary intent
.pt-intent-success - Success intent
.pt-intent-warning - Warning intent
.pt-intent-danger  - Danger intent
.pt-icon-info-sign - With an icon

Styleguide pt-callout
*/
.pt-callout {
  line-height: 1.5;
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  background-color: rgba(138, 155, 168, 0.15);
  padding: 10px 12px 9px; }
  .pt-callout[class*="pt-icon-"] {
    padding-left: 40px; }
    .pt-callout[class*="pt-icon-"]::before {
      line-height: 1;
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      top: 10px;
      left: 10px;
      color: #5c7080; }
  .pt-callout h5 {
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 20px; }
  .pt-dark .pt-callout {
    background-color: rgba(138, 155, 168, 0.2); }
    .pt-dark .pt-callout[class*="pt-icon-"]::before {
      color: #bfccd6; }
  .pt-callout.pt-intent-primary {
    background-color: rgba(19, 124, 189, 0.15); }
    .pt-callout.pt-intent-primary[class*="pt-icon-"]::before,
    .pt-callout.pt-intent-primary h5 {
      color: #106ba3; }
    .pt-dark .pt-callout.pt-intent-primary {
      background-color: rgba(19, 124, 189, 0.25); }
      .pt-dark .pt-callout.pt-intent-primary[class*="pt-icon-"]::before,
      .pt-dark .pt-callout.pt-intent-primary h5 {
        color: #48aff0; }
  .pt-callout.pt-intent-success {
    background-color: rgba(15, 153, 96, 0.15); }
    .pt-callout.pt-intent-success[class*="pt-icon-"]::before,
    .pt-callout.pt-intent-success h5 {
      color: #0d8050; }
    .pt-dark .pt-callout.pt-intent-success {
      background-color: rgba(15, 153, 96, 0.25); }
      .pt-dark .pt-callout.pt-intent-success[class*="pt-icon-"]::before,
      .pt-dark .pt-callout.pt-intent-success h5 {
        color: #3dcc91; }
  .pt-callout.pt-intent-warning {
    background-color: rgba(217, 130, 43, 0.15); }
    .pt-callout.pt-intent-warning[class*="pt-icon-"]::before,
    .pt-callout.pt-intent-warning h5 {
      color: #bf7326; }
    .pt-dark .pt-callout.pt-intent-warning {
      background-color: rgba(217, 130, 43, 0.25); }
      .pt-dark .pt-callout.pt-intent-warning[class*="pt-icon-"]::before,
      .pt-dark .pt-callout.pt-intent-warning h5 {
        color: #ffb366; }
  .pt-callout.pt-intent-danger {
    background-color: rgba(219, 55, 55, 0.15); }
    .pt-callout.pt-intent-danger[class*="pt-icon-"]::before,
    .pt-callout.pt-intent-danger h5 {
      color: #c23030; }
    .pt-dark .pt-callout.pt-intent-danger {
      background-color: rgba(219, 55, 55, 0.25); }
      .pt-dark .pt-callout.pt-intent-danger[class*="pt-icon-"]::before,
      .pt-dark .pt-callout.pt-intent-danger h5 {
        color: #ff7373; }
  .pt-running-text .pt-callout {
    margin: 20px 0; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Cards

Markup:
<div class="pt-card {{.modifier}}">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec dapibus et mauris,
  vitae dictum metus.
</div>

.pt-elevation-0 - Ground floor. This level provides just a gentle border shadow.
.pt-elevation-1 - First. Subtle drop shadow intended for static containers.
.pt-elevation-2 - Second. An even stronger shadow, moving on up.
.pt-elevation-3 - Third. For containers overlaying content temporarily.
.pt-elevation-4 - Fourth. The strongest shadow, usually for overlay containers on top of backdrops.

Styleguide pt-card
*/
.pt-card {
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  background-color: #ffffff;
  padding: 20px;
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-card.pt-dark,
  .pt-dark .pt-card {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
    background-color: #30404d; }

.pt-elevation-0 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }
  .pt-elevation-0.pt-dark,
  .pt-dark .pt-elevation-0 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }

.pt-elevation-1 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-elevation-1.pt-dark,
  .pt-dark .pt-elevation-1 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }

.pt-elevation-2 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2); }
  .pt-elevation-2.pt-dark,
  .pt-dark .pt-elevation-2 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4), 0 2px 6px rgba(16, 22, 26, 0.4); }

.pt-elevation-3 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2); }
  .pt-elevation-3.pt-dark,
  .pt-dark .pt-elevation-3 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.pt-elevation-4 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2); }
  .pt-elevation-4.pt-dark,
  .pt-dark .pt-elevation-4 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4); }

/*
Interactive cards

Markup:
<div class="docs-card-example">
  <div class="pt-card pt-elevation-0 pt-interactive">
    <h5><a href="#">Trader Profile</a></h5>
    <p>Overview of employee activity, including risk model, scores and scenario alert history.</p>
  </div>
  <div class="pt-card pt-elevation-1 pt-interactive">
    <h5><a href="#">Desk Profile</a></h5>
    <p>Desk-level summary of trading activity and trading profiles.</p>
  </div>
  <div class="pt-card pt-elevation-2 pt-interactive">
    <h5><a href="#">Dataset Dashboards</a></h5>
    <p>Stats of dataset completeness and reference data join percentages.</p>
  </div>
</div>

Styleguide pt-card.pt-interactive
*/
.pt-card.pt-interactive:hover {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  cursor: pointer; }
  .pt-card.pt-interactive:hover.pt-dark,
  .pt-dark .pt-card.pt-interactive:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.pt-card.pt-interactive:active {
  opacity: 0.9;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  transition-duration: 0; }
  .pt-card.pt-interactive:active.pt-dark,
  .pt-dark .pt-card.pt-interactive:active {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
.pt-collapse {
  height: 0;
  overflow-y: hidden;
  transition: height 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-collapse .pt-collapse-body {
    transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.pt-context-menu .pt-popover-target {
  display: block; }

.pt-context-menu-popover-target {
  position: fixed; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Dialog

Markup:
<!-- this container element fills its parent and centers the .pt-dialog within it -->
<div class="pt-dialog-container">
  <div class="pt-dialog">
    <div class="pt-dialog-header">
      <span class="pt-icon-large pt-icon-inbox"></span>
      <h5>Dialog header</h5>
      <button aria-label="Close" class="pt-dialog-close-button pt-icon-small-cross"></button>
    </div>
    <div class="pt-dialog-body">
      This dialog hasn't been wired up with any open or close interactions.
      It's just an example of markup and styles.
    </div>
    <div class="pt-dialog-footer">
      <div class="pt-dialog-footer-actions">
        <button type="button" class="pt-button">Secondary button</button>
        <button type="submit" class="pt-button pt-intent-primary">Primary button</button>
      </div>
    </div>
  </div>
</div>

Styleguide pt-dialog
*/
.pt-dialog-container {
  opacity: 1;
  transform: scale(1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  user-select: none; }
  .pt-dialog-container.pt-overlay-enter > .pt-dialog, .pt-dialog-container.pt-overlay-appear > .pt-dialog {
    opacity: 0;
    transform: scale(0.5); }
  .pt-dialog-container.pt-overlay-enter-active > .pt-dialog, .pt-dialog-container.pt-overlay-appear-active > .pt-dialog {
    opacity: 1;
    transform: scale(1);
    transition-property: opacity, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-dialog-container.pt-overlay-leave > .pt-dialog {
    opacity: 1;
    transform: scale(1); }
  .pt-dialog-container.pt-overlay-leave-active > .pt-dialog {
    opacity: 0;
    transform: scale(0.5);
    transition-property: opacity, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-dialog-container .pt-dialog {
    position: static !important;
    transform: none; }

.pt-dialog {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  background: #ebf1f5;
  width: 500px;
  padding-bottom: 20px;
  user-select: initial; }
  .pt-dialog:focus {
    outline: 0; }
  .pt-dialog.pt-dark,
  .pt-dark .pt-dialog {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
    background: #293742;
    color: #f5f8fa; }

.pt-dialog-header {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  background: #ffffff;
  min-height: 40px;
  padding-left: 20px; }
  .pt-dialog-header .pt-icon-large {
    flex: 0 0 auto;
    margin-right: 10px;
    color: #5c7080; }
  .pt-dialog-header h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    margin: 0;
    line-height: inherit; }
    .pt-dialog-header h5:last-child {
      margin-right: 20px; }
  .pt-dark .pt-dialog-header {
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4);
    background: #30404d; }
    .pt-dark .pt-dialog-header .pt-icon-large {
      color: #bfccd6; }
    .pt-dark .pt-dialog-header h5 {
      color: #f5f8fa; }

.pt-dialog-close-button {
  line-height: 1;
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #5c7080;
  flex: 0 0 auto;
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px; }
  .pt-dialog-close-button:hover {
    color: #182026; }
  .pt-dark .pt-dialog-close-button {
    color: #bfccd6; }
    .pt-dark .pt-dialog-close-button:hover {
      color: #f5f8fa; }

.pt-dialog-body {
  flex: 1 1 auto;
  margin: 20px;
  line-height: 18px; }

.pt-dialog-footer {
  flex: 0 0 auto;
  margin: 0 20px; }

.pt-dialog-footer-actions {
  display: flex;
  justify-content: flex-end; }
  .pt-dialog-footer-actions .pt-button {
    margin-left: 10px; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.pt-select select {
  display: inline-block;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 10px;
  vertical-align: middle;
  font-size: 14px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  height: 30px;
  padding: 0 25px 0 10px; }
  .pt-select select:hover {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5; }
  .pt-select select:active, .pt-select select.pt-active {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #d8e1e8;
    background-image: none; }
  .pt-select select:disabled, .pt-select select.pt-disabled {
    outline: none;
    box-shadow: none;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
    .pt-select select:disabled.pt-active, .pt-select select.pt-disabled.pt-active {
      background: rgba(206, 217, 224, 0.7); }

.pt-select.pt-minimal select {
  box-shadow: none;
  background: none; }
  .pt-select.pt-minimal select:hover {
    box-shadow: none;
    background: rgba(167, 182, 194, 0.3);
    text-decoration: none;
    color: #182026; }
  .pt-select.pt-minimal select:active, .pt-select.pt-minimal select.pt-active {
    box-shadow: none;
    background: rgba(115, 134, 148, 0.3);
    color: #182026; }
  .pt-select.pt-minimal select:disabled, .pt-select.pt-minimal select:disabled:hover, .pt-select.pt-minimal select.pt-disabled, .pt-select.pt-minimal select.pt-disabled:hover {
    background: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-dark .pt-select.pt-minimal select, .pt-select.pt-minimal .pt-dark select {
    box-shadow: none;
    background: none;
    color: inherit; }
    .pt-dark .pt-select.pt-minimal select:hover, .pt-select.pt-minimal .pt-dark select:hover, .pt-dark .pt-select.pt-minimal select:active, .pt-select.pt-minimal .pt-dark select:active, .pt-dark .pt-select.pt-minimal select.pt-active, .pt-select.pt-minimal .pt-dark select.pt-active {
      box-shadow: none;
      background: none; }
    .pt-dark .pt-select.pt-minimal select:hover, .pt-select.pt-minimal .pt-dark select:hover {
      background: rgba(138, 155, 168, 0.15); }
    .pt-dark .pt-select.pt-minimal select:active, .pt-select.pt-minimal .pt-dark select:active, .pt-dark .pt-select.pt-minimal select.pt-active, .pt-select.pt-minimal .pt-dark select.pt-active {
      background: rgba(138, 155, 168, 0.3);
      color: #f5f8fa; }
    .pt-dark .pt-select.pt-minimal select:disabled, .pt-select.pt-minimal .pt-dark select:disabled, .pt-dark .pt-select.pt-minimal select:disabled:hover, .pt-select.pt-minimal .pt-dark select:disabled:hover, .pt-dark .pt-select.pt-minimal select.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-disabled, .pt-dark .pt-select.pt-minimal select.pt-disabled:hover, .pt-select.pt-minimal .pt-dark select.pt-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: rgba(191, 204, 214, 0.5); }
  .pt-select.pt-minimal select.pt-intent-primary {
    color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:hover, .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal select.pt-intent-primary.pt-active {
      box-shadow: none;
      background: none;
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:hover {
      background: rgba(19, 124, 189, 0.15);
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal select.pt-intent-primary.pt-active {
      background: rgba(19, 124, 189, 0.3);
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:disabled, .pt-select.pt-minimal select.pt-intent-primary.pt-disabled {
      background: none;
      color: rgba(16, 107, 163, 0.5); }
    .pt-select.pt-minimal select.pt-intent-primary .pt-button-spinner .pt-spinner-head {
      stroke: #106ba3; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-primary, .pt-select.pt-minimal .pt-dark select.pt-intent-primary {
      color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:hover {
        background: rgba(19, 124, 189, 0.2);
        color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:active, .pt-dark .pt-select.pt-minimal select.pt-intent-primary.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-primary.pt-active {
        background: rgba(19, 124, 189, 0.3);
        color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-primary.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-primary.pt-disabled {
        background: none;
        color: rgba(72, 175, 240, 0.5); }
  .pt-select.pt-minimal select.pt-intent-success {
    color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:hover, .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal select.pt-intent-success.pt-active {
      box-shadow: none;
      background: none;
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:hover {
      background: rgba(15, 153, 96, 0.15);
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal select.pt-intent-success.pt-active {
      background: rgba(15, 153, 96, 0.3);
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:disabled, .pt-select.pt-minimal select.pt-intent-success.pt-disabled {
      background: none;
      color: rgba(13, 128, 80, 0.5); }
    .pt-select.pt-minimal select.pt-intent-success .pt-button-spinner .pt-spinner-head {
      stroke: #0d8050; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-success, .pt-select.pt-minimal .pt-dark select.pt-intent-success {
      color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-success:hover {
        background: rgba(15, 153, 96, 0.2);
        color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal .pt-dark select.pt-intent-success:active, .pt-dark .pt-select.pt-minimal select.pt-intent-success.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-success.pt-active {
        background: rgba(15, 153, 96, 0.3);
        color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-success:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-success.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-success.pt-disabled {
        background: none;
        color: rgba(61, 204, 145, 0.5); }
  .pt-select.pt-minimal select.pt-intent-warning {
    color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:hover, .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal select.pt-intent-warning.pt-active {
      box-shadow: none;
      background: none;
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:hover {
      background: rgba(217, 130, 43, 0.15);
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal select.pt-intent-warning.pt-active {
      background: rgba(217, 130, 43, 0.3);
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:disabled, .pt-select.pt-minimal select.pt-intent-warning.pt-disabled {
      background: none;
      color: rgba(191, 115, 38, 0.5); }
    .pt-select.pt-minimal select.pt-intent-warning .pt-button-spinner .pt-spinner-head {
      stroke: #bf7326; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-warning, .pt-select.pt-minimal .pt-dark select.pt-intent-warning {
      color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:hover {
        background: rgba(217, 130, 43, 0.2);
        color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:active, .pt-dark .pt-select.pt-minimal select.pt-intent-warning.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-warning.pt-active {
        background: rgba(217, 130, 43, 0.3);
        color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-warning.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-warning.pt-disabled {
        background: none;
        color: rgba(255, 179, 102, 0.5); }
  .pt-select.pt-minimal select.pt-intent-danger {
    color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:hover, .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal select.pt-intent-danger.pt-active {
      box-shadow: none;
      background: none;
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:hover {
      background: rgba(219, 55, 55, 0.15);
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal select.pt-intent-danger.pt-active {
      background: rgba(219, 55, 55, 0.3);
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:disabled, .pt-select.pt-minimal select.pt-intent-danger.pt-disabled {
      background: none;
      color: rgba(194, 48, 48, 0.5); }
    .pt-select.pt-minimal select.pt-intent-danger .pt-button-spinner .pt-spinner-head {
      stroke: #c23030; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-danger, .pt-select.pt-minimal .pt-dark select.pt-intent-danger {
      color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:hover {
        background: rgba(219, 55, 55, 0.2);
        color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:active, .pt-dark .pt-select.pt-minimal select.pt-intent-danger.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-danger.pt-active {
        background: rgba(219, 55, 55, 0.3);
        color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-danger.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-danger.pt-disabled {
        background: none;
        color: rgba(255, 115, 115, 0.5); }

.pt-select.pt-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px; }

.pt-dark .pt-select select {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #394b59;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  color: #f5f8fa; }
  .pt-dark .pt-select select:hover, .pt-dark .pt-select select:active, .pt-dark .pt-select select.pt-active {
    color: #f5f8fa; }
  .pt-dark .pt-select select:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    background-color: #30404d; }
  .pt-dark .pt-select select:active, .pt-dark .pt-select select.pt-active {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #202b33;
    background-image: none; }
  .pt-dark .pt-select select:disabled, .pt-dark .pt-select select.pt-disabled {
    box-shadow: none;
    background-color: rgba(57, 75, 89, 0.5);
    background-image: none;
    color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-select select:disabled.pt-active, .pt-dark .pt-select select.pt-disabled.pt-active {
      background: rgba(57, 75, 89, 0.7); }
  .pt-dark .pt-select select .pt-button-spinner .pt-spinner-head {
    background: rgba(16, 22, 26, 0.5);
    stroke: #8a9ba8; }

.pt-select select:disabled {
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.pt-select::after {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  right: 7px;
  line-height: 30px;
  color: #5c7080;
  content: "";
  pointer-events: none; }
  .pt-disabled.pt-select::after {
    color: rgba(92, 112, 128, 0.5); }

.pt-editable-text {
  display: inline-block;
  position: relative;
  cursor: text;
  max-width: 100%;
  vertical-align: top;
  white-space: nowrap; }
  .pt-editable-text::before {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border-radius: 3px;
    content: "";
    transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .pt-editable-text.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background-color: #ffffff; }
  .pt-editable-text.pt-disabled::before {
    box-shadow: none; }
  .pt-editable-text.pt-intent-primary .pt-editable-input,
  .pt-editable-text.pt-intent-primary .pt-editable-content {
    color: #137cbd; }
  .pt-editable-text.pt-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(19, 124, 189, 0.4); }
  .pt-editable-text.pt-intent-primary.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-editable-text.pt-intent-success .pt-editable-input,
  .pt-editable-text.pt-intent-success .pt-editable-content {
    color: #0f9960; }
  .pt-editable-text.pt-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px rgba(15, 153, 96, 0.4); }
  .pt-editable-text.pt-intent-success.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-editable-text.pt-intent-warning .pt-editable-input,
  .pt-editable-text.pt-intent-warning .pt-editable-content {
    color: #d9822b; }
  .pt-editable-text.pt-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px rgba(217, 130, 43, 0.4); }
  .pt-editable-text.pt-intent-warning.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-editable-text.pt-intent-danger .pt-editable-input,
  .pt-editable-text.pt-intent-danger .pt-editable-content {
    color: #db3737; }
  .pt-editable-text.pt-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px rgba(219, 55, 55, 0.4); }
  .pt-editable-text.pt-intent-danger.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-dark .pt-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.15); }
  .pt-dark .pt-editable-text.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    background-color: rgba(16, 22, 26, 0.3); }
  .pt-dark .pt-editable-text.pt-disabled::before {
    box-shadow: none; }
  .pt-dark .pt-editable-text.pt-intent-primary .pt-editable-content {
    color: #48aff0; }
  .pt-dark .pt-editable-text.pt-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(72, 175, 240, 0), 0 0 0 0 rgba(72, 175, 240, 0), inset 0 0 0 1px rgba(72, 175, 240, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-primary.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #48aff0, 0 0 0 3px rgba(72, 175, 240, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-success .pt-editable-content {
    color: #3dcc91; }
  .pt-dark .pt-editable-text.pt-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(61, 204, 145, 0), 0 0 0 0 rgba(61, 204, 145, 0), inset 0 0 0 1px rgba(61, 204, 145, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-success.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #3dcc91, 0 0 0 3px rgba(61, 204, 145, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-warning .pt-editable-content {
    color: #ffb366; }
  .pt-dark .pt-editable-text.pt-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 179, 102, 0), 0 0 0 0 rgba(255, 179, 102, 0), inset 0 0 0 1px rgba(255, 179, 102, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-warning.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #ffb366, 0 0 0 3px rgba(255, 179, 102, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-danger .pt-editable-content {
    color: #ff7373; }
  .pt-dark .pt-editable-text.pt-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 115, 115, 0), 0 0 0 0 rgba(255, 115, 115, 0), inset 0 0 0 1px rgba(255, 115, 115, 0.4); }
  .pt-dark .pt-editable-text.pt-intent-danger.pt-editable-editing::before {
    box-shadow: 0 0 0 1px #ff7373, 0 0 0 3px rgba(255, 115, 115, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.pt-editable-input,
.pt-editable-content {
  display: inherit;
  position: relative;
  min-width: inherit;
  max-width: inherit;
  vertical-align: top;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
  resize: none; }

.pt-editable-input {
  border: none;
  box-shadow: none;
  background: none;
  width: 100%;
  padding: 0;
  white-space: pre-wrap; }
  .pt-editable-input:focus {
    outline: none; }
  .pt-editable-input::-ms-clear {
    display: none; }

.pt-editable-content {
  overflow: hidden;
  padding-right: 2px;
  text-overflow: ellipsis;
  white-space: pre; }
  .pt-editable-editing > .pt-editable-content {
    position: absolute;
    left: 0;
    visibility: hidden; }
  .pt-editable-placeholder > .pt-editable-content {
    color: rgba(92, 112, 128, 0.5); }
    .pt-dark .pt-editable-placeholder > .pt-editable-content {
      color: rgba(191, 204, 214, 0.5); }

.pt-editable-text.pt-multiline {
  display: block; }
  .pt-editable-text.pt-multiline .pt-editable-content {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.pt-select select {
  display: inline-block;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 10px;
  vertical-align: middle;
  font-size: 14px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  height: 30px;
  padding: 0 25px 0 10px; }
  .pt-select select:hover {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5; }
  .pt-select select:active, .pt-select select.pt-active {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #d8e1e8;
    background-image: none; }
  .pt-select select:disabled, .pt-select select.pt-disabled {
    outline: none;
    box-shadow: none;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
    .pt-select select:disabled.pt-active, .pt-select select.pt-disabled.pt-active {
      background: rgba(206, 217, 224, 0.7); }

.pt-select.pt-minimal select {
  box-shadow: none;
  background: none; }
  .pt-select.pt-minimal select:hover {
    box-shadow: none;
    background: rgba(167, 182, 194, 0.3);
    text-decoration: none;
    color: #182026; }
  .pt-select.pt-minimal select:active, .pt-select.pt-minimal select.pt-active {
    box-shadow: none;
    background: rgba(115, 134, 148, 0.3);
    color: #182026; }
  .pt-select.pt-minimal select:disabled, .pt-select.pt-minimal select:disabled:hover, .pt-select.pt-minimal select.pt-disabled, .pt-select.pt-minimal select.pt-disabled:hover {
    background: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-dark .pt-select.pt-minimal select, .pt-select.pt-minimal .pt-dark select {
    box-shadow: none;
    background: none;
    color: inherit; }
    .pt-dark .pt-select.pt-minimal select:hover, .pt-select.pt-minimal .pt-dark select:hover, .pt-dark .pt-select.pt-minimal select:active, .pt-select.pt-minimal .pt-dark select:active, .pt-dark .pt-select.pt-minimal select.pt-active, .pt-select.pt-minimal .pt-dark select.pt-active {
      box-shadow: none;
      background: none; }
    .pt-dark .pt-select.pt-minimal select:hover, .pt-select.pt-minimal .pt-dark select:hover {
      background: rgba(138, 155, 168, 0.15); }
    .pt-dark .pt-select.pt-minimal select:active, .pt-select.pt-minimal .pt-dark select:active, .pt-dark .pt-select.pt-minimal select.pt-active, .pt-select.pt-minimal .pt-dark select.pt-active {
      background: rgba(138, 155, 168, 0.3);
      color: #f5f8fa; }
    .pt-dark .pt-select.pt-minimal select:disabled, .pt-select.pt-minimal .pt-dark select:disabled, .pt-dark .pt-select.pt-minimal select:disabled:hover, .pt-select.pt-minimal .pt-dark select:disabled:hover, .pt-dark .pt-select.pt-minimal select.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-disabled, .pt-dark .pt-select.pt-minimal select.pt-disabled:hover, .pt-select.pt-minimal .pt-dark select.pt-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: rgba(191, 204, 214, 0.5); }
  .pt-select.pt-minimal select.pt-intent-primary {
    color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:hover, .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal select.pt-intent-primary.pt-active {
      box-shadow: none;
      background: none;
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:hover {
      background: rgba(19, 124, 189, 0.15);
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal select.pt-intent-primary.pt-active {
      background: rgba(19, 124, 189, 0.3);
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:disabled, .pt-select.pt-minimal select.pt-intent-primary.pt-disabled {
      background: none;
      color: rgba(16, 107, 163, 0.5); }
    .pt-select.pt-minimal select.pt-intent-primary .pt-button-spinner .pt-spinner-head {
      stroke: #106ba3; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-primary, .pt-select.pt-minimal .pt-dark select.pt-intent-primary {
      color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:hover {
        background: rgba(19, 124, 189, 0.2);
        color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:active, .pt-dark .pt-select.pt-minimal select.pt-intent-primary.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-primary.pt-active {
        background: rgba(19, 124, 189, 0.3);
        color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-primary.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-primary.pt-disabled {
        background: none;
        color: rgba(72, 175, 240, 0.5); }
  .pt-select.pt-minimal select.pt-intent-success {
    color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:hover, .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal select.pt-intent-success.pt-active {
      box-shadow: none;
      background: none;
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:hover {
      background: rgba(15, 153, 96, 0.15);
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal select.pt-intent-success.pt-active {
      background: rgba(15, 153, 96, 0.3);
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:disabled, .pt-select.pt-minimal select.pt-intent-success.pt-disabled {
      background: none;
      color: rgba(13, 128, 80, 0.5); }
    .pt-select.pt-minimal select.pt-intent-success .pt-button-spinner .pt-spinner-head {
      stroke: #0d8050; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-success, .pt-select.pt-minimal .pt-dark select.pt-intent-success {
      color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-success:hover {
        background: rgba(15, 153, 96, 0.2);
        color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal .pt-dark select.pt-intent-success:active, .pt-dark .pt-select.pt-minimal select.pt-intent-success.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-success.pt-active {
        background: rgba(15, 153, 96, 0.3);
        color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-success:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-success.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-success.pt-disabled {
        background: none;
        color: rgba(61, 204, 145, 0.5); }
  .pt-select.pt-minimal select.pt-intent-warning {
    color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:hover, .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal select.pt-intent-warning.pt-active {
      box-shadow: none;
      background: none;
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:hover {
      background: rgba(217, 130, 43, 0.15);
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal select.pt-intent-warning.pt-active {
      background: rgba(217, 130, 43, 0.3);
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:disabled, .pt-select.pt-minimal select.pt-intent-warning.pt-disabled {
      background: none;
      color: rgba(191, 115, 38, 0.5); }
    .pt-select.pt-minimal select.pt-intent-warning .pt-button-spinner .pt-spinner-head {
      stroke: #bf7326; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-warning, .pt-select.pt-minimal .pt-dark select.pt-intent-warning {
      color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:hover {
        background: rgba(217, 130, 43, 0.2);
        color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:active, .pt-dark .pt-select.pt-minimal select.pt-intent-warning.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-warning.pt-active {
        background: rgba(217, 130, 43, 0.3);
        color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-warning.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-warning.pt-disabled {
        background: none;
        color: rgba(255, 179, 102, 0.5); }
  .pt-select.pt-minimal select.pt-intent-danger {
    color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:hover, .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal select.pt-intent-danger.pt-active {
      box-shadow: none;
      background: none;
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:hover {
      background: rgba(219, 55, 55, 0.15);
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal select.pt-intent-danger.pt-active {
      background: rgba(219, 55, 55, 0.3);
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:disabled, .pt-select.pt-minimal select.pt-intent-danger.pt-disabled {
      background: none;
      color: rgba(194, 48, 48, 0.5); }
    .pt-select.pt-minimal select.pt-intent-danger .pt-button-spinner .pt-spinner-head {
      stroke: #c23030; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-danger, .pt-select.pt-minimal .pt-dark select.pt-intent-danger {
      color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:hover {
        background: rgba(219, 55, 55, 0.2);
        color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:active, .pt-dark .pt-select.pt-minimal select.pt-intent-danger.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-danger.pt-active {
        background: rgba(219, 55, 55, 0.3);
        color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-danger.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-danger.pt-disabled {
        background: none;
        color: rgba(255, 115, 115, 0.5); }

.pt-select.pt-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px; }

.pt-dark .pt-select select {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #394b59;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  color: #f5f8fa; }
  .pt-dark .pt-select select:hover, .pt-dark .pt-select select:active, .pt-dark .pt-select select.pt-active {
    color: #f5f8fa; }
  .pt-dark .pt-select select:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    background-color: #30404d; }
  .pt-dark .pt-select select:active, .pt-dark .pt-select select.pt-active {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #202b33;
    background-image: none; }
  .pt-dark .pt-select select:disabled, .pt-dark .pt-select select.pt-disabled {
    box-shadow: none;
    background-color: rgba(57, 75, 89, 0.5);
    background-image: none;
    color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-select select:disabled.pt-active, .pt-dark .pt-select select.pt-disabled.pt-active {
      background: rgba(57, 75, 89, 0.7); }
  .pt-dark .pt-select select .pt-button-spinner .pt-spinner-head {
    background: rgba(16, 22, 26, 0.5);
    stroke: #8a9ba8; }

.pt-select select:disabled {
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.pt-select::after {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  right: 7px;
  line-height: 30px;
  color: #5c7080;
  content: "";
  pointer-events: none; }
  .pt-disabled.pt-select::after {
    color: rgba(92, 112, 128, 0.5); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.pt-select select {
  display: inline-block;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 10px;
  vertical-align: middle;
  font-size: 14px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  height: 30px;
  padding: 0 25px 0 10px; }
  .pt-select select:hover {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5; }
  .pt-select select:active, .pt-select select.pt-active {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #d8e1e8;
    background-image: none; }
  .pt-select select:disabled, .pt-select select.pt-disabled {
    outline: none;
    box-shadow: none;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
    .pt-select select:disabled.pt-active, .pt-select select.pt-disabled.pt-active {
      background: rgba(206, 217, 224, 0.7); }

.pt-select.pt-minimal select {
  box-shadow: none;
  background: none; }
  .pt-select.pt-minimal select:hover {
    box-shadow: none;
    background: rgba(167, 182, 194, 0.3);
    text-decoration: none;
    color: #182026; }
  .pt-select.pt-minimal select:active, .pt-select.pt-minimal select.pt-active {
    box-shadow: none;
    background: rgba(115, 134, 148, 0.3);
    color: #182026; }
  .pt-select.pt-minimal select:disabled, .pt-select.pt-minimal select:disabled:hover, .pt-select.pt-minimal select.pt-disabled, .pt-select.pt-minimal select.pt-disabled:hover {
    background: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-dark .pt-select.pt-minimal select, .pt-select.pt-minimal .pt-dark select {
    box-shadow: none;
    background: none;
    color: inherit; }
    .pt-dark .pt-select.pt-minimal select:hover, .pt-select.pt-minimal .pt-dark select:hover, .pt-dark .pt-select.pt-minimal select:active, .pt-select.pt-minimal .pt-dark select:active, .pt-dark .pt-select.pt-minimal select.pt-active, .pt-select.pt-minimal .pt-dark select.pt-active {
      box-shadow: none;
      background: none; }
    .pt-dark .pt-select.pt-minimal select:hover, .pt-select.pt-minimal .pt-dark select:hover {
      background: rgba(138, 155, 168, 0.15); }
    .pt-dark .pt-select.pt-minimal select:active, .pt-select.pt-minimal .pt-dark select:active, .pt-dark .pt-select.pt-minimal select.pt-active, .pt-select.pt-minimal .pt-dark select.pt-active {
      background: rgba(138, 155, 168, 0.3);
      color: #f5f8fa; }
    .pt-dark .pt-select.pt-minimal select:disabled, .pt-select.pt-minimal .pt-dark select:disabled, .pt-dark .pt-select.pt-minimal select:disabled:hover, .pt-select.pt-minimal .pt-dark select:disabled:hover, .pt-dark .pt-select.pt-minimal select.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-disabled, .pt-dark .pt-select.pt-minimal select.pt-disabled:hover, .pt-select.pt-minimal .pt-dark select.pt-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: rgba(191, 204, 214, 0.5); }
  .pt-select.pt-minimal select.pt-intent-primary {
    color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:hover, .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal select.pt-intent-primary.pt-active {
      box-shadow: none;
      background: none;
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:hover {
      background: rgba(19, 124, 189, 0.15);
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal select.pt-intent-primary.pt-active {
      background: rgba(19, 124, 189, 0.3);
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:disabled, .pt-select.pt-minimal select.pt-intent-primary.pt-disabled {
      background: none;
      color: rgba(16, 107, 163, 0.5); }
    .pt-select.pt-minimal select.pt-intent-primary .pt-button-spinner .pt-spinner-head {
      stroke: #106ba3; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-primary, .pt-select.pt-minimal .pt-dark select.pt-intent-primary {
      color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:hover {
        background: rgba(19, 124, 189, 0.2);
        color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:active, .pt-dark .pt-select.pt-minimal select.pt-intent-primary.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-primary.pt-active {
        background: rgba(19, 124, 189, 0.3);
        color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-primary.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-primary.pt-disabled {
        background: none;
        color: rgba(72, 175, 240, 0.5); }
  .pt-select.pt-minimal select.pt-intent-success {
    color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:hover, .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal select.pt-intent-success.pt-active {
      box-shadow: none;
      background: none;
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:hover {
      background: rgba(15, 153, 96, 0.15);
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal select.pt-intent-success.pt-active {
      background: rgba(15, 153, 96, 0.3);
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:disabled, .pt-select.pt-minimal select.pt-intent-success.pt-disabled {
      background: none;
      color: rgba(13, 128, 80, 0.5); }
    .pt-select.pt-minimal select.pt-intent-success .pt-button-spinner .pt-spinner-head {
      stroke: #0d8050; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-success, .pt-select.pt-minimal .pt-dark select.pt-intent-success {
      color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-success:hover {
        background: rgba(15, 153, 96, 0.2);
        color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal .pt-dark select.pt-intent-success:active, .pt-dark .pt-select.pt-minimal select.pt-intent-success.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-success.pt-active {
        background: rgba(15, 153, 96, 0.3);
        color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-success:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-success.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-success.pt-disabled {
        background: none;
        color: rgba(61, 204, 145, 0.5); }
  .pt-select.pt-minimal select.pt-intent-warning {
    color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:hover, .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal select.pt-intent-warning.pt-active {
      box-shadow: none;
      background: none;
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:hover {
      background: rgba(217, 130, 43, 0.15);
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal select.pt-intent-warning.pt-active {
      background: rgba(217, 130, 43, 0.3);
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:disabled, .pt-select.pt-minimal select.pt-intent-warning.pt-disabled {
      background: none;
      color: rgba(191, 115, 38, 0.5); }
    .pt-select.pt-minimal select.pt-intent-warning .pt-button-spinner .pt-spinner-head {
      stroke: #bf7326; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-warning, .pt-select.pt-minimal .pt-dark select.pt-intent-warning {
      color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:hover {
        background: rgba(217, 130, 43, 0.2);
        color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:active, .pt-dark .pt-select.pt-minimal select.pt-intent-warning.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-warning.pt-active {
        background: rgba(217, 130, 43, 0.3);
        color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-warning.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-warning.pt-disabled {
        background: none;
        color: rgba(255, 179, 102, 0.5); }
  .pt-select.pt-minimal select.pt-intent-danger {
    color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:hover, .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal select.pt-intent-danger.pt-active {
      box-shadow: none;
      background: none;
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:hover {
      background: rgba(219, 55, 55, 0.15);
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal select.pt-intent-danger.pt-active {
      background: rgba(219, 55, 55, 0.3);
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:disabled, .pt-select.pt-minimal select.pt-intent-danger.pt-disabled {
      background: none;
      color: rgba(194, 48, 48, 0.5); }
    .pt-select.pt-minimal select.pt-intent-danger .pt-button-spinner .pt-spinner-head {
      stroke: #c23030; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-danger, .pt-select.pt-minimal .pt-dark select.pt-intent-danger {
      color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:hover {
        background: rgba(219, 55, 55, 0.2);
        color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:active, .pt-dark .pt-select.pt-minimal select.pt-intent-danger.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-danger.pt-active {
        background: rgba(219, 55, 55, 0.3);
        color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-danger.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-danger.pt-disabled {
        background: none;
        color: rgba(255, 115, 115, 0.5); }

.pt-select.pt-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px; }

.pt-dark .pt-select select {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #394b59;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  color: #f5f8fa; }
  .pt-dark .pt-select select:hover, .pt-dark .pt-select select:active, .pt-dark .pt-select select.pt-active {
    color: #f5f8fa; }
  .pt-dark .pt-select select:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    background-color: #30404d; }
  .pt-dark .pt-select select:active, .pt-dark .pt-select select.pt-active {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #202b33;
    background-image: none; }
  .pt-dark .pt-select select:disabled, .pt-dark .pt-select select.pt-disabled {
    box-shadow: none;
    background-color: rgba(57, 75, 89, 0.5);
    background-image: none;
    color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-select select:disabled.pt-active, .pt-dark .pt-select select.pt-disabled.pt-active {
      background: rgba(57, 75, 89, 0.7); }
  .pt-dark .pt-select select .pt-button-spinner .pt-spinner-head {
    background: rgba(16, 22, 26, 0.5);
    stroke: #8a9ba8; }

.pt-select select:disabled {
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.pt-select::after {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  right: 7px;
  line-height: 30px;
  color: #5c7080;
  content: "";
  pointer-events: none; }
  .pt-disabled.pt-select::after {
    color: rgba(92, 112, 128, 0.5); }

/*
Control groups

Markup:
<div class="pt-control-group">
  <button class="pt-button pt-icon-filter">Filter</button>
  <input type="text" class="pt-input" placeholder="Find filters..." />
</div>
<div class="pt-control-group">
  <div class="pt-select">
    <select>
      <option selected>Filter...</option>
      <option value="1">Issues</option>
      <option value="2">Requests</option>
      <option value="3">Projects</option>
    </select>
  </div>
  <div class="pt-input-group">
    <span class="pt-icon pt-icon-search"></span>
    <input type="text" class="pt-input" value="from:ggray to:allorca" />
  </div>
</div>
<div class="pt-control-group">
  <div class="pt-input-group">
    <span class="pt-icon pt-icon-people"></span>
    <input type="text" class="pt-input" placeholder="Find collaborators..." style="padding-right:94px" />
    <div class="pt-input-action">
      <button class="pt-button pt-minimal pt-intent-primary">
        can view<span class="pt-icon-standard pt-icon-caret-down pt-align-right"></span>
      </button>
    </div>
  </div>
  <button class="pt-button pt-intent-primary">Add</button>
</div>

Styleguide pt-control-group
*/
.pt-control-group {
  transform: translateZ(0);
  display: flex;
  align-items: flex-start;
  /*
  Responsive control groups

  Markup:
  <div class="pt-control-group">
    <div class="pt-input-group pt-fill">
      <span class="pt-icon pt-icon-people"></span>
      <input type="text" class="pt-input" placeholder="Find collaborators..." />
    </div>
    <button class="pt-button pt-intent-primary">Add</button>
  </div>
  <div class="pt-control-group pt-fill">
    <button class="pt-button pt-icon-minus pt-fixed"></button>
    <input type="text" class="pt-input" placeholder="Enter a value..." />
    <button class="pt-button pt-icon-plus pt-fixed"></button>
  </div>

  Styleguide pt-control-group.pt-fill
  */
  /*
  Vertical control groups

  Markup:
  <div class="pt-control-group pt-vertical" style="width: 300px;">
    <div class="pt-input-group pt-large">
      <span class="pt-icon pt-icon-person"></span>
      <input type="text" class="pt-input" placeholder="Username" />
    </div>
    <div class="pt-input-group pt-large">
      <span class="pt-icon pt-icon-lock"></span>
      <input type="password" class="pt-input" placeholder="Password" />
    </div>
    <button class="pt-button pt-large pt-intent-primary">Login</button>
  </div>

  Styleguide pt-control-group.pt-vertical
  */ }
  .pt-control-group > * {
    flex: 0 0 auto; }
  .pt-control-group .pt-button,
  .pt-control-group .pt-input,
  .pt-control-group .pt-select {
    position: relative; }
  .pt-control-group .pt-input {
    z-index: 2;
    border-radius: inherit; }
    .pt-control-group .pt-input:focus {
      z-index: 14;
      border-radius: 3px; }
    .pt-control-group .pt-input[class*="pt-intent"] {
      z-index: 13; }
      .pt-control-group .pt-input[class*="pt-intent"]:focus {
        z-index: 15; }
    .pt-control-group .pt-input[readonly], .pt-control-group .pt-input:disabled, .pt-control-group .pt-input.pt-disabled {
      z-index: 1; }
  .pt-control-group .pt-input-group[class*="pt-intent"] .pt-input {
    z-index: 13; }
    .pt-control-group .pt-input-group[class*="pt-intent"] .pt-input:focus {
      z-index: 15; }
  .pt-control-group .pt-button,
  .pt-control-group .pt-select select {
    z-index: 4;
    border-radius: inherit; }
    .pt-control-group .pt-button:focus,
    .pt-control-group .pt-select select:focus {
      position: relative;
      z-index: 5; }
    .pt-control-group .pt-button:hover,
    .pt-control-group .pt-select select:hover {
      z-index: 6; }
    .pt-control-group .pt-button:active,
    .pt-control-group .pt-select select:active {
      z-index: 7; }
    .pt-control-group .pt-button[readonly], .pt-control-group .pt-button:disabled, .pt-control-group .pt-button.pt-disabled,
    .pt-control-group .pt-select select[readonly],
    .pt-control-group .pt-select select:disabled,
    .pt-control-group .pt-select select.pt-disabled {
      z-index: 3; }
    .pt-control-group .pt-button[class*="pt-intent"],
    .pt-control-group .pt-select select[class*="pt-intent"] {
      z-index: 9; }
      .pt-control-group .pt-button[class*="pt-intent"]:focus,
      .pt-control-group .pt-select select[class*="pt-intent"]:focus {
        z-index: 10; }
      .pt-control-group .pt-button[class*="pt-intent"]:hover,
      .pt-control-group .pt-select select[class*="pt-intent"]:hover {
        z-index: 11; }
      .pt-control-group .pt-button[class*="pt-intent"]:active,
      .pt-control-group .pt-select select[class*="pt-intent"]:active {
        z-index: 12; }
      .pt-control-group .pt-button[class*="pt-intent"][readonly], .pt-control-group .pt-button[class*="pt-intent"]:disabled, .pt-control-group .pt-button[class*="pt-intent"].pt-disabled,
      .pt-control-group .pt-select select[class*="pt-intent"][readonly],
      .pt-control-group .pt-select select[class*="pt-intent"]:disabled,
      .pt-control-group .pt-select select[class*="pt-intent"].pt-disabled {
        z-index: 8; }
  .pt-control-group .pt-input-group > .pt-icon,
  .pt-control-group .pt-input-group > .pt-button,
  .pt-control-group .pt-input-group > .pt-input-action {
    z-index: 16; }
  .pt-control-group .pt-select::after {
    z-index: 17; }
  .pt-control-group:not(.pt-vertical) > * {
    margin-right: -1px; }
  .pt-dark .pt-control-group:not(.pt-vertical) > * {
    margin-right: 0; }
  .pt-dark .pt-control-group:not(.pt-vertical) > .pt-button + .pt-button {
    margin-left: 1px; }
  .pt-control-group > :first-child {
    border-radius: 3px 0 0 3px; }
  .pt-control-group > :last-child {
    margin-right: 0;
    border-radius: 0 3px 3px 0; }
  .pt-control-group .pt-input-group .pt-button {
    border-radius: 3px; }
  .pt-control-group > .pt-fill {
    flex: 1 1 auto; }
  .pt-control-group.pt-fill > *:not(.pt-fixed) {
    flex: 1 1 auto; }
  .pt-control-group.pt-vertical {
    flex-direction: column; }
    .pt-control-group.pt-vertical > * {
      margin-top: -1px;
      width: 100%; }
    .pt-control-group.pt-vertical > :first-child {
      margin-top: 0;
      border-radius: 3px 3px 0 0; }
    .pt-control-group.pt-vertical > :last-child {
      border-radius: 0 0 3px 3px; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.pt-control {
  display: block;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  min-height: 16px;
  padding-left: 26px;
  text-transform: none;
  line-height: 16px;
  /*
  Checkbox

  Markup:
  <label class="pt-control pt-checkbox {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="pt-control-indicator"></span>
    Checkbox
  </label>

  :checked  - Checked
  :disabled - Disabled. Also add <code>.pt-disabled</code> to <code>.pt-control</code> to change text color (not shown below).
  :indeterminate - Indeterminate. Note that this style can only be achieved via JavaScript
                   <code>input.indeterminate = true</code>.
  .pt-align-right - Right-aligned indicator
  .pt-large - Large

  Styleguide pt-checkbox
  */
  /*
  Radio

  Markup:
  <label class="pt-control pt-radio {{.modifier}}">
    <input type="radio" name="docs-radio-regular" {{:modifier}} />
    <span class="pt-control-indicator"></span>
    Radio
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.pt-disabled</code> to <code>.pt-control</code> to change text color (not shown below).
  .pt-align-right - Right-aligned indicator
  .pt-large - Large

  Styleguide pt-radio
  */
  /*
  Switch

  Markup:
  <label class="pt-control pt-switch {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="pt-control-indicator"></span>
    Switch
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.pt-disabled</code> to <code>.pt-control</code> to change text color (not shown below).
  .pt-align-right - Right-aligned indicator
  .pt-large - Large

  Styleguide pt-switch
  */
  /*
  Inline labels

  Markup:
  <label class="pt-label">A group of related options</label>
  <label class="pt-control pt-checkbox pt-inline">
    <input type="checkbox" />
    <span class="pt-control-indicator"></span>
    First
  </label>
  <label class="pt-control pt-checkbox pt-inline">
    <input type="checkbox" />
    <span class="pt-control-indicator"></span>
    Second
  </label>
  <label class="pt-control pt-checkbox pt-inline">
    <input type="checkbox" />
    <span class="pt-control-indicator"></span>
    Third
  </label>

  Styleguide pt-checkbox.pt-inline
  */ }
  .pt-control.pt-disabled {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-control.pt-inline {
    display: inline-block;
    margin-right: 20px; }
  .pt-control input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1; }
  .pt-control .pt-control-indicator {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    border: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    background-color: #f5f8fa;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    cursor: pointer;
    width: 16px;
    height: 16px;
    line-height: 16px;
    user-select: none; }
    .pt-control .pt-control-indicator::before {
      position: relative;
      content: ""; }
  .pt-control input:checked ~ .pt-control-indicator, .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    background-color: #137cbd;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    color: #ffffff; }
  .pt-control:hover .pt-control-indicator {
    background-color: #ebf1f5; }
  .pt-control:hover input:checked ~ .pt-control-indicator, .pt-control:hover .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control:hover input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox:hover input:indeterminate ~ .pt-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    background-color: #106ba3; }
  .pt-control input:not(:disabled):active ~ .pt-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background: #d8e1e8; }
  .pt-control input:not(:disabled):active:checked ~ .pt-control-indicator, .pt-control.pt-checkbox input:not(:disabled):active:indeterminate ~ .pt-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background: #0e5a8a; }
  .pt-control input:focus ~ .pt-control-indicator {
    outline: rgba(19, 124, 189, 0.5) auto 2px;
    outline-offset: 2px;
    -moz-outline-radius: 6px; }
  .pt-control input:disabled ~ .pt-control-indicator {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed; }
  .pt-control input:disabled:checked ~ .pt-control-indicator, .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator {
    box-shadow: none;
    background: rgba(19, 124, 189, 0.5); }
  .pt-control.pt-checkbox .pt-control-indicator {
    border-radius: 3px;
    font-size: 16px; }
  .pt-control.pt-checkbox input:checked ~ .pt-control-indicator::before, .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before {
    content: ""; }
  .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before {
    content: ""; }
  .pt-control.pt-radio .pt-control-indicator {
    border-radius: 50%;
    font-size: 6px; }
  .pt-control.pt-radio input:checked ~ .pt-control-indicator::before, .pt-control.pt-radio .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-control.pt-radio input:indeterminate ~ .pt-control-indicator::before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #ffffff;
    width: 1em;
    height: 1em;
    content: ""; }
  .pt-control.pt-radio input:checked:disabled ~ .pt-control-indicator::before, .pt-control.pt-radio .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-control.pt-radio input:disabled:indeterminate ~ .pt-control-indicator::before {
    box-shadow: none; }
  .pt-control.pt-radio input:focus ~ .pt-control-indicator {
    -moz-outline-radius: 16px; }
  .pt-control.pt-switch {
    padding-left: 38px; }
    .pt-control.pt-switch .pt-control-indicator {
      border: none;
      border-radius: 28px;
      box-shadow: none;
      background: rgba(167, 182, 194, 0.5);
      width: 28px;
      height: 16px;
      transition: background 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
      .pt-control.pt-switch .pt-control-indicator::before {
        display: block;
        top: 2px;
        left: 2px;
        border-radius: 16px;
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
        background: #ffffff;
        background-clip: padding-box;
        width: 12px;
        height: 12px;
        content: "";
        transition: left 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
    .pt-control.pt-switch input:checked ~ .pt-control-indicator, .pt-control.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control.pt-switch input:indeterminate ~ .pt-control-indicator {
      box-shadow: none;
      background-color: #137cbd;
      width: 28px;
      height: 16px; }
      .pt-control.pt-switch input:checked ~ .pt-control-indicator::before, .pt-control.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-control.pt-switch input:indeterminate ~ .pt-control-indicator::before {
        left: 14px;
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-control.pt-switch:hover .pt-control-indicator {
      background-color: rgba(115, 134, 148, 0.5); }
    .pt-control.pt-switch:hover input:checked ~ .pt-control-indicator, .pt-control.pt-switch:hover .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control.pt-switch:hover input:indeterminate ~ .pt-control-indicator {
      background-color: #106ba3; }
    .pt-control.pt-switch input:not(:disabled):active ~ .pt-control-indicator {
      box-shadow: none;
      background-color: rgba(92, 112, 128, 0.5); }
    .pt-control.pt-switch input:not(:disabled):active:checked ~ .pt-control-indicator, .pt-control.pt-switch .pt-control.pt-checkbox input:not(:disabled):active:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control.pt-switch input:not(:disabled):active:indeterminate ~ .pt-control-indicator {
      background-color: #0e5a8a; }
      .pt-control.pt-switch input:not(:disabled):active:checked ~ .pt-control-indicator::before, .pt-control.pt-switch .pt-control.pt-checkbox input:not(:disabled):active:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-control.pt-switch input:not(:disabled):active:indeterminate ~ .pt-control-indicator::before {
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-control.pt-switch input:disabled ~ .pt-control-indicator {
      background-color: rgba(206, 217, 224, 0.5); }
      .pt-control.pt-switch input:disabled ~ .pt-control-indicator::before {
        box-shadow: none;
        background-color: rgba(255, 255, 255, 0.8); }
    .pt-control.pt-switch input:disabled:checked ~ .pt-control-indicator, .pt-control.pt-switch .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control.pt-switch input:disabled:indeterminate ~ .pt-control-indicator {
      background-color: rgba(19, 124, 189, 0.5); }
  .pt-control.pt-align-right {
    padding-right: 26px;
    padding-left: 0; }
    .pt-control.pt-align-right .pt-control-indicator {
      right: 0;
      left: auto; }
  .pt-control.pt-large {
    min-height: 20px;
    padding-left: 30px;
    line-height: 20px;
    font-size: 16px; }
    .pt-control.pt-large .pt-control-indicator {
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-family: "Icons20";
      font-size: 20px; }
    .pt-control.pt-large.pt-checkbox input:checked ~ .pt-control-indicator::before, .pt-control.pt-large.pt-checkbox input:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-large.pt-checkbox
    .pt-control.pt-checkbox:hover input:indeterminate ~ .pt-control-indicator::before {
      top: 1px; }
    .pt-control.pt-large.pt-radio .pt-control-indicator {
      font-size: 8px; }
    .pt-control.pt-large.pt-switch {
      padding-left: 42px; }
      .pt-control.pt-large.pt-switch .pt-control-indicator {
        width: 32px;
        height: 20px; }
        .pt-control.pt-large.pt-switch .pt-control-indicator::before {
          width: 16px;
          height: 16px; }
      .pt-control.pt-large.pt-switch input:checked ~ .pt-control-indicator, .pt-control.pt-large.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control.pt-large.pt-switch input:indeterminate ~ .pt-control-indicator {
        width: 32px;
        height: 20px; }
        .pt-control.pt-large.pt-switch input:checked ~ .pt-control-indicator::before, .pt-control.pt-large.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-control.pt-large.pt-switch input:indeterminate ~ .pt-control-indicator::before {
          left: 14px; }
  .pt-dark .pt-control {
    color: #f5f8fa; }
    .pt-dark .pt-control.pt-disabled {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-control .pt-control-indicator {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
      background-color: #394b59;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0)); }
    .pt-dark .pt-control input:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-control:hover .pt-control-indicator {
      background-color: #30404d; }
    .pt-dark .pt-control input:not(:disabled):active ~ .pt-control-indicator {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background: #202b33; }
    .pt-dark .pt-control input:not(:disabled):active:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-checkbox input:not(:disabled):active:indeterminate ~ .pt-control-indicator {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #0e5a8a; }
    .pt-dark .pt-control input:disabled ~ .pt-control-indicator {
      box-shadow: none;
      background: rgba(57, 75, 89, 0.5);
      cursor: not-allowed; }
    .pt-dark .pt-control input:disabled:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator {
      box-shadow: none;
      background: rgba(14, 90, 138, 0.5); }
    .pt-dark .pt-control.pt-checkbox input:checked:disabled ~ .pt-control-indicator::before, .pt-dark .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator::before {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-control.pt-radio input:checked:disabled ~ .pt-control-indicator::before, .pt-dark .pt-control.pt-radio .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-dark .pt-control.pt-radio input:disabled:indeterminate ~ .pt-control-indicator::before {
      background: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-control.pt-switch .pt-control-indicator {
      box-shadow: none;
      background: rgba(16, 22, 26, 0.5); }
      .pt-dark .pt-control.pt-switch .pt-control-indicator::before {
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
        background: #30404d; }
    .pt-dark .pt-control.pt-switch input:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch input:indeterminate ~ .pt-control-indicator {
      box-shadow: none;
      background-color: #137cbd; }
      .pt-dark .pt-control.pt-switch input:checked ~ .pt-control-indicator::before, .pt-dark .pt-control.pt-switch .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch input:indeterminate ~ .pt-control-indicator::before {
        box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-control.pt-switch:hover .pt-control-indicator {
      background: rgba(16, 22, 26, 0.7); }
    .pt-dark .pt-control.pt-switch:hover input:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-switch:hover .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch:hover input:indeterminate ~ .pt-control-indicator {
      background: #2b95d6; }
    .pt-dark .pt-control.pt-switch input:not(:disabled):active ~ .pt-control-indicator {
      box-shadow: none;
      background: rgba(16, 22, 26, 0.9); }
      .pt-dark .pt-control.pt-switch input:not(:disabled):active ~ .pt-control-indicator::before {
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-control.pt-switch input:not(:disabled):active:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-switch .pt-control.pt-checkbox input:not(:disabled):active:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch input:not(:disabled):active:indeterminate ~ .pt-control-indicator {
      background: #48aff0; }
      .pt-dark .pt-control.pt-switch input:not(:disabled):active:checked ~ .pt-control-indicator::before, .pt-dark .pt-control.pt-switch .pt-control.pt-checkbox input:not(:disabled):active:indeterminate ~ .pt-control-indicator::before, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch input:not(:disabled):active:indeterminate ~ .pt-control-indicator::before {
        box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-control.pt-switch input:disabled ~ .pt-control-indicator {
      background: rgba(57, 75, 89, 0.5); }
      .pt-dark .pt-control.pt-switch input:disabled ~ .pt-control-indicator::before {
        box-shadow: none;
        background: rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-control.pt-switch input:disabled:checked ~ .pt-control-indicator, .pt-dark .pt-control.pt-switch .pt-control.pt-checkbox input:disabled:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-dark .pt-control.pt-switch input:disabled:indeterminate ~ .pt-control-indicator {
      background: rgba(14, 90, 138, 0.5); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
File upload

Markup:
<label class="pt-file-upload {{.modifier}}">
  <input type="file" {{:modifier}}/>
  <span class="pt-file-upload-input">Choose file...</span>
</label>

:disabled - Disabled
.pt-large - Larger size
.pt-fill - Take up full width of parent element

Styleguide pt-file-upload
*/
.pt-file-upload {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 30px; }
  .pt-file-upload input {
    opacity: 0;
    margin: 0;
    min-width: 200px; }
    .pt-file-upload input:disabled + .pt-file-upload-input,
    .pt-file-upload input.pt-disabled + .pt-file-upload-input {
      box-shadow: none;
      background: rgba(206, 217, 224, 0.5);
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5);
      resize: none; }
      .pt-file-upload input:disabled + .pt-file-upload-input::after,
      .pt-file-upload input.pt-disabled + .pt-file-upload-input::after {
        outline: none;
        box-shadow: none;
        background-color: rgba(206, 217, 224, 0.5);
        background-image: none;
        cursor: not-allowed;
        color: rgba(92, 112, 128, 0.5); }
        .pt-file-upload input:disabled + .pt-file-upload-input::after.pt-active,
        .pt-file-upload input.pt-disabled + .pt-file-upload-input::after.pt-active {
          background: rgba(206, 217, 224, 0.7); }
      .pt-dark .pt-file-upload input:disabled + .pt-file-upload-input, .pt-dark
      .pt-file-upload input.pt-disabled + .pt-file-upload-input {
        box-shadow: none;
        background: rgba(57, 75, 89, 0.5);
        color: rgba(191, 204, 214, 0.5); }
        .pt-dark .pt-file-upload input:disabled + .pt-file-upload-input::after, .pt-dark
        .pt-file-upload input.pt-disabled + .pt-file-upload-input::after {
          box-shadow: none;
          background-color: rgba(57, 75, 89, 0.5);
          background-image: none;
          color: rgba(191, 204, 214, 0.5); }
          .pt-dark .pt-file-upload input:disabled + .pt-file-upload-input::after.pt-active, .pt-dark
          .pt-file-upload input.pt-disabled + .pt-file-upload-input::after.pt-active {
            background: rgba(57, 75, 89, 0.7); }
  .pt-file-upload.pt-fill {
    width: 100%; }
  .pt-file-upload.pt-large,
  .pt-large .pt-file-upload {
    height: 40px; }

.pt-file-upload-input {
  outline: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  height: 30px;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 30px;
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  appearance: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding-right: 80px;
  user-select: none; }
  .pt-file-upload-input::placeholder {
    opacity: 1;
    color: rgba(92, 112, 128, 0.5); }
  .pt-file-upload-input:focus, .pt-file-upload-input.pt-active {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-file-upload-input[type="search"], .pt-file-upload-input.pt-round {
    border-radius: 30px;
    box-sizing: border-box;
    padding-left: 10px; }
  .pt-file-upload-input[readonly] {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .pt-file-upload-input:disabled, .pt-file-upload-input.pt-disabled {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5);
    resize: none; }
  .pt-file-upload-input::after {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-color: #f5f8fa;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    color: #182026;
    min-width: 24px;
    min-height: 24px;
    line-height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 3px;
    border-radius: 3px;
    width: 70px;
    text-align: center;
    content: "Browse"; }
    .pt-file-upload-input::after:hover {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
      background-clip: padding-box;
      background-color: #ebf1f5; }
    .pt-file-upload-input::after:active, .pt-file-upload-input::after.pt-active {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #d8e1e8;
      background-image: none; }
    .pt-file-upload-input::after:disabled, .pt-file-upload-input::after.pt-disabled {
      outline: none;
      box-shadow: none;
      background-color: rgba(206, 217, 224, 0.5);
      background-image: none;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
      .pt-file-upload-input::after:disabled.pt-active, .pt-file-upload-input::after.pt-disabled.pt-active {
        background: rgba(206, 217, 224, 0.7); }
  .pt-file-upload-input:hover::after {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5; }
  .pt-file-upload-input:active::after {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #d8e1e8;
    background-image: none; }
  .pt-large .pt-file-upload-input {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding-right: 95px; }
    .pt-large .pt-file-upload-input[type="search"], .pt-large .pt-file-upload-input.pt-round {
      padding: 0 15px; }
    .pt-large .pt-file-upload-input::after {
      min-width: 30px;
      min-height: 30px;
      line-height: 30px;
      margin: 5px;
      width: 85px; }
  .pt-dark .pt-file-upload-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
    color: #f5f8fa;
    color: #bfccd6; }
    .pt-dark .pt-file-upload-input::placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-file-upload-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-file-upload-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-file-upload-input:disabled, .pt-dark .pt-file-upload-input.pt-disabled {
      box-shadow: none;
      background: rgba(57, 75, 89, 0.5);
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-file-upload-input::after {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
      background-color: #394b59;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
      color: #f5f8fa; }
      .pt-dark .pt-file-upload-input::after:hover, .pt-dark .pt-file-upload-input::after:active, .pt-dark .pt-file-upload-input::after.pt-active {
        color: #f5f8fa; }
      .pt-dark .pt-file-upload-input::after:hover {
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
        background-color: #30404d; }
      .pt-dark .pt-file-upload-input::after:active, .pt-dark .pt-file-upload-input::after.pt-active {
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
        background-color: #202b33;
        background-image: none; }
      .pt-dark .pt-file-upload-input::after:disabled, .pt-dark .pt-file-upload-input::after.pt-disabled {
        box-shadow: none;
        background-color: rgba(57, 75, 89, 0.5);
        background-image: none;
        color: rgba(191, 204, 214, 0.5); }
        .pt-dark .pt-file-upload-input::after:disabled.pt-active, .pt-dark .pt-file-upload-input::after.pt-disabled.pt-active {
          background: rgba(57, 75, 89, 0.7); }
      .pt-dark .pt-file-upload-input::after .pt-button-spinner .pt-spinner-head {
        background: rgba(16, 22, 26, 0.5);
        stroke: #8a9ba8; }
    .pt-dark .pt-file-upload-input:hover::after {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
      background-color: #30404d; }
    .pt-dark .pt-file-upload-input:active::after {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #202b33;
      background-image: none; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Form groups

Markup:
<div class="pt-form-group">
  <label class="pt-label" for="example-form-group-input-a">
    Label A
    <span class="pt-text-muted">(required)</span>
  </label>
  <div class="pt-form-content">
    <input id="example-form-group-input-a" class="pt-input" style="width: 300px;" placeholder="Placeholder text" type="text" dir="auto" />
    <div class="pt-form-helper-text">Helper text with details / user feedback</div>
  </div>
</div>
<div class="pt-form-group pt-intent-danger">
  <label class="pt-label" for="example-form-group-input-b">
    Label B
    <span class="pt-text-muted">(required)</span>
  </label>
  <div class="pt-form-content">
    <div class="pt-input-group pt-intent-danger">
      <span class="pt-icon pt-icon-calendar"></span>
      <input id="example-form-group-input-b" class="pt-input" style="width: 200px;" type="text" placeholder="Placeholder text" dir="auto" />
    </div>
    <div class="pt-form-helper-text">Please enter a value</div>
  </div>
</div>
<div class="pt-form-group">
  <label class="pt-label" for="example-form-group-switch-c">
    Label C
    <span class="pt-text-muted">(required)</span>
  </label>
  <div class="pt-form-content">
    <label class="pt-control pt-switch">
      <input id="example-form-group-switch-c" type="checkbox" />
      <span class="pt-control-indicator"></span>
      Switch C
    </label>
    <div class="pt-form-helper-text">Helper text with details / user feedback</div>
  </div>
</div>
<div class="pt-form-group pt-inline">
  <label class="pt-label" for="example-form-group-input-d">
    Label D
    <span class="pt-text-muted">(optional)</span>
  </label>
  <div class="pt-form-content">
    <div class="pt-input-group">
      <span class="pt-icon pt-icon-calendar"></span>
      <input id="example-form-group-input-d" class="pt-input" style="width: 200px;" type="text" placeholder="Placeholder text" dir="auto" />
    </div>
    <div class="pt-form-helper-text">Helper text with details / user feedback</div>
  </div>
</div>
<div class="pt-form-group pt-inline pt-large pt-disabled">
  <label class="pt-label" for="example-form-group-input-e">
    Label E
    <span class="pt-text-muted">(optional)</span>
  </label>
  <div class="pt-form-content">
    <div class="pt-input-group pt-large pt-disabled">
      <span class="pt-icon pt-icon-calendar"></span>
      <input id="example-form-group-input-e" class="pt-input" disabled style="width: 200px;" type="text" placeholder="Placeholder text" dir="auto" />
    </div>
    <div class="pt-form-helper-text">Helper text with details / user feedback</div>
  </div>
</div>
<div class="pt-form-group pt-inline">
  <label class="pt-label" for="example-form-group-switch-f">
    Label F
  </label>
  <div class="pt-form-content">
    <label class="pt-control pt-switch">
      <input id="example-form-group-switch-f" type="checkbox" />
      <span class="pt-control-indicator"></span>
      Switch F
    </label>
    <div class="pt-form-helper-text">Helper text with details / user feedback</div>
  </div>
</div>
<div class="pt-form-group pt-inline pt-large pt-disabled">
  <label class="pt-label" for="example-form-group-switch-g">
    Label G
  </label>
  <div class="pt-form-content">
    <label class="pt-control pt-switch pt-large pt-disabled">
      <input id="example-form-group-switch-g" type="checkbox" disabled />
      <span class="pt-control-indicator"></span>
      Switch G
    </label>
    <div class="pt-form-helper-text">Helper text with details / user feedback</div>
  </div>
</div>

Styleguide pt-form-group
*/
.pt-form-group {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px; }
  .pt-form-group label.pt-label {
    margin-bottom: 5px; }
  .pt-form-group .pt-control {
    margin-top: 5px; }
  .pt-form-group .pt-form-helper-text {
    margin-top: 5px;
    color: #5c7080;
    font-size: 12px; }
  .pt-form-group.pt-intent-primary .pt-form-helper-text {
    color: #106ba3; }
  .pt-form-group.pt-intent-success .pt-form-helper-text {
    color: #0d8050; }
  .pt-form-group.pt-intent-warning .pt-form-helper-text {
    color: #bf7326; }
  .pt-form-group.pt-intent-danger .pt-form-helper-text {
    color: #c23030; }
  .pt-form-group.pt-inline {
    flex-direction: row;
    align-items: baseline; }
    .pt-form-group.pt-inline.pt-large label.pt-label {
      margin: 0 10px 0 0;
      line-height: 40px; }
    .pt-form-group.pt-inline label.pt-label {
      margin: 0 10px 0 0;
      line-height: 30px; }
  .pt-form-group.pt-disabled .pt-label,
  .pt-form-group.pt-disabled .pt-text-muted,
  .pt-form-group.pt-disabled .pt-form-helper-text {
    color: rgba(92, 112, 128, 0.5) !important; }
  .pt-dark .pt-form-group.pt-intent-primary .pt-form-helper-text {
    color: #48aff0; }
  .pt-dark .pt-form-group.pt-intent-success .pt-form-helper-text {
    color: #3dcc91; }
  .pt-dark .pt-form-group.pt-intent-warning .pt-form-helper-text {
    color: #ffb366; }
  .pt-dark .pt-form-group.pt-intent-danger .pt-form-helper-text {
    color: #ff7373; }
  .pt-dark .pt-form-group .pt-form-helper-text {
    color: #bfccd6; }
  .pt-dark .pt-form-group.pt-disabled .pt-label,
  .pt-dark .pt-form-group.pt-disabled .pt-text-muted,
  .pt-dark .pt-form-group.pt-disabled .pt-form-helper-text {
    color: rgba(191, 204, 214, 0.5) !important; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Input groups

Markup:
<div class="pt-input-group {{.modifier}}">
  <span class="pt-icon pt-icon-filter"></span>
  <input type="text" class="pt-input" {{:modifier}} placeholder="Filter histogram..." />
</div>
<div class="pt-input-group {{.modifier}}">
  <input type="password" class="pt-input" {{:modifier}} placeholder="Enter your password..." />
  <button class="pt-button pt-minimal pt-intent-warning pt-icon-lock" {{:modifier}}></button>
</div>
<div class="pt-input-group {{.modifier}}">
  <span class="pt-icon pt-icon-search"></span>
  <input type="text" class="pt-input" {{:modifier}} placeholder="Search" />
  <button class="pt-button pt-minimal pt-intent-primary pt-icon-arrow-right" {{:modifier}}></button>
</div>

:disabled - Disabled input. Must be added separately to the <code>&#60;input&#62;</code> and <code>&#60;button&#62;</code>. Also add <code>.pt-disabled</code> to <code>.pt-input-group</code> for icon coloring (not shown below).
.pt-round - Rounded caps. Button will also be rounded.
.pt-large - Large group. Children will adjust size accordingly.
.pt-intent-primary - Primary intent. (All 4 intents are supported.)

Styleguide pt-input-group
*/
.pt-input-group {
  display: block;
  position: relative; }
  .pt-input-group .pt-input {
    position: relative;
    width: 100%; }
    .pt-input-group .pt-input:not(:first-child) {
      padding-left: 30px; }
    .pt-input-group .pt-input:not(:last-child) {
      padding-right: 30px; }
  .pt-input-group .pt-input-action,
  .pt-input-group > .pt-button,
  .pt-input-group > .pt-icon {
    position: absolute;
    top: 0; }
    .pt-input-group .pt-input-action:first-child,
    .pt-input-group > .pt-button:first-child,
    .pt-input-group > .pt-icon:first-child {
      left: 0; }
    .pt-input-group .pt-input-action:last-child,
    .pt-input-group > .pt-button:last-child,
    .pt-input-group > .pt-icon:last-child {
      right: 0; }
  .pt-input-group .pt-button {
    min-width: 24px;
    min-height: 24px;
    line-height: 24px;
    margin: 3px;
    padding-top: 0;
    padding-bottom: 0; }
  .pt-input-group .pt-icon {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    z-index: 1;
    margin: 0 7px;
    line-height: 30px;
    color: #5c7080; }
  .pt-input-group .pt-input-action > .pt-spinner {
    margin: 3px; }
  .pt-input-group .pt-tag {
    margin: 5px; }
  .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:not(:hover):not(:focus),
  .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:not(:hover):not(:focus) {
    color: #5c7080; }
    .pt-dark .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:not(:hover):not(:focus), .pt-dark
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:not(:hover):not(:focus) {
      color: #bfccd6; }
    .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon, .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon-standard, .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon-large,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon-standard,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:not(:hover):not(:focus) .pt-icon-large {
      color: #5c7080; }
  .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:disabled,
  .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:disabled {
    color: rgba(92, 112, 128, 0.5) !important; }
    .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:disabled .pt-icon, .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:disabled .pt-icon-standard, .pt-input-group .pt-input:not(:focus) + .pt-button.pt-minimal:disabled .pt-icon-large,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:disabled .pt-icon,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:disabled .pt-icon-standard,
    .pt-input-group .pt-input:not(:focus) + .pt-input-action .pt-button.pt-minimal:disabled .pt-icon-large {
      color: rgba(92, 112, 128, 0.5) !important; }
  .pt-input-group.pt-disabled {
    cursor: not-allowed; }
    .pt-input-group.pt-disabled .pt-icon {
      color: rgba(92, 112, 128, 0.5); }
  .pt-input-group.pt-large .pt-button {
    min-width: 30px;
    min-height: 30px;
    line-height: 30px;
    margin: 5px;
    line-height: 0; }
  .pt-input-group.pt-large .pt-icon {
    margin: 0 12px;
    line-height: 40px; }
  .pt-input-group.pt-large .pt-input {
    height: 40px;
    line-height: 40px;
    font-size: 16px; }
    .pt-input-group.pt-large .pt-input[type="search"], .pt-input-group.pt-large .pt-input.pt-round {
      padding: 0 15px; }
    .pt-input-group.pt-large .pt-input:not(:first-child) {
      padding-left: 40px; }
    .pt-input-group.pt-large .pt-input:not(:last-child) {
      padding-right: 40px; }
  .pt-input-group.pt-large .pt-input-action > .pt-spinner {
    margin: 8px; }
  .pt-input-group.pt-round .pt-button,
  .pt-input-group.pt-round .pt-input,
  .pt-input-group.pt-round .pt-tag {
    border-radius: 30px; }
  .pt-dark .pt-input-group .pt-icon {
    color: #bfccd6; }
  .pt-dark .pt-input-group.pt-disabled .pt-icon {
    color: rgba(191, 204, 214, 0.5); }
  .pt-input-group.pt-intent-primary .pt-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-primary .pt-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-primary .pt-input[readonly] {
      box-shadow: inset 0 0 0 1px #137cbd; }
    .pt-input-group.pt-intent-primary .pt-input:disabled, .pt-input-group.pt-intent-primary .pt-input.pt-disabled {
      box-shadow: none; }
  .pt-input-group.pt-intent-primary .pt-icon {
    color: #106ba3; }
    .pt-dark .pt-input-group.pt-intent-primary .pt-icon {
      color: #48aff0; }
  .pt-input-group.pt-intent-success .pt-input {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-success .pt-input:focus {
      box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-success .pt-input[readonly] {
      box-shadow: inset 0 0 0 1px #0f9960; }
    .pt-input-group.pt-intent-success .pt-input:disabled, .pt-input-group.pt-intent-success .pt-input.pt-disabled {
      box-shadow: none; }
  .pt-input-group.pt-intent-success .pt-icon {
    color: #0d8050; }
    .pt-dark .pt-input-group.pt-intent-success .pt-icon {
      color: #3dcc91; }
  .pt-input-group.pt-intent-warning .pt-input {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-warning .pt-input:focus {
      box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-warning .pt-input[readonly] {
      box-shadow: inset 0 0 0 1px #d9822b; }
    .pt-input-group.pt-intent-warning .pt-input:disabled, .pt-input-group.pt-intent-warning .pt-input.pt-disabled {
      box-shadow: none; }
  .pt-input-group.pt-intent-warning .pt-icon {
    color: #bf7326; }
    .pt-dark .pt-input-group.pt-intent-warning .pt-icon {
      color: #ffb366; }
  .pt-input-group.pt-intent-danger .pt-input {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-danger .pt-input:focus {
      box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input-group.pt-intent-danger .pt-input[readonly] {
      box-shadow: inset 0 0 0 1px #db3737; }
    .pt-input-group.pt-intent-danger .pt-input:disabled, .pt-input-group.pt-intent-danger .pt-input.pt-disabled {
      box-shadow: none; }
  .pt-input-group.pt-intent-danger .pt-icon {
    color: #c23030; }
    .pt-dark .pt-input-group.pt-intent-danger .pt-icon {
      color: #ff7373; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Text inputs

Markup:
<input class="pt-input {{.modifier}}" {{:modifier}} type="text" placeholder="Text input" dir="auto" />

:disabled - Disabled
:readonly - Readonly
.pt-round - Rounded ends
.pt-large - Larger size
.pt-intent-primary - Primary intent
.pt-intent-success - Success intent
.pt-intent-warning - Warning intent
.pt-intent-danger - Danger intent
.pt-fill - Take up full width of parent element

Styleguide pt-input
*/
.pt-input {
  outline: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  height: 30px;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 30px;
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  appearance: none; }
  .pt-input::placeholder {
    opacity: 1;
    color: rgba(92, 112, 128, 0.5); }
  .pt-input:focus, .pt-input.pt-active {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .pt-input[type="search"], .pt-input.pt-round {
    border-radius: 30px;
    box-sizing: border-box;
    padding-left: 10px; }
  .pt-input[readonly] {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .pt-input:disabled, .pt-input.pt-disabled {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5);
    resize: none; }
  .pt-input.pt-large {
    height: 40px;
    line-height: 40px;
    font-size: 16px; }
    .pt-input.pt-large[type="search"], .pt-input.pt-large.pt-round {
      padding: 0 15px; }
  .pt-input.pt-fill {
    width: 100%; }
  .pt-dark .pt-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
    color: #f5f8fa; }
    .pt-dark .pt-input::placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-input:disabled, .pt-dark .pt-input.pt-disabled {
      box-shadow: none;
      background: rgba(57, 75, 89, 0.5);
      color: rgba(191, 204, 214, 0.5); }
  .pt-input.pt-intent-primary {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-primary:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-primary[readonly] {
      box-shadow: inset 0 0 0 1px #137cbd; }
    .pt-input.pt-intent-primary:disabled, .pt-input.pt-intent-primary.pt-disabled {
      box-shadow: none; }
    .pt-dark .pt-input.pt-intent-primary {
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-primary:focus {
        box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-primary[readonly] {
        box-shadow: inset 0 0 0 1px #137cbd; }
      .pt-dark .pt-input.pt-intent-primary:disabled, .pt-dark .pt-input.pt-intent-primary.pt-disabled {
        box-shadow: none; }
  .pt-input.pt-intent-success {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-success:focus {
      box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-success[readonly] {
      box-shadow: inset 0 0 0 1px #0f9960; }
    .pt-input.pt-intent-success:disabled, .pt-input.pt-intent-success.pt-disabled {
      box-shadow: none; }
    .pt-dark .pt-input.pt-intent-success {
      box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-success:focus {
        box-shadow: 0 0 0 1px #0f9960, 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-success[readonly] {
        box-shadow: inset 0 0 0 1px #0f9960; }
      .pt-dark .pt-input.pt-intent-success:disabled, .pt-dark .pt-input.pt-intent-success.pt-disabled {
        box-shadow: none; }
  .pt-input.pt-intent-warning {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-warning:focus {
      box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-warning[readonly] {
      box-shadow: inset 0 0 0 1px #d9822b; }
    .pt-input.pt-intent-warning:disabled, .pt-input.pt-intent-warning.pt-disabled {
      box-shadow: none; }
    .pt-dark .pt-input.pt-intent-warning {
      box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-warning:focus {
        box-shadow: 0 0 0 1px #d9822b, 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-warning[readonly] {
        box-shadow: inset 0 0 0 1px #d9822b; }
      .pt-dark .pt-input.pt-intent-warning:disabled, .pt-dark .pt-input.pt-intent-warning.pt-disabled {
        box-shadow: none; }
  .pt-input.pt-intent-danger {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-danger:focus {
      box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .pt-input.pt-intent-danger[readonly] {
      box-shadow: inset 0 0 0 1px #db3737; }
    .pt-input.pt-intent-danger:disabled, .pt-input.pt-intent-danger.pt-disabled {
      box-shadow: none; }
    .pt-dark .pt-input.pt-intent-danger {
      box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-danger:focus {
        box-shadow: 0 0 0 1px #db3737, 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .pt-dark .pt-input.pt-intent-danger[readonly] {
        box-shadow: inset 0 0 0 1px #db3737; }
      .pt-dark .pt-input.pt-intent-danger:disabled, .pt-dark .pt-input.pt-intent-danger.pt-disabled {
        box-shadow: none; }
  .pt-input::-ms-clear {
    display: none; }

/*
Search inputs

Markup:
<div class="pt-input-group {{.modifier}}">
  <span class="pt-icon pt-icon-search"></span>
  <input class="pt-input" {{:modifier}} type="search" placeholder="Search input" dir="auto" />
</div>

:disabled - Disabled. Also add <code>.pt-disabled</code> to <code>.pt-input-group</code> for icon coloring (not shown below).
.pt-large - Large

Styleguide pt-input.pt-search
*/
/*
Textareas

Markup:
<textarea class="pt-input {{.modifier}}" {{:modifier}} dir="auto"></textarea>

:disabled - Disabled
:readonly - Readonly
.pt-large - Larger font size
.pt-intent-primary - Primary intent
.pt-intent-danger  - Danger intent
.pt-fill  - Take up full width of parent element

Styleguide pt-textarea
*/
textarea.pt-input {
  max-width: 100%;
  height: auto;
  padding: 10px;
  line-height: 1.28581; }
  textarea.pt-input.pt-large {
    line-height: 1.28581;
    font-size: 16px; }
  .pt-dark textarea.pt-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    background: rgba(16, 22, 26, 0.3);
    color: #f5f8fa; }
    .pt-dark textarea.pt-input::placeholder {
      color: rgba(191, 204, 214, 0.5); }
    .pt-dark textarea.pt-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .pt-dark textarea.pt-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .pt-dark textarea.pt-input:disabled, .pt-dark textarea.pt-input.pt-disabled {
      box-shadow: none;
      background: rgba(57, 75, 89, 0.5);
      color: rgba(191, 204, 214, 0.5); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Labels

Markup:
<label class="pt-label {{.modifier}}">
  Label A
  <span class="pt-text-muted">(required)</span>
  <input class="pt-input" style="width: 200px;" type="text" placeholder="Text input" dir="auto" />
</label>
<label class="pt-label {{.modifier}}">
  Label B
  <span class="pt-text-muted">(optional)</span>
  <div class="pt-input-group">
    <span class="pt-icon pt-icon-calendar"></span>
    <input class="pt-input" style="width: 200px;" type="text" placeholder="Input group" dir="auto" />
  </div>
</label>

.pt-inline - Inline

Styleguide pt-label
*/
label.pt-label {
  display: block;
  margin: 0 0 15px;
  /*
  Disabled labels

  Markup:
  <label class="pt-label pt-disabled">
    Label A
    <span class="pt-text-muted">(optional)</span>
    <input disabled class="pt-input" style="width: 200px;" type="text" placeholder="Text input" dir="auto" />
  </label>
  <label class="pt-label pt-disabled">
    Label B
    <div class="pt-input-group pt-disabled">
      <span class="pt-icon pt-icon-calendar"></span>
      <input disabled class="pt-input" style="width: 200px;" type="text" placeholder="Input group" dir="auto" />
    </div>
  </label>
  <label class="pt-label pt-disabled">
    Label C
    <div class="pt-select pt-disabled">
      <select disabled>
        <option selected>Choose an item...</option>
        <option value="1">One</option>
      </select>
    </div>
  </label>

  Styleguide pt-label.pt-disabled
  */ }
  label.pt-label .pt-input,
  label.pt-label .pt-select {
    display: block;
    margin-top: 5px;
    text-transform: none; }
  label.pt-label .pt-select select {
    width: 100%;
    vertical-align: top;
    font-weight: 400; }
  label.pt-label.pt-inline {
    line-height: 30px; }
    label.pt-label.pt-inline .pt-input,
    label.pt-label.pt-inline .pt-input-group,
    label.pt-label.pt-inline .pt-select {
      display: inline-block;
      margin: 0 0 0 5px;
      vertical-align: top; }
    label.pt-label.pt-inline .pt-input-group .pt-input {
      margin-left: 0; }
    label.pt-label.pt-inline.pt-large {
      line-height: 40px; }
  label.pt-label.pt-disabled,
  label.pt-label.pt-disabled .pt-text-muted {
    color: rgba(92, 112, 128, 0.5); }
  .pt-dark label.pt-label {
    color: #f5f8fa; }
    .pt-dark label.pt-label.pt-disabled,
    .pt-dark label.pt-label.pt-disabled .pt-text-muted {
      color: rgba(191, 204, 214, 0.5); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
Selects

Markup:
<div class="pt-select {{.modifier}}">
  <select {{:modifier}}>
    <option selected>Choose an item...</option>
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
    <option value="4">Four</option>
  </select>
</div>

:disabled - Disabled. Also add <code>.pt-disabled</code> to <code>.pt-select</code> for icon coloring (not shown below).
.pt-minimal - Minimal appearance
.pt-large - Large
.pt-fill - Expand to fill parent container

Styleguide pt-select
*/
.pt-select {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: normal; }
  .pt-select select::-ms-expand {
    display: none; }
  .pt-select.pt-large::after {
    top: 0;
    right: 12px;
    line-height: 40px; }
  .pt-select.pt-fill,
  .pt-select.pt-fill select {
    width: 100%; }
  .pt-dark .pt-select option {
    background-color: #30404d;
    color: #f5f8fa; }
  .pt-dark .pt-select::after {
    color: #bfccd6; }

/*
Inline

Markup:
<label class="pt-label {{.modifier}}">
  Label A
  <div class="pt-select">
    <select>
      <option selected>Choose an item...</option>
      <option value="1">One</option>
    </select>
  </div>
</label>

.pt-inline - Inline

Styleguide pt-select.pt-inline
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.pt-select select {
  display: inline-block;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 10px;
  vertical-align: middle;
  font-size: 14px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  height: 30px;
  padding: 0 25px 0 10px; }
  .pt-select select:hover {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5; }
  .pt-select select:active, .pt-select select.pt-active {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #d8e1e8;
    background-image: none; }
  .pt-select select:disabled, .pt-select select.pt-disabled {
    outline: none;
    box-shadow: none;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
    .pt-select select:disabled.pt-active, .pt-select select.pt-disabled.pt-active {
      background: rgba(206, 217, 224, 0.7); }

.pt-select.pt-minimal select {
  box-shadow: none;
  background: none; }
  .pt-select.pt-minimal select:hover {
    box-shadow: none;
    background: rgba(167, 182, 194, 0.3);
    text-decoration: none;
    color: #182026; }
  .pt-select.pt-minimal select:active, .pt-select.pt-minimal select.pt-active {
    box-shadow: none;
    background: rgba(115, 134, 148, 0.3);
    color: #182026; }
  .pt-select.pt-minimal select:disabled, .pt-select.pt-minimal select:disabled:hover, .pt-select.pt-minimal select.pt-disabled, .pt-select.pt-minimal select.pt-disabled:hover {
    background: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-dark .pt-select.pt-minimal select, .pt-select.pt-minimal .pt-dark select {
    box-shadow: none;
    background: none;
    color: inherit; }
    .pt-dark .pt-select.pt-minimal select:hover, .pt-select.pt-minimal .pt-dark select:hover, .pt-dark .pt-select.pt-minimal select:active, .pt-select.pt-minimal .pt-dark select:active, .pt-dark .pt-select.pt-minimal select.pt-active, .pt-select.pt-minimal .pt-dark select.pt-active {
      box-shadow: none;
      background: none; }
    .pt-dark .pt-select.pt-minimal select:hover, .pt-select.pt-minimal .pt-dark select:hover {
      background: rgba(138, 155, 168, 0.15); }
    .pt-dark .pt-select.pt-minimal select:active, .pt-select.pt-minimal .pt-dark select:active, .pt-dark .pt-select.pt-minimal select.pt-active, .pt-select.pt-minimal .pt-dark select.pt-active {
      background: rgba(138, 155, 168, 0.3);
      color: #f5f8fa; }
    .pt-dark .pt-select.pt-minimal select:disabled, .pt-select.pt-minimal .pt-dark select:disabled, .pt-dark .pt-select.pt-minimal select:disabled:hover, .pt-select.pt-minimal .pt-dark select:disabled:hover, .pt-dark .pt-select.pt-minimal select.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-disabled, .pt-dark .pt-select.pt-minimal select.pt-disabled:hover, .pt-select.pt-minimal .pt-dark select.pt-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: rgba(191, 204, 214, 0.5); }
  .pt-select.pt-minimal select.pt-intent-primary {
    color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:hover, .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal select.pt-intent-primary.pt-active {
      box-shadow: none;
      background: none;
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:hover {
      background: rgba(19, 124, 189, 0.15);
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal select.pt-intent-primary.pt-active {
      background: rgba(19, 124, 189, 0.3);
      color: #106ba3; }
    .pt-select.pt-minimal select.pt-intent-primary:disabled, .pt-select.pt-minimal select.pt-intent-primary.pt-disabled {
      background: none;
      color: rgba(16, 107, 163, 0.5); }
    .pt-select.pt-minimal select.pt-intent-primary .pt-button-spinner .pt-spinner-head {
      stroke: #106ba3; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-primary, .pt-select.pt-minimal .pt-dark select.pt-intent-primary {
      color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:hover {
        background: rgba(19, 124, 189, 0.2);
        color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:active, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:active, .pt-dark .pt-select.pt-minimal select.pt-intent-primary.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-primary.pt-active {
        background: rgba(19, 124, 189, 0.3);
        color: #48aff0; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-primary:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-primary:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-primary.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-primary.pt-disabled {
        background: none;
        color: rgba(72, 175, 240, 0.5); }
  .pt-select.pt-minimal select.pt-intent-success {
    color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:hover, .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal select.pt-intent-success.pt-active {
      box-shadow: none;
      background: none;
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:hover {
      background: rgba(15, 153, 96, 0.15);
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal select.pt-intent-success.pt-active {
      background: rgba(15, 153, 96, 0.3);
      color: #0d8050; }
    .pt-select.pt-minimal select.pt-intent-success:disabled, .pt-select.pt-minimal select.pt-intent-success.pt-disabled {
      background: none;
      color: rgba(13, 128, 80, 0.5); }
    .pt-select.pt-minimal select.pt-intent-success .pt-button-spinner .pt-spinner-head {
      stroke: #0d8050; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-success, .pt-select.pt-minimal .pt-dark select.pt-intent-success {
      color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-success:hover {
        background: rgba(15, 153, 96, 0.2);
        color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:active, .pt-select.pt-minimal .pt-dark select.pt-intent-success:active, .pt-dark .pt-select.pt-minimal select.pt-intent-success.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-success.pt-active {
        background: rgba(15, 153, 96, 0.3);
        color: #3dcc91; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-success:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-success:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-success.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-success.pt-disabled {
        background: none;
        color: rgba(61, 204, 145, 0.5); }
  .pt-select.pt-minimal select.pt-intent-warning {
    color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:hover, .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal select.pt-intent-warning.pt-active {
      box-shadow: none;
      background: none;
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:hover {
      background: rgba(217, 130, 43, 0.15);
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal select.pt-intent-warning.pt-active {
      background: rgba(217, 130, 43, 0.3);
      color: #bf7326; }
    .pt-select.pt-minimal select.pt-intent-warning:disabled, .pt-select.pt-minimal select.pt-intent-warning.pt-disabled {
      background: none;
      color: rgba(191, 115, 38, 0.5); }
    .pt-select.pt-minimal select.pt-intent-warning .pt-button-spinner .pt-spinner-head {
      stroke: #bf7326; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-warning, .pt-select.pt-minimal .pt-dark select.pt-intent-warning {
      color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:hover {
        background: rgba(217, 130, 43, 0.2);
        color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:active, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:active, .pt-dark .pt-select.pt-minimal select.pt-intent-warning.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-warning.pt-active {
        background: rgba(217, 130, 43, 0.3);
        color: #ffb366; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-warning:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-warning:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-warning.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-warning.pt-disabled {
        background: none;
        color: rgba(255, 179, 102, 0.5); }
  .pt-select.pt-minimal select.pt-intent-danger {
    color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:hover, .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal select.pt-intent-danger.pt-active {
      box-shadow: none;
      background: none;
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:hover {
      background: rgba(219, 55, 55, 0.15);
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal select.pt-intent-danger.pt-active {
      background: rgba(219, 55, 55, 0.3);
      color: #c23030; }
    .pt-select.pt-minimal select.pt-intent-danger:disabled, .pt-select.pt-minimal select.pt-intent-danger.pt-disabled {
      background: none;
      color: rgba(194, 48, 48, 0.5); }
    .pt-select.pt-minimal select.pt-intent-danger .pt-button-spinner .pt-spinner-head {
      stroke: #c23030; }
    .pt-dark .pt-select.pt-minimal select.pt-intent-danger, .pt-select.pt-minimal .pt-dark select.pt-intent-danger {
      color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:hover, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:hover {
        background: rgba(219, 55, 55, 0.2);
        color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:active, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:active, .pt-dark .pt-select.pt-minimal select.pt-intent-danger.pt-active, .pt-select.pt-minimal .pt-dark select.pt-intent-danger.pt-active {
        background: rgba(219, 55, 55, 0.3);
        color: #ff7373; }
      .pt-dark .pt-select.pt-minimal select.pt-intent-danger:disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-danger:disabled, .pt-dark .pt-select.pt-minimal select.pt-intent-danger.pt-disabled, .pt-select.pt-minimal .pt-dark select.pt-intent-danger.pt-disabled {
        background: none;
        color: rgba(255, 115, 115, 0.5); }

.pt-select.pt-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px; }

.pt-dark .pt-select select {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  background-color: #394b59;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  color: #f5f8fa; }
  .pt-dark .pt-select select:hover, .pt-dark .pt-select select:active, .pt-dark .pt-select select.pt-active {
    color: #f5f8fa; }
  .pt-dark .pt-select select:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    background-color: #30404d; }
  .pt-dark .pt-select select:active, .pt-dark .pt-select select.pt-active {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #202b33;
    background-image: none; }
  .pt-dark .pt-select select:disabled, .pt-dark .pt-select select.pt-disabled {
    box-shadow: none;
    background-color: rgba(57, 75, 89, 0.5);
    background-image: none;
    color: rgba(191, 204, 214, 0.5); }
    .pt-dark .pt-select select:disabled.pt-active, .pt-dark .pt-select select.pt-disabled.pt-active {
      background: rgba(57, 75, 89, 0.7); }
  .pt-dark .pt-select select .pt-button-spinner .pt-spinner-head {
    background: rgba(16, 22, 26, 0.5);
    stroke: #8a9ba8; }

.pt-select select:disabled {
  box-shadow: none;
  background-color: rgba(206, 217, 224, 0.5);
  cursor: not-allowed;
  color: rgba(92, 112, 128, 0.5); }

.pt-select::after {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  right: 7px;
  line-height: 30px;
  color: #5c7080;
  content: "";
  pointer-events: none; }
  .pt-disabled.pt-select::after {
    color: rgba(92, 112, 128, 0.5); }

.pt-numeric-input .pt-button-group.pt-vertical > .pt-button:first-child {
  min-height: 16px; }

.pt-numeric-input .pt-button-group.pt-vertical > .pt-button:last-child {
  min-height: 15px; }

.pt-numeric-input .pt-button-group.pt-vertical > .pt-button:first-child {
  border-radius: 0 3px 0 0; }

.pt-numeric-input .pt-button-group.pt-vertical > .pt-button:last-child {
  border-radius: 0 0 3px 0; }

.pt-numeric-input .pt-button-group.pt-vertical > .pt-button[class*="pt-icon-"]::before {
  display: block;
  height: 14px;
  overflow: hidden;
  line-height: 14px; }

.pt-numeric-input .pt-button-group.pt-vertical:first-child > .pt-button:first-child {
  border-radius: 3px 0 0 0; }

.pt-numeric-input .pt-button-group.pt-vertical:first-child > .pt-button:last-child {
  border-radius: 0 0 0 3px; }

.pt-numeric-input.pt-large .pt-button-group.pt-vertical > .pt-button:first-child {
  min-height: 21px; }

.pt-numeric-input.pt-large .pt-button-group.pt-vertical > .pt-button:last-child {
  min-height: 20px; }

.pt-dark .pt-numeric-input .pt-button-group.pt-vertical > .pt-button {
  line-height: 14px; }
  .pt-dark .pt-numeric-input .pt-button-group.pt-vertical > .pt-button:first-child {
    min-height: 15px; }
  .pt-dark .pt-numeric-input .pt-button-group.pt-vertical > .pt-button:last-child {
    min-height: 14px; }

.pt-dark .pt-numeric-input.pt-large .pt-button-group.pt-vertical > .pt-button:first-child {
  min-height: 20px; }

.pt-dark .pt-numeric-input.pt-large .pt-button-group.pt-vertical > .pt-button:last-child {
  min-height: 19px; }

form {
  display: block; }

/*
 * Copyright 2016 Palantir Technologies, Inc. All rights reserved.
 */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
.pt-key {
  display: inline-block;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
  background: #ffffff;
  min-width: 25px;
  height: 25px;
  padding: 3px 5px;
  vertical-align: middle;
  text-align: center;
  line-height: 20px;
  color: #5c7080;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
  font-size: 12px; }
  .pt-key.pt-modifier-key {
    padding: 3px 8px 3px 6px; }
    .pt-key.pt-modifier-key .pt-icon-standard {
      margin-right: 5px; }
  .pt-dark .pt-key {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4), 0 2px 6px rgba(16, 22, 26, 0.4), inset 0 1px 0 rgba(255, 255, 255, 0.05);
    background: #394b59;
    color: #bfccd6; }

.pt-key-combo .pt-key:not(:last-child) {
  margin-right: 5px; }

.pt-hotkey-dialog {
  top: 40px;
  padding-bottom: 0; }
  .pt-hotkey-dialog .pt-dialog-body {
    margin: 0;
    padding: 0; }
  .pt-hotkey-dialog .pt-key-combo {
    flex: 1 0 auto;
    width: 160px;
    text-align: right; }

.pt-hotkey-column {
  margin: auto;
  max-height: 80vh;
  overflow-y: auto;
  padding: 30px; }
  .pt-hotkey-column .pt-hotkey-group {
    margin-bottom: 30px; }
    .pt-hotkey-column .pt-hotkey-group:not(:first-child) {
      margin-top: 50px; }

.pt-hotkey {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 0;
  margin-left: 0; }
  .pt-hotkey:not(:last-child) {
    margin-bottom: 10px; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
.pt-icon, .pt-icon-standard, .pt-icon-large {
  display: inline-block; }
  .pt-icon.pt-intent-primary, .pt-icon-standard.pt-intent-primary, .pt-icon-large.pt-intent-primary {
    color: #137cbd; }
  .pt-icon.pt-intent-success, .pt-icon-standard.pt-intent-success, .pt-icon-large.pt-intent-success {
    color: #0f9960; }
  .pt-icon.pt-intent-warning, .pt-icon-standard.pt-intent-warning, .pt-icon-large.pt-intent-warning {
    color: #d9822b; }
  .pt-icon.pt-intent-danger, .pt-icon-standard.pt-intent-danger, .pt-icon-large.pt-intent-danger {
    color: #db3737; }

span.pt-icon-standard {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

span.pt-icon-large {
  line-height: 1;
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

span.pt-icon {
  line-height: 1;
  font-family: "Icons20";
  font-size: inherit;
  font-weight: 400;
  font-style: normal; }
  span.pt-icon::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; }

.pt-icon-blank::before {
  content: ""; }

.pt-icon-style::before {
  content: ""; }

.pt-icon-align-left::before {
  content: ""; }

.pt-icon-align-center::before {
  content: ""; }

.pt-icon-align-right::before {
  content: ""; }

.pt-icon-align-justify::before {
  content: ""; }

.pt-icon-bold::before {
  content: ""; }

.pt-icon-italic::before {
  content: ""; }

.pt-icon-underline::before {
  content: "⎁"; }

.pt-icon-search-around::before {
  content: ""; }

.pt-icon-graph-remove::before {
  content: ""; }

.pt-icon-group-objects::before {
  content: ""; }

.pt-icon-merge-links::before {
  content: ""; }

.pt-icon-layout::before {
  content: ""; }

.pt-icon-layout-auto::before {
  content: ""; }

.pt-icon-layout-circle::before {
  content: ""; }

.pt-icon-layout-hierarchy::before {
  content: ""; }

.pt-icon-layout-grid::before {
  content: ""; }

.pt-icon-layout-group-by::before {
  content: ""; }

.pt-icon-layout-skew-grid::before {
  content: ""; }

.pt-icon-geosearch::before {
  content: ""; }

.pt-icon-heatmap::before {
  content: ""; }

.pt-icon-drive-time::before {
  content: ""; }

.pt-icon-select::before {
  content: ""; }

.pt-icon-predictive-analysis::before {
  content: ""; }

.pt-icon-layers::before {
  content: ""; }

.pt-icon-locate::before {
  content: ""; }

.pt-icon-bookmark::before {
  content: ""; }

.pt-icon-citation::before {
  content: ""; }

.pt-icon-tag::before {
  content: ""; }

.pt-icon-clipboard::before {
  content: ""; }

.pt-icon-selection::before {
  content: "⦿"; }

.pt-icon-timeline-events::before {
  content: ""; }

.pt-icon-timeline-line-chart::before {
  content: ""; }

.pt-icon-timeline-bar-chart::before {
  content: ""; }

.pt-icon-applications::before {
  content: ""; }

.pt-icon-projects::before {
  content: ""; }

.pt-icon-changes::before {
  content: ""; }

.pt-icon-notifications::before {
  content: ""; }

.pt-icon-lock::before {
  content: ""; }

.pt-icon-unlock::before {
  content: ""; }

.pt-icon-user::before {
  content: ""; }

.pt-icon-search-template::before {
  content: ""; }

.pt-icon-inbox::before {
  content: ""; }

.pt-icon-more::before {
  content: ""; }

.pt-icon-help::before {
  content: "?"; }

.pt-icon-calendar::before {
  content: ""; }

.pt-icon-media::before {
  content: ""; }

.pt-icon-link::before {
  content: ""; }

.pt-icon-share::before {
  content: ""; }

.pt-icon-download::before {
  content: ""; }

.pt-icon-document::before {
  content: ""; }

.pt-icon-properties::before {
  content: ""; }

.pt-icon-import::before {
  content: ""; }

.pt-icon-export::before {
  content: ""; }

.pt-icon-minimize::before {
  content: ""; }

.pt-icon-maximize::before {
  content: ""; }

.pt-icon-tick::before {
  content: "✓"; }

.pt-icon-cross::before {
  content: "✗"; }

.pt-icon-plus::before {
  content: "+"; }

.pt-icon-minus::before {
  content: "−"; }

.pt-icon-arrow-left::before {
  content: "←"; }

.pt-icon-arrow-right::before {
  content: "→"; }

.pt-icon-exchange::before {
  content: ""; }

.pt-icon-comparison::before {
  content: ""; }

.pt-icon-list::before {
  content: "☰"; }

.pt-icon-filter::before {
  content: ""; }

.pt-icon-confirm::before {
  content: ""; }

.pt-icon-fork::before {
  content: ""; }

.pt-icon-trash::before {
  content: ""; }

.pt-icon-person::before {
  content: ""; }

.pt-icon-people::before {
  content: ""; }

.pt-icon-add::before {
  content: ""; }

.pt-icon-remove::before {
  content: ""; }

.pt-icon-geolocation::before {
  content: ""; }

.pt-icon-zoom-in::before {
  content: ""; }

.pt-icon-zoom-out::before {
  content: ""; }

.pt-icon-refresh::before {
  content: ""; }

.pt-icon-delete::before {
  content: ""; }

.pt-icon-cog::before {
  content: ""; }

.pt-icon-flag::before {
  content: "⚑"; }

.pt-icon-pin::before {
  content: ""; }

.pt-icon-warning-sign::before {
  content: ""; }

.pt-icon-error::before {
  content: ""; }

.pt-icon-info-sign::before {
  content: "ℹ"; }

.pt-icon-credit-card::before {
  content: ""; }

.pt-icon-edit::before {
  content: "✎"; }

.pt-icon-history::before {
  content: ""; }

.pt-icon-search::before {
  content: ""; }

.pt-icon-log-out::before {
  content: ""; }

.pt-icon-star::before {
  content: "★"; }

.pt-icon-star-empty::before {
  content: "☆"; }

.pt-icon-sort-alphabetical::before {
  content: ""; }

.pt-icon-sort-numerical::before {
  content: ""; }

.pt-icon-sort::before {
  content: ""; }

.pt-icon-folder-open::before {
  content: ""; }

.pt-icon-folder-close::before {
  content: ""; }

.pt-icon-folder-shared::before {
  content: ""; }

.pt-icon-caret-up::before {
  content: "⌃"; }

.pt-icon-caret-right::before {
  content: "〉"; }

.pt-icon-caret-down::before {
  content: "⌄"; }

.pt-icon-caret-left::before {
  content: "〈"; }

.pt-icon-menu-open::before {
  content: ""; }

.pt-icon-menu-closed::before {
  content: ""; }

.pt-icon-feed::before {
  content: ""; }

.pt-icon-two-columns::before {
  content: ""; }

.pt-icon-one-column::before {
  content: ""; }

.pt-icon-dot::before {
  content: "•"; }

.pt-icon-property::before {
  content: ""; }

.pt-icon-time::before {
  content: "⏲"; }

.pt-icon-disable::before {
  content: ""; }

.pt-icon-unpin::before {
  content: ""; }

.pt-icon-flows::before {
  content: ""; }

.pt-icon-new-text-box::before {
  content: ""; }

.pt-icon-new-link::before {
  content: ""; }

.pt-icon-new-object::before {
  content: ""; }

.pt-icon-path-search::before {
  content: ""; }

.pt-icon-automatic-updates::before {
  content: ""; }

.pt-icon-page-layout::before {
  content: ""; }

.pt-icon-code::before {
  content: ""; }

.pt-icon-map::before {
  content: ""; }

.pt-icon-search-text::before {
  content: ""; }

.pt-icon-envelope::before {
  content: "✉"; }

.pt-icon-paperclip::before {
  content: ""; }

.pt-icon-label::before {
  content: ""; }

.pt-icon-globe::before {
  content: ""; }

.pt-icon-home::before {
  content: "⌂"; }

.pt-icon-th::before {
  content: ""; }

.pt-icon-th-list::before {
  content: ""; }

.pt-icon-th-derived::before {
  content: ""; }

.pt-icon-circle::before {
  content: ""; }

.pt-icon-draw::before {
  content: ""; }

.pt-icon-insert::before {
  content: ""; }

.pt-icon-helper-management::before {
  content: ""; }

.pt-icon-send-to::before {
  content: ""; }

.pt-icon-eye-open::before {
  content: ""; }

.pt-icon-folder-shared-open::before {
  content: ""; }

.pt-icon-social-media::before {
  content: ""; }

.pt-icon-arrow-up::before {
  content: "↑"; }

.pt-icon-arrow-down::before {
  content: "↓"; }

.pt-icon-arrows-horizontal::before {
  content: "↔"; }

.pt-icon-arrows-vertical::before {
  content: "↕"; }

.pt-icon-resolve::before {
  content: ""; }

.pt-icon-graph::before {
  content: ""; }

.pt-icon-briefcase::before {
  content: ""; }

.pt-icon-dollar::before {
  content: "$"; }

.pt-icon-ninja::before {
  content: ""; }

.pt-icon-delta::before {
  content: "Δ"; }

.pt-icon-barcode::before {
  content: ""; }

.pt-icon-torch::before {
  content: ""; }

.pt-icon-widget::before {
  content: ""; }

.pt-icon-unresolve::before {
  content: ""; }

.pt-icon-offline::before {
  content: ""; }

.pt-icon-zoom-to-fit::before {
  content: ""; }

.pt-icon-add-to-artifact::before {
  content: ""; }

.pt-icon-map-marker::before {
  content: ""; }

.pt-icon-chart::before {
  content: ""; }

.pt-icon-control::before {
  content: ""; }

.pt-icon-multi-select::before {
  content: ""; }

.pt-icon-direction-left::before {
  content: ""; }

.pt-icon-direction-right::before {
  content: ""; }

.pt-icon-database::before {
  content: ""; }

.pt-icon-pie-chart::before {
  content: ""; }

.pt-icon-full-circle::before {
  content: ""; }

.pt-icon-square::before {
  content: ""; }

.pt-icon-print::before {
  content: "⎙"; }

.pt-icon-presentation::before {
  content: ""; }

.pt-icon-ungroup-objects::before {
  content: ""; }

.pt-icon-chat::before {
  content: ""; }

.pt-icon-comment::before {
  content: ""; }

.pt-icon-circle-arrow-right::before {
  content: ""; }

.pt-icon-circle-arrow-left::before {
  content: ""; }

.pt-icon-circle-arrow-up::before {
  content: ""; }

.pt-icon-circle-arrow-down::before {
  content: ""; }

.pt-icon-upload::before {
  content: ""; }

.pt-icon-asterisk::before {
  content: "*"; }

.pt-icon-cloud::before {
  content: "☁"; }

.pt-icon-cloud-download::before {
  content: ""; }

.pt-icon-cloud-upload::before {
  content: ""; }

.pt-icon-repeat::before {
  content: ""; }

.pt-icon-move::before {
  content: ""; }

.pt-icon-chevron-left::before {
  content: ""; }

.pt-icon-chevron-right::before {
  content: ""; }

.pt-icon-chevron-up::before {
  content: ""; }

.pt-icon-chevron-down::before {
  content: ""; }

.pt-icon-random::before {
  content: ""; }

.pt-icon-fullscreen::before {
  content: ""; }

.pt-icon-log-in::before {
  content: ""; }

.pt-icon-heart::before {
  content: "♥"; }

.pt-icon-office::before {
  content: ""; }

.pt-icon-duplicate::before {
  content: ""; }

.pt-icon-ban-circle::before {
  content: ""; }

.pt-icon-camera::before {
  content: ""; }

.pt-icon-mobile-video::before {
  content: ""; }

.pt-icon-video::before {
  content: ""; }

.pt-icon-film::before {
  content: ""; }

.pt-icon-settings::before {
  content: ""; }

.pt-icon-volume-off::before {
  content: ""; }

.pt-icon-volume-down::before {
  content: ""; }

.pt-icon-volume-up::before {
  content: ""; }

.pt-icon-music::before {
  content: ""; }

.pt-icon-step-backward::before {
  content: ""; }

.pt-icon-fast-backward::before {
  content: ""; }

.pt-icon-pause::before {
  content: ""; }

.pt-icon-stop::before {
  content: ""; }

.pt-icon-play::before {
  content: ""; }

.pt-icon-fast-forward::before {
  content: ""; }

.pt-icon-step-forward::before {
  content: ""; }

.pt-icon-eject::before {
  content: "⏏"; }

.pt-icon-record::before {
  content: ""; }

.pt-icon-desktop::before {
  content: ""; }

.pt-icon-phone::before {
  content: "☎"; }

.pt-icon-lightbulb::before {
  content: ""; }

.pt-icon-glass::before {
  content: ""; }

.pt-icon-tint::before {
  content: ""; }

.pt-icon-flash::before {
  content: ""; }

.pt-icon-font::before {
  content: ""; }

.pt-icon-header::before {
  content: ""; }

.pt-icon-saved::before {
  content: ""; }

.pt-icon-floppy-disk::before {
  content: ""; }

.pt-icon-book::before {
  content: ""; }

.pt-icon-hand-right::before {
  content: ""; }

.pt-icon-hand-up::before {
  content: ""; }

.pt-icon-hand-down::before {
  content: ""; }

.pt-icon-hand-left::before {
  content: ""; }

.pt-icon-thumbs-up::before {
  content: ""; }

.pt-icon-thumbs-down::before {
  content: ""; }

.pt-icon-box::before {
  content: ""; }

.pt-icon-compressed::before {
  content: ""; }

.pt-icon-shopping-cart::before {
  content: ""; }

.pt-icon-shop::before {
  content: ""; }

.pt-icon-layout-linear::before {
  content: ""; }

.pt-icon-undo::before {
  content: "⎌"; }

.pt-icon-redo::before {
  content: ""; }

.pt-icon-code-block::before {
  content: ""; }

.pt-icon-double-caret-vertical::before {
  content: ""; }

.pt-icon-double-caret-horizontal::before {
  content: ""; }

.pt-icon-sort-alphabetical-desc::before {
  content: ""; }

.pt-icon-sort-numerical-desc::before {
  content: ""; }

.pt-icon-take-action::before {
  content: ""; }

.pt-icon-contrast::before {
  content: ""; }

.pt-icon-eye-off::before {
  content: ""; }

.pt-icon-timeline-area-chart::before {
  content: ""; }

.pt-icon-doughnut-chart::before {
  content: ""; }

.pt-icon-layer::before {
  content: ""; }

.pt-icon-grid::before {
  content: ""; }

.pt-icon-polygon-filter::before {
  content: ""; }

.pt-icon-add-to-folder::before {
  content: ""; }

.pt-icon-layout-balloon::before {
  content: ""; }

.pt-icon-layout-sorted-clusters::before {
  content: ""; }

.pt-icon-sort-asc::before {
  content: ""; }

.pt-icon-sort-desc::before {
  content: ""; }

.pt-icon-small-cross::before {
  content: ""; }

.pt-icon-small-tick::before {
  content: ""; }

.pt-icon-power::before {
  content: ""; }

.pt-icon-column-layout::before {
  content: ""; }

.pt-icon-arrow-top-left::before {
  content: "↖"; }

.pt-icon-arrow-top-right::before {
  content: "↗"; }

.pt-icon-arrow-bottom-right::before {
  content: "↘"; }

.pt-icon-arrow-bottom-left::before {
  content: "↙"; }

.pt-icon-mugshot::before {
  content: ""; }

.pt-icon-headset::before {
  content: ""; }

.pt-icon-text-highlight::before {
  content: ""; }

.pt-icon-hand::before {
  content: ""; }

.pt-icon-chevron-backward::before {
  content: ""; }

.pt-icon-chevron-forward::before {
  content: ""; }

.pt-icon-rotate-document::before {
  content: ""; }

.pt-icon-rotate-page::before {
  content: ""; }

.pt-icon-badge::before {
  content: ""; }

.pt-icon-grid-view::before {
  content: ""; }

.pt-icon-function::before {
  content: ""; }

.pt-icon-waterfall-chart::before {
  content: ""; }

.pt-icon-stacked-chart::before {
  content: ""; }

.pt-icon-pulse::before {
  content: ""; }

.pt-icon-new-person::before {
  content: ""; }

.pt-icon-exclude-row::before {
  content: ""; }

.pt-icon-pivot-table::before {
  content: ""; }

.pt-icon-segmented-control::before {
  content: ""; }

.pt-icon-highlight::before {
  content: ""; }

.pt-icon-filter-list::before {
  content: ""; }

.pt-icon-cut::before {
  content: ""; }

.pt-icon-annotation::before {
  content: ""; }

.pt-icon-pivot::before {
  content: ""; }

.pt-icon-ring::before {
  content: ""; }

.pt-icon-heat-grid::before {
  content: ""; }

.pt-icon-gantt-chart::before {
  content: ""; }

.pt-icon-variable::before {
  content: ""; }

.pt-icon-manual::before {
  content: ""; }

.pt-icon-add-row-top::before {
  content: ""; }

.pt-icon-add-row-bottom::before {
  content: ""; }

.pt-icon-add-column-left::before {
  content: ""; }

.pt-icon-add-column-right::before {
  content: ""; }

.pt-icon-remove-row-top::before {
  content: ""; }

.pt-icon-remove-row-bottom::before {
  content: ""; }

.pt-icon-remove-column-left::before {
  content: ""; }

.pt-icon-remove-column-right::before {
  content: ""; }

.pt-icon-double-chevron-left::before {
  content: ""; }

.pt-icon-double-chevron-right::before {
  content: ""; }

.pt-icon-double-chevron-up::before {
  content: ""; }

.pt-icon-double-chevron-down::before {
  content: ""; }

.pt-icon-key-control::before {
  content: ""; }

.pt-icon-key-command::before {
  content: ""; }

.pt-icon-key-shift::before {
  content: ""; }

.pt-icon-key-backspace::before {
  content: ""; }

.pt-icon-key-delete::before {
  content: ""; }

.pt-icon-key-escape::before {
  content: ""; }

.pt-icon-key-enter::before {
  content: ""; }

.pt-icon-calculator::before {
  content: ""; }

.pt-icon-horizontal-bar-chart::before {
  content: ""; }

.pt-icon-small-plus::before {
  content: ""; }

.pt-icon-small-minus::before {
  content: ""; }

.pt-icon-step-chart::before {
  content: ""; }

.pt-icon-euro::before {
  content: "€"; }

.pt-icon-drag-handle-vertical::before {
  content: ""; }

.pt-icon-drag-handle-horizontal::before {
  content: ""; }

.pt-icon-mobile-phone::before {
  content: ""; }

.pt-icon-sim-card::before {
  content: ""; }

.pt-icon-trending-up::before {
  content: ""; }

.pt-icon-trending-down::before {
  content: ""; }

.pt-icon-curved-range-chart::before {
  content: ""; }

.pt-icon-vertical-bar-chart-desc::before {
  content: ""; }

.pt-icon-horizontal-bar-chart-desc::before {
  content: ""; }

.pt-icon-document-open::before {
  content: ""; }

.pt-icon-document-share::before {
  content: ""; }

.pt-icon-horizontal-distribution::before {
  content: ""; }

.pt-icon-vertical-distribution::before {
  content: ""; }

.pt-icon-alignment-left::before {
  content: ""; }

.pt-icon-alignment-vertical-center::before {
  content: ""; }

.pt-icon-alignment-right::before {
  content: ""; }

.pt-icon-alignment-top::before {
  content: ""; }

.pt-icon-alignment-horizontal-center::before {
  content: ""; }

.pt-icon-alignment-bottom::before {
  content: ""; }

.pt-icon-git-pull::before {
  content: ""; }

.pt-icon-git-merge::before {
  content: ""; }

.pt-icon-git-branch::before {
  content: ""; }

.pt-icon-git-commit::before {
  content: ""; }

.pt-icon-git-push::before {
  content: ""; }

.pt-icon-build::before {
  content: ""; }

.pt-icon-symbol-circle::before {
  content: ""; }

.pt-icon-symbol-square::before {
  content: ""; }

.pt-icon-symbol-diamond::before {
  content: ""; }

.pt-icon-symbol-cross::before {
  content: ""; }

.pt-icon-symbol-triangle-up::before {
  content: ""; }

.pt-icon-symbol-triangle-down::before {
  content: ""; }

.pt-icon-wrench::before {
  content: ""; }

.pt-icon-application::before {
  content: ""; }

.pt-icon-send-to-graph::before {
  content: ""; }

.pt-icon-send-to-map::before {
  content: ""; }

.pt-icon-join-table::before {
  content: ""; }

.pt-icon-derive-column::before {
  content: ""; }

.pt-icon-image-rotate-left::before {
  content: ""; }

.pt-icon-image-rotate-right::before {
  content: ""; }

.pt-icon-known-vehicle::before {
  content: ""; }

.pt-icon-unknown-vehicle::before {
  content: ""; }

.pt-icon-scatter-plot::before {
  content: ""; }

.pt-icon-oil-field::before {
  content: ""; }

.pt-icon-rig::before {
  content: ""; }

.pt-icon-map-create::before {
  content: ""; }

.pt-icon-key-option::before {
  content: ""; }

.pt-icon-list-detail-view::before {
  content: ""; }

.pt-icon-swap-vertical::before {
  content: ""; }

.pt-icon-swap-horizontal::before {
  content: ""; }

.pt-icon-numbered-list::before {
  content: ""; }

.pt-icon-new-grid-item::before {
  content: ""; }

.pt-icon-git-repo::before {
  content: ""; }

.pt-icon-git-new-branch::before {
  content: ""; }

.pt-icon-manually-entered-data::before {
  content: ""; }

.pt-icon-airplane::before {
  content: ""; }

.pt-icon-merge-columns::before {
  content: ""; }

.pt-icon-split-columns::before {
  content: ""; }

.pt-icon-dashboard::before {
  content: ""; }

.pt-icon-publish-function::before {
  content: ""; }

.pt-icon-path::before {
  content: ""; }

.pt-icon-moon::before {
  content: ""; }

.pt-icon-remove-column::before {
  content: ""; }

.pt-icon-numerical::before {
  content: ""; }

.pt-icon-key-tab::before {
  content: ""; }

.pt-icon-regression-chart::before {
  content: ""; }

.pt-icon-translate::before {
  content: ""; }

.pt-icon-eye-on::before {
  content: ""; }

.pt-icon-vertical-bar-chart-asc::before {
  content: ""; }

.pt-icon-horizontal-bar-chart-asc::before {
  content: ""; }

.pt-icon-grouped-bar-chart::before {
  content: ""; }

.pt-icon-full-stacked-chart::before {
  content: ""; }

.pt-icon-endorsed::before {
  content: ""; }

.pt-icon-follower::before {
  content: ""; }

.pt-icon-following::before {
  content: ""; }

.pt-icon-menu::before {
  content: ""; }

.pt-icon-collapse-all::before {
  content: ""; }

.pt-icon-expand-all::before {
  content: ""; }

.pt-icon-intersection::before {
  content: ""; }

.pt-icon-blocked-person::before {
  content: ""; }

.pt-icon-slash::before {
  content: ""; }

.pt-icon-percentage::before {
  content: ""; }

.pt-icon-satellite::before {
  content: ""; }

.pt-icon-paragraph::before {
  content: ""; }

.pt-icon-bank-account::before {
  content: ""; }

.pt-icon-cell-tower::before {
  content: ""; }

.pt-icon-id-number::before {
  content: ""; }

.pt-icon-ip-address::before {
  content: ""; }

.pt-icon-eraser::before {
  content: ""; }

.pt-icon-issue::before {
  content: ""; }

.pt-icon-issue-new::before {
  content: ""; }

.pt-icon-issue-closed::before {
  content: ""; }

.pt-icon-panel-stats::before {
  content: ""; }

.pt-icon-panel-table::before {
  content: ""; }

.pt-icon-tick-circle::before {
  content: ""; }

.pt-icon-prescription::before {
  content: ""; }

.pt-icon-new-prescription::before {
  content: ""; }

.pt-icon-filter-keep::before {
  content: ""; }

.pt-icon-filter-remove::before {
  content: ""; }

.pt-icon-key::before {
  content: ""; }

.pt-icon-feed-subscribed::before {
  content: ""; }

.pt-icon-widget-button::before {
  content: ""; }

.pt-icon-widget-header::before {
  content: ""; }

.pt-icon-widget-footer::before {
  content: ""; }

.pt-icon-header-one::before {
  content: ""; }

.pt-icon-header-two::before {
  content: ""; }

.pt-icon-form::before {
  content: ""; }

.pt-icon-series-add::before {
  content: ""; }

.pt-icon-series-search::before {
  content: ""; }

.pt-icon-series-filtered::before {
  content: ""; }

.pt-icon-series-derived::before {
  content: ""; }

.pt-icon-series-configuration::before {
  content: ""; }

.pt-icon-console::before {
  content: ""; }

.pt-icon-compass::before {
  content: ""; }

.pt-icon-walk::before {
  content: ""; }

.pt-icon-taxi::before {
  content: ""; }

.pt-icon-train::before {
  content: ""; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
.pt-submenu > .pt-popover-target {
  display: inherit; }
  .pt-submenu > .pt-popover-target > .pt-menu-item {
    padding-right: 26px; }
    .pt-submenu > .pt-popover-target > .pt-menu-item::after {
      line-height: 1;
      font-family: "Icons16", sans-serif;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      right: 5px;
      content: "〉"; }
      .pt-large .pt-submenu > .pt-popover-target > .pt-menu-item::after {
        line-height: 20px; }

.pt-submenu .pt-popover {
  position: absolute;
  top: -5px;
  left: 5px; }
  .pt-submenu .pt-popover.pt-align-left {
    right: 5px;
    left: auto; }

/*
Menus

Markup:
<ul class="pt-menu {{.modifier}} pt-elevation-1">
  <li>
    <a class="pt-menu-item pt-icon-people" tabindex="0">Share...</a>
  </li>
  <li>
    <a class="pt-menu-item pt-icon-circle-arrow-right" tabindex="0">Move...</a>
  </li>
  <li>
    <a class="pt-menu-item pt-icon-edit" tabindex="0">Rename</a>
  </li>
  <li class="pt-menu-divider"></li>
  <li>
    <a class="pt-menu-item pt-icon-trash pt-intent-danger" tabindex="0">Delete</a>
  </li>
</ul>

.pt-large - Large size (only supported on <code>.pt-menu</code>)

Styleguide pt-menu
*/
.pt-menu {
  margin: 0;
  border-radius: 3px;
  background: #ffffff;
  min-width: 180px;
  padding: 5px;
  list-style: none;
  text-align: left;
  color: #182026; }

.pt-menu-divider {
  display: block;
  margin: 5px;
  border-top: 1px solid rgba(16, 22, 26, 0.15); }
  .pt-dark .pt-menu-divider {
    border-top-color: rgba(255, 255, 255, 0.15); }

.pt-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  display: block;
  border-radius: 2px;
  padding: 7px;
  line-height: 16px;
  color: inherit;
  user-select: none; }
  .pt-menu-item:hover, .pt-submenu > .pt-popover-open > .pt-menu-item {
    background-color: rgba(167, 182, 194, 0.3);
    cursor: pointer; }
  .pt-menu-item.pt-disabled {
    background-color: inherit;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-dark .pt-menu-item {
    color: inherit; }
    .pt-dark .pt-menu-item:hover, .pt-dark .pt-submenu > .pt-popover-open > .pt-menu-item {
      background-color: rgba(138, 155, 168, 0.15);
      color: inherit; }
    .pt-dark .pt-menu-item.pt-disabled {
      background-color: inherit;
      color: rgba(191, 204, 214, 0.5); }
  .pt-menu-item.pt-intent-primary {
    color: #106ba3; }
    .pt-menu-item.pt-intent-primary::before, .pt-menu-item.pt-intent-primary::after,
    .pt-menu-item.pt-intent-primary .pt-menu-item-label {
      color: #106ba3; }
    .pt-menu-item.pt-intent-primary:hover, .pt-submenu > .pt-popover-open > .pt-intent-primary.pt-menu-item, .pt-menu-item.pt-intent-primary.pt-active {
      background-color: #137cbd; }
    .pt-menu-item.pt-intent-primary:active {
      background-color: #106ba3; }
    .pt-menu-item.pt-intent-primary:hover, .pt-submenu > .pt-popover-open > .pt-intent-primary.pt-menu-item, .pt-menu-item.pt-intent-primary:hover::before, .pt-submenu > .pt-popover-open > .pt-intent-primary.pt-menu-item::before, .pt-menu-item.pt-intent-primary:hover::after, .pt-submenu > .pt-popover-open > .pt-intent-primary.pt-menu-item::after,
    .pt-menu-item.pt-intent-primary:hover .pt-menu-item-label, .pt-submenu > .pt-popover-open > .pt-intent-primary.pt-menu-item .pt-menu-item-label, .pt-menu-item.pt-intent-primary:active, .pt-menu-item.pt-intent-primary:active::before, .pt-menu-item.pt-intent-primary:active::after,
    .pt-menu-item.pt-intent-primary:active .pt-menu-item-label, .pt-menu-item.pt-intent-primary.pt-active, .pt-menu-item.pt-intent-primary.pt-active::before, .pt-menu-item.pt-intent-primary.pt-active::after,
    .pt-menu-item.pt-intent-primary.pt-active .pt-menu-item-label {
      color: #ffffff; }
  .pt-menu-item.pt-intent-success {
    color: #0d8050; }
    .pt-menu-item.pt-intent-success::before, .pt-menu-item.pt-intent-success::after,
    .pt-menu-item.pt-intent-success .pt-menu-item-label {
      color: #0d8050; }
    .pt-menu-item.pt-intent-success:hover, .pt-submenu > .pt-popover-open > .pt-intent-success.pt-menu-item, .pt-menu-item.pt-intent-success.pt-active {
      background-color: #0f9960; }
    .pt-menu-item.pt-intent-success:active {
      background-color: #0d8050; }
    .pt-menu-item.pt-intent-success:hover, .pt-submenu > .pt-popover-open > .pt-intent-success.pt-menu-item, .pt-menu-item.pt-intent-success:hover::before, .pt-submenu > .pt-popover-open > .pt-intent-success.pt-menu-item::before, .pt-menu-item.pt-intent-success:hover::after, .pt-submenu > .pt-popover-open > .pt-intent-success.pt-menu-item::after,
    .pt-menu-item.pt-intent-success:hover .pt-menu-item-label, .pt-submenu > .pt-popover-open > .pt-intent-success.pt-menu-item .pt-menu-item-label, .pt-menu-item.pt-intent-success:active, .pt-menu-item.pt-intent-success:active::before, .pt-menu-item.pt-intent-success:active::after,
    .pt-menu-item.pt-intent-success:active .pt-menu-item-label, .pt-menu-item.pt-intent-success.pt-active, .pt-menu-item.pt-intent-success.pt-active::before, .pt-menu-item.pt-intent-success.pt-active::after,
    .pt-menu-item.pt-intent-success.pt-active .pt-menu-item-label {
      color: #ffffff; }
  .pt-menu-item.pt-intent-warning {
    color: #bf7326; }
    .pt-menu-item.pt-intent-warning::before, .pt-menu-item.pt-intent-warning::after,
    .pt-menu-item.pt-intent-warning .pt-menu-item-label {
      color: #bf7326; }
    .pt-menu-item.pt-intent-warning:hover, .pt-submenu > .pt-popover-open > .pt-intent-warning.pt-menu-item, .pt-menu-item.pt-intent-warning.pt-active {
      background-color: #d9822b; }
    .pt-menu-item.pt-intent-warning:active {
      background-color: #bf7326; }
    .pt-menu-item.pt-intent-warning:hover, .pt-submenu > .pt-popover-open > .pt-intent-warning.pt-menu-item, .pt-menu-item.pt-intent-warning:hover::before, .pt-submenu > .pt-popover-open > .pt-intent-warning.pt-menu-item::before, .pt-menu-item.pt-intent-warning:hover::after, .pt-submenu > .pt-popover-open > .pt-intent-warning.pt-menu-item::after,
    .pt-menu-item.pt-intent-warning:hover .pt-menu-item-label, .pt-submenu > .pt-popover-open > .pt-intent-warning.pt-menu-item .pt-menu-item-label, .pt-menu-item.pt-intent-warning:active, .pt-menu-item.pt-intent-warning:active::before, .pt-menu-item.pt-intent-warning:active::after,
    .pt-menu-item.pt-intent-warning:active .pt-menu-item-label, .pt-menu-item.pt-intent-warning.pt-active, .pt-menu-item.pt-intent-warning.pt-active::before, .pt-menu-item.pt-intent-warning.pt-active::after,
    .pt-menu-item.pt-intent-warning.pt-active .pt-menu-item-label {
      color: #ffffff; }
  .pt-menu-item.pt-intent-danger {
    color: #c23030; }
    .pt-menu-item.pt-intent-danger::before, .pt-menu-item.pt-intent-danger::after,
    .pt-menu-item.pt-intent-danger .pt-menu-item-label {
      color: #c23030; }
    .pt-menu-item.pt-intent-danger:hover, .pt-submenu > .pt-popover-open > .pt-intent-danger.pt-menu-item, .pt-menu-item.pt-intent-danger.pt-active {
      background-color: #db3737; }
    .pt-menu-item.pt-intent-danger:active {
      background-color: #c23030; }
    .pt-menu-item.pt-intent-danger:hover, .pt-submenu > .pt-popover-open > .pt-intent-danger.pt-menu-item, .pt-menu-item.pt-intent-danger:hover::before, .pt-submenu > .pt-popover-open > .pt-intent-danger.pt-menu-item::before, .pt-menu-item.pt-intent-danger:hover::after, .pt-submenu > .pt-popover-open > .pt-intent-danger.pt-menu-item::after,
    .pt-menu-item.pt-intent-danger:hover .pt-menu-item-label, .pt-submenu > .pt-popover-open > .pt-intent-danger.pt-menu-item .pt-menu-item-label, .pt-menu-item.pt-intent-danger:active, .pt-menu-item.pt-intent-danger:active::before, .pt-menu-item.pt-intent-danger:active::after,
    .pt-menu-item.pt-intent-danger:active .pt-menu-item-label, .pt-menu-item.pt-intent-danger.pt-active, .pt-menu-item.pt-intent-danger.pt-active::before, .pt-menu-item.pt-intent-danger.pt-active::after,
    .pt-menu-item.pt-intent-danger.pt-active .pt-menu-item-label {
      color: #ffffff; }
  .pt-menu-item::before {
    line-height: 1;
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    float: left;
    margin-right: 7px; }
  .pt-menu-item::before, .pt-menu-item::after {
    color: #5c7080; }
  .pt-menu-item .pt-menu-item-label {
    color: #5c7080; }
  .pt-menu-item:hover, .pt-submenu > .pt-popover-open > .pt-menu-item {
    color: inherit; }
  .pt-menu-item.pt-active, .pt-menu-item:active {
    background-color: rgba(115, 134, 148, 0.3); }
  .pt-menu-item.pt-disabled {
    outline: none !important;
    background-color: inherit !important;
    cursor: not-allowed !important;
    color: rgba(92, 112, 128, 0.5) !important; }
    .pt-menu-item.pt-disabled::before, .pt-menu-item.pt-disabled::after,
    .pt-menu-item.pt-disabled .pt-menu-item-label {
      color: rgba(92, 112, 128, 0.5) !important; }
  .pt-large .pt-menu-item {
    padding: 10px 7px;
    line-height: 20px;
    font-size: 16px; }
    .pt-large .pt-menu-item::before {
      line-height: 1;
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      margin-right: 10px; }

a.pt-menu-item, a.pt-menu-item:hover, .pt-submenu > .pt-popover-open > a.pt-menu-item {
  text-decoration: none; }

button.pt-menu-item {
  border: none;
  background: none;
  width: 100%;
  text-align: left; }

.pt-menu-item-label {
  float: right;
  margin-left: 7px; }

/*
Menu headers

Markup:
<ul class="pt-menu pt-elevation-1">
  <li class="pt-menu-header"><h6>Layouts</h6></li>
  <li><button type="button" class="pt-menu-item pt-icon-layout-auto">Auto</button></li>
  <li><button type="button" class="pt-menu-item pt-icon-layout-circle">Circle</button></li>
  <li><button type="button" class="pt-menu-item pt-icon-layout-grid">Grid</button></li>
  <li class="pt-menu-header"><h6>Views</h6></li>
  <li><button type="button" class="pt-menu-item pt-icon-history">History</button></li>
  <li><button type="button" class="pt-menu-item pt-icon-star">Favorites</button></li>
  <li><button type="button" class="pt-menu-item pt-icon-envelope">Messages</button></li>
</ul>

Styleguide pt-menu.pt-menu-header
*/
.pt-menu-header {
  display: block;
  margin: 5px;
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  cursor: default;
  padding-left: 2px; }
  .pt-dark .pt-menu-header {
    border-top-color: rgba(255, 255, 255, 0.15); }
  .pt-menu-header:first-of-type {
    border-top: none; }
  .pt-menu-header > h6 {
    color: #182026;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    margin: 0;
    padding: 10px 7px 0 1px;
    line-height: 17px; }
  .pt-menu-header:first-of-type > h6 {
    padding-top: 0; }
  .pt-large .pt-menu-header > h6 {
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 18px; }
  .pt-large .pt-menu-header:first-of-type > h6 {
    padding-top: 0; }

.pt-dark .pt-menu {
  background: #30404d;
  color: #f5f8fa; }

.pt-dark .pt-menu-item.pt-intent-primary {
  color: #48aff0; }
  .pt-dark .pt-menu-item.pt-intent-primary::before, .pt-dark .pt-menu-item.pt-intent-primary::after,
  .pt-dark .pt-menu-item.pt-intent-primary .pt-menu-item-label {
    color: #48aff0; }
  .pt-dark .pt-menu-item.pt-intent-primary:hover, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-primary.pt-menu-item, .pt-dark .pt-menu-item.pt-intent-primary.pt-active {
    background-color: #137cbd; }
  .pt-dark .pt-menu-item.pt-intent-primary:active {
    background-color: #106ba3; }
  .pt-dark .pt-menu-item.pt-intent-primary:hover, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-primary.pt-menu-item, .pt-dark .pt-menu-item.pt-intent-primary:hover::before, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-primary.pt-menu-item::before, .pt-dark .pt-menu-item.pt-intent-primary:hover::after, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-primary.pt-menu-item::after,
  .pt-dark .pt-menu-item.pt-intent-primary:hover .pt-menu-item-label,
  .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-primary.pt-menu-item .pt-menu-item-label, .pt-dark .pt-menu-item.pt-intent-primary:active, .pt-dark .pt-menu-item.pt-intent-primary:active::before, .pt-dark .pt-menu-item.pt-intent-primary:active::after,
  .pt-dark .pt-menu-item.pt-intent-primary:active .pt-menu-item-label, .pt-dark .pt-menu-item.pt-intent-primary.pt-active, .pt-dark .pt-menu-item.pt-intent-primary.pt-active::before, .pt-dark .pt-menu-item.pt-intent-primary.pt-active::after,
  .pt-dark .pt-menu-item.pt-intent-primary.pt-active .pt-menu-item-label {
    color: #ffffff; }

.pt-dark .pt-menu-item.pt-intent-success {
  color: #3dcc91; }
  .pt-dark .pt-menu-item.pt-intent-success::before, .pt-dark .pt-menu-item.pt-intent-success::after,
  .pt-dark .pt-menu-item.pt-intent-success .pt-menu-item-label {
    color: #3dcc91; }
  .pt-dark .pt-menu-item.pt-intent-success:hover, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-success.pt-menu-item, .pt-dark .pt-menu-item.pt-intent-success.pt-active {
    background-color: #0f9960; }
  .pt-dark .pt-menu-item.pt-intent-success:active {
    background-color: #0d8050; }
  .pt-dark .pt-menu-item.pt-intent-success:hover, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-success.pt-menu-item, .pt-dark .pt-menu-item.pt-intent-success:hover::before, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-success.pt-menu-item::before, .pt-dark .pt-menu-item.pt-intent-success:hover::after, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-success.pt-menu-item::after,
  .pt-dark .pt-menu-item.pt-intent-success:hover .pt-menu-item-label,
  .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-success.pt-menu-item .pt-menu-item-label, .pt-dark .pt-menu-item.pt-intent-success:active, .pt-dark .pt-menu-item.pt-intent-success:active::before, .pt-dark .pt-menu-item.pt-intent-success:active::after,
  .pt-dark .pt-menu-item.pt-intent-success:active .pt-menu-item-label, .pt-dark .pt-menu-item.pt-intent-success.pt-active, .pt-dark .pt-menu-item.pt-intent-success.pt-active::before, .pt-dark .pt-menu-item.pt-intent-success.pt-active::after,
  .pt-dark .pt-menu-item.pt-intent-success.pt-active .pt-menu-item-label {
    color: #ffffff; }

.pt-dark .pt-menu-item.pt-intent-warning {
  color: #ffb366; }
  .pt-dark .pt-menu-item.pt-intent-warning::before, .pt-dark .pt-menu-item.pt-intent-warning::after,
  .pt-dark .pt-menu-item.pt-intent-warning .pt-menu-item-label {
    color: #ffb366; }
  .pt-dark .pt-menu-item.pt-intent-warning:hover, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-warning.pt-menu-item, .pt-dark .pt-menu-item.pt-intent-warning.pt-active {
    background-color: #d9822b; }
  .pt-dark .pt-menu-item.pt-intent-warning:active {
    background-color: #bf7326; }
  .pt-dark .pt-menu-item.pt-intent-warning:hover, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-warning.pt-menu-item, .pt-dark .pt-menu-item.pt-intent-warning:hover::before, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-warning.pt-menu-item::before, .pt-dark .pt-menu-item.pt-intent-warning:hover::after, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-warning.pt-menu-item::after,
  .pt-dark .pt-menu-item.pt-intent-warning:hover .pt-menu-item-label,
  .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-warning.pt-menu-item .pt-menu-item-label, .pt-dark .pt-menu-item.pt-intent-warning:active, .pt-dark .pt-menu-item.pt-intent-warning:active::before, .pt-dark .pt-menu-item.pt-intent-warning:active::after,
  .pt-dark .pt-menu-item.pt-intent-warning:active .pt-menu-item-label, .pt-dark .pt-menu-item.pt-intent-warning.pt-active, .pt-dark .pt-menu-item.pt-intent-warning.pt-active::before, .pt-dark .pt-menu-item.pt-intent-warning.pt-active::after,
  .pt-dark .pt-menu-item.pt-intent-warning.pt-active .pt-menu-item-label {
    color: #ffffff; }

.pt-dark .pt-menu-item.pt-intent-danger {
  color: #ff7373; }
  .pt-dark .pt-menu-item.pt-intent-danger::before, .pt-dark .pt-menu-item.pt-intent-danger::after,
  .pt-dark .pt-menu-item.pt-intent-danger .pt-menu-item-label {
    color: #ff7373; }
  .pt-dark .pt-menu-item.pt-intent-danger:hover, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-danger.pt-menu-item, .pt-dark .pt-menu-item.pt-intent-danger.pt-active {
    background-color: #db3737; }
  .pt-dark .pt-menu-item.pt-intent-danger:active {
    background-color: #c23030; }
  .pt-dark .pt-menu-item.pt-intent-danger:hover, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-danger.pt-menu-item, .pt-dark .pt-menu-item.pt-intent-danger:hover::before, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-danger.pt-menu-item::before, .pt-dark .pt-menu-item.pt-intent-danger:hover::after, .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-danger.pt-menu-item::after,
  .pt-dark .pt-menu-item.pt-intent-danger:hover .pt-menu-item-label,
  .pt-dark .pt-submenu > .pt-popover-open > .pt-intent-danger.pt-menu-item .pt-menu-item-label, .pt-dark .pt-menu-item.pt-intent-danger:active, .pt-dark .pt-menu-item.pt-intent-danger:active::before, .pt-dark .pt-menu-item.pt-intent-danger:active::after,
  .pt-dark .pt-menu-item.pt-intent-danger:active .pt-menu-item-label, .pt-dark .pt-menu-item.pt-intent-danger.pt-active, .pt-dark .pt-menu-item.pt-intent-danger.pt-active::before, .pt-dark .pt-menu-item.pt-intent-danger.pt-active::after,
  .pt-dark .pt-menu-item.pt-intent-danger.pt-active .pt-menu-item-label {
    color: #ffffff; }

.pt-dark .pt-menu-item::before, .pt-dark .pt-menu-item::after {
  color: #bfccd6; }

.pt-dark .pt-menu-item .pt-menu-item-label {
  color: #bfccd6; }

.pt-dark .pt-menu-item:hover::before, .pt-dark .pt-submenu > .pt-popover-open > .pt-menu-item::before, .pt-dark .pt-menu-item:hover::after, .pt-dark .pt-submenu > .pt-popover-open > .pt-menu-item::after {
  color: #ffffff; }

.pt-dark .pt-menu-item.pt-active, .pt-dark .pt-menu-item:active {
  background-color: rgba(138, 155, 168, 0.3); }

.pt-dark .pt-menu-item.pt-disabled {
  color: rgba(191, 204, 214, 0.5) !important; }
  .pt-dark .pt-menu-item.pt-disabled::before, .pt-dark .pt-menu-item.pt-disabled::after,
  .pt-dark .pt-menu-item.pt-disabled .pt-menu-item-label {
    color: rgba(191, 204, 214, 0.5) !important; }

.pt-dark .pt-menu-divider,
.pt-dark .pt-menu-header {
  border-color: rgba(255, 255, 255, 0.15); }

.pt-dark .pt-menu-header > h6 {
  color: #f5f8fa; }

.pt-label .pt-menu {
  margin-top: 5px; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Navbars

Markup:
<nav class="pt-navbar {{.modifier}}">
  <div class="pt-navbar-group pt-align-left">
    <div class="pt-navbar-heading">Blueprint</div>
    <input class="pt-input" placeholder="Search files..." type="text" />
  </div>
  <div class="pt-navbar-group pt-align-right">
    <button class="pt-button pt-minimal pt-icon-home">Home</button>
    <button class="pt-button pt-minimal pt-icon-document">Files</button>
    <span class="pt-navbar-divider"></span>
    <button class="pt-button pt-minimal pt-icon-user"></button>
    <button class="pt-button pt-minimal pt-icon-notifications"></button>
    <button class="pt-button pt-minimal pt-icon-cog"></button>
  </div>
</nav>

.pt-dark - Dark theme

Styleguide pt-navbar
*/
.pt-navbar {
  position: relative;
  z-index: 10;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  background-color: #ffffff;
  height: 50px;
  padding: 0 15px; }
  .pt-navbar.pt-dark,
  .pt-dark .pt-navbar {
    background-color: #394b59; }
  .pt-navbar.pt-dark {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-dark .pt-navbar {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }
  .pt-navbar.pt-fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0; }
  .pt-navbar .pt-logo {
    margin-right: 15px;
    width: 20px; }

.pt-navbar-heading {
  margin-right: 15px;
  font-size: 16px; }

.pt-navbar-group {
  display: flex;
  align-items: center;
  height: 50px; }
  .pt-navbar-group.pt-align-left {
    float: left; }
  .pt-navbar-group.pt-align-right {
    float: right; }

.pt-navbar-divider {
  margin: 0 10px;
  border-left: 1px solid rgba(16, 22, 26, 0.15);
  height: 20px; }
  .pt-dark .pt-navbar-divider {
    border-left-color: rgba(255, 255, 255, 0.15); }

/*
Fixed width

Markup:
<nav class="pt-navbar pt-dark">
  <div style="margin: 0 auto; width: 480px;"> <!-- ADD ME -->
    <div class="pt-navbar-group pt-align-left">
      <div class="pt-navbar-heading">Blueprint</div>
    </div>
    <div class="pt-navbar-group pt-align-right">
      <button class="pt-button pt-minimal pt-icon-home">Home</button>
      <button class="pt-button pt-minimal pt-icon-document">Files</button>
      <span class="pt-navbar-divider"></span>
      <button class="pt-button pt-minimal pt-icon-user"></button>
      <button class="pt-button pt-minimal pt-icon-notifications"></button>
      <button class="pt-button pt-minimal pt-icon-cog"></button>
    </div>
  </div>
</nav>

Styleguide pt-navbar.pt-container
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Non-ideal state

Markup:
<div class="pt-non-ideal-state">
  <div class="pt-non-ideal-state-visual pt-non-ideal-state-icon">
    <span class="pt-icon pt-icon-folder-open"></span>
  </div>
  <h4 class="pt-non-ideal-state-title">This folder is empty</h4>
  <div class="pt-non-ideal-state-description">
    Create a new file to populate the folder.
  </div>
</div>

Styleguide pt-non-ideal-state
*/
.pt-non-ideal-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  height: 100%; }

.pt-non-ideal-state > :not(:last-child) {
  margin-bottom: 20px; }

.pt-non-ideal-state-icon .pt-icon {
  color: rgba(92, 112, 128, 0.5);
  font-size: 60px; }
  .pt-dark .pt-non-ideal-state-icon .pt-icon {
    color: rgba(191, 204, 214, 0.5); }

.pt-non-ideal-state-title,
.pt-non-ideal-state-description {
  max-width: 100%;
  text-align: center; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
body.pt-overlay-open {
  overflow: hidden; }

.pt-overlay {
  position: static;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20; }
  .pt-overlay:not(.pt-overlay-open) {
    pointer-events: none; }
  .pt-overlay.pt-overlay-scroll-container {
    position: fixed;
    overflow: auto; }
    .pt-overlay.pt-overlay-scroll-container.pt-overlay-inline {
      position: absolute; }
  .pt-overlay.pt-overlay-inline {
    overflow: visible; }

.pt-overlay-content {
  position: fixed;
  z-index: 20; }
  .pt-overlay-inline .pt-overlay-content,
  .pt-overlay-scroll-container .pt-overlay-content {
    position: absolute; }

.pt-overlay-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 20;
  background-color: rgba(16, 22, 26, 0.7);
  overflow: auto;
  user-select: none; }
  .pt-overlay-backdrop.pt-overlay-enter, .pt-overlay-backdrop.pt-overlay-appear {
    opacity: 0; }
  .pt-overlay-backdrop.pt-overlay-enter-active, .pt-overlay-backdrop.pt-overlay-appear-active {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-overlay-backdrop.pt-overlay-leave {
    opacity: 1; }
  .pt-overlay-backdrop.pt-overlay-leave-active {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-overlay-backdrop:focus {
    outline: none; }
  .pt-overlay-inline .pt-overlay-backdrop {
    position: absolute; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.pt-popover {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  transform: scale(1);
  display: inline-block;
  z-index: 20;
  border-radius: 3px; }
  .pt-popover .pt-popover-arrow {
    position: absolute;
    width: 30px;
    height: 30px; }
    .pt-popover .pt-popover-arrow::before {
      margin: 5px;
      width: 20px;
      height: 20px; }
  .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-popover {
    margin-top: -17px;
    margin-bottom: 17px; }
    .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-popover > .pt-popover-arrow {
      bottom: -11px; }
      .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-popover > .pt-popover-arrow svg {
        transform: rotate(-90deg); }
  .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-popover {
    margin-left: 17px; }
    .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-popover > .pt-popover-arrow {
      left: -11px; }
      .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-popover > .pt-popover-arrow svg {
        transform: rotate(0); }
  .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-popover {
    margin-top: 17px; }
    .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-popover > .pt-popover-arrow {
      top: -11px; }
      .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-popover > .pt-popover-arrow svg {
        transform: rotate(90deg); }
  .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-popover {
    margin-right: 17px;
    margin-left: -17px; }
    .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-popover > .pt-popover-arrow {
      right: -11px; }
      .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-popover > .pt-popover-arrow svg {
        transform: rotate(180deg); }
  .pt-tether-element-attached-middle > .pt-popover > .pt-popover-arrow {
    top: 50%;
    transform: translateY(-50%); }
  .pt-tether-element-attached-center > .pt-popover > .pt-popover-arrow {
    right: 50%;
    transform: translateX(50%); }
  .pt-tether-element-attached-top.pt-tether-target-attached-top > .pt-popover > .pt-popover-arrow {
    top: -0.3934px; }
  .pt-tether-element-attached-right.pt-tether-target-attached-right > .pt-popover > .pt-popover-arrow {
    right: -0.3934px; }
  .pt-tether-element-attached-left.pt-tether-target-attached-left > .pt-popover > .pt-popover-arrow {
    left: -0.3934px; }
  .pt-tether-element-attached-bottom.pt-tether-target-attached-bottom > .pt-popover > .pt-popover-arrow {
    bottom: -0.3934px; }
  .pt-tether-element-attached-top.pt-tether-element-attached-left > .pt-popover {
    transform-origin: top left; }
  .pt-tether-element-attached-top.pt-tether-element-attached-center > .pt-popover {
    transform-origin: top center; }
  .pt-tether-element-attached-top.pt-tether-element-attached-right > .pt-popover {
    transform-origin: top right; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-left > .pt-popover {
    transform-origin: center left; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-center > .pt-popover {
    transform-origin: center center; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-right > .pt-popover {
    transform-origin: center right; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-left > .pt-popover {
    transform-origin: bottom left; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-center > .pt-popover {
    transform-origin: bottom center; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-right > .pt-popover {
    transform-origin: bottom right; }
  .pt-popover .pt-popover-content {
    background: #ffffff;
    color: inherit; }
  .pt-popover .pt-popover-arrow::before {
    box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2); }
  .pt-popover .pt-popover-arrow-border {
    fill: #10161a;
    fill-opacity: 0.1; }
  .pt-popover .pt-popover-arrow-fill {
    fill: #ffffff; }
  .pt-popover-enter > .pt-popover, .pt-popover-appear > .pt-popover {
    transform: scale(0.3); }
  .pt-popover-enter-active > .pt-popover, .pt-popover-appear-active > .pt-popover {
    transform: scale(1);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-popover-leave > .pt-popover {
    transform: scale(1); }
  .pt-popover-leave-active > .pt-popover {
    transform: scale(0.3);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-popover .pt-popover-content {
    position: relative;
    border-radius: 3px; }
  .pt-popover.pt-popover-content-sizing .pt-popover-content {
    max-width: 350px;
    padding: 20px; }
  .pt-popover-target .pt-popover.pt-popover-content-sizing {
    width: 350px; }
  .pt-popover.pt-minimal {
    margin: 0 !important; }
    .pt-popover.pt-minimal .pt-popover-arrow {
      display: none; }
    .pt-popover.pt-minimal.pt-popover {
      transform: scale(1); }
      .pt-popover-enter > .pt-popover.pt-minimal.pt-popover, .pt-popover-appear > .pt-popover.pt-minimal.pt-popover {
        transform: scale(1); }
      .pt-popover-enter-active > .pt-popover.pt-minimal.pt-popover, .pt-popover-appear-active > .pt-popover.pt-minimal.pt-popover {
        transform: scale(1);
        transition-property: transform;
        transition-duration: 100ms;
        transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
        transition-delay: 0; }
      .pt-popover-leave > .pt-popover.pt-minimal.pt-popover {
        transform: scale(1); }
      .pt-popover-leave-active > .pt-popover.pt-minimal.pt-popover {
        transform: scale(1);
        transition-property: transform;
        transition-duration: 100ms;
        transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
        transition-delay: 0; }
  .pt-popover.pt-dark,
  .pt-dark .pt-popover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .pt-popover.pt-dark .pt-popover-content,
    .pt-dark .pt-popover .pt-popover-content {
      background: #30404d;
      color: inherit; }
    .pt-popover.pt-dark .pt-popover-arrow::before,
    .pt-dark .pt-popover .pt-popover-arrow::before {
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4); }
    .pt-popover.pt-dark .pt-popover-arrow-border,
    .pt-dark .pt-popover .pt-popover-arrow-border {
      fill: #10161a;
      fill-opacity: 0.2; }
    .pt-popover.pt-dark .pt-popover-arrow-fill,
    .pt-dark .pt-popover .pt-popover-arrow-fill {
      fill: #30404d; }

.pt-popover-arrow::before {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 2px;
  content: ""; }

.pt-tether-pinned .pt-popover-arrow {
  display: none; }

.pt-popover-backdrop {
  background: rgba(255, 255, 255, 0); }

.pt-transition-container {
  opacity: 1;
  display: flex;
  z-index: 20; }
  .pt-transition-container.pt-popover-enter, .pt-transition-container.pt-popover-appear {
    opacity: 0; }
  .pt-transition-container.pt-popover-enter-active, .pt-transition-container.pt-popover-appear-active {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-transition-container.pt-popover-leave {
    opacity: 1; }
  .pt-transition-container.pt-popover-leave-active {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-transition-container:focus {
    outline: none; }
  .pt-transition-container.pt-popover-leave .pt-popover-content {
    pointer-events: none; }

.pt-popover-target {
  display: inline-block;
  position: relative;
  vertical-align: top; }
  .pt-popover-target > .pt-popover-target {
    display: inline-block; }
  .pt-popover-target .pt-transition-container {
    position: absolute; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-bottom.pt-tether-target-attached-top {
      bottom: 100%; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-left.pt-tether-target-attached-right {
      left: 100%; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-top.pt-tether-target-attached-bottom {
      top: 100%; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-right.pt-tether-target-attached-left {
      right: 100%; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-top.pt-tether-target-attached-top {
      top: 0; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-right.pt-tether-target-attached-right {
      right: 0; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-bottom.pt-tether-target-attached-bottom {
      bottom: 0; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-left.pt-tether-target-attached-left {
      left: 0; }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-middle {
      top: 50%;
      transform: translateY(-50%); }
    .pt-popover-target .pt-transition-container.pt-tether-element-attached-center {
      left: 50%;
      transform: translateX(-50%); }
  .pt-popover-target .pt-overlay-inline {
    position: static; }

.pt-button-group.pt-vertical .pt-popover-target {
  display: block; }

.pt-button-group:not(.pt-vertical) .pt-popover-target,
.pt-button-group:not(.pt-vertical) .pt-tether-target {
  float: left; }

.pt-portal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Progress bars

Markup:
<div class="pt-progress-bar {{.modifier}}">
  <div class="pt-progress-meter" style="width: 25%"></div>
</div>
<div class="pt-progress-bar pt-intent-primary {{.modifier}}">
  <div class="pt-progress-meter" style="width: 50%"></div>
</div>
<div class="pt-progress-bar pt-intent-success {{.modifier}}">
  <div class="pt-progress-meter" style="width: 75%"></div>
</div>
<div class="pt-progress-bar pt-intent-danger {{.modifier}}">
  <div class="pt-progress-meter" style="width: 100%"></div>
</div>

.pt-no-stripes   - No stripes
.pt-no-animation - No animation

Styleguide pt-progress-bar
*/
@keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.pt-progress-bar {
  display: block;
  position: relative;
  border-radius: 40px;
  background: rgba(92, 112, 128, 0.2);
  width: 100%;
  height: 8px;
  overflow: hidden; }
  .pt-progress-bar .pt-progress-meter {
    display: inline-block;
    position: absolute;
    border-radius: 40px;
    background: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%);
    background-color: rgba(92, 112, 128, 0.8);
    background-size: 30px 30px;
    width: 100%;
    height: 100%;
    transition: width 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-progress-bar:not(.pt-no-animation):not(.pt-no-stripes) .pt-progress-meter {
    animation: linear-progress-bar-stripes 300ms linear infinite reverse; }
  .pt-progress-bar.pt-no-stripes .pt-progress-meter {
    background-image: none; }

.pt-dark .pt-progress-bar,
.pt-progress-bar.pt-dark {
  background: rgba(16, 22, 26, 0.5); }
  .pt-dark .pt-progress-bar .pt-progress-meter,
  .pt-progress-bar.pt-dark .pt-progress-meter {
    background-color: #8a9ba8; }

.pt-progress-bar.pt-intent-primary .pt-progress-meter {
  background-color: #137cbd; }

.pt-progress-bar.pt-intent-success .pt-progress-meter {
  background-color: #0f9960; }

.pt-progress-bar.pt-intent-warning .pt-progress-meter {
  background-color: #d9822b; }

.pt-progress-bar.pt-intent-danger .pt-progress-meter {
  background-color: #db3737; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Skeletons

Markup:
<div class="pt-card">
  <h5><a class="{{.modifier}}" href="#" tabindex="-1">Card heading</a></h5>
  <p class="{{.modifier}}">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget tortor felis.
    Fusce dapibus metus in dapibus mollis. Quisque eget ex diam.
  </p>
  <button type="button" class="pt-button {{.modifier}}" tabindex="-1">Submit</button>
</div>

.pt-skeleton - Render this element as a skeleton, an outline of its true self.

Styleguide pt-skeleton
*/
@keyframes glow {
  0%,
  100% {
    border-color: rgba(167, 182, 194, 0.2);
    background-color: rgba(167, 182, 194, 0.2); }
  50% {
    border-color: rgba(92, 112, 128, 0.2);
    background-color: rgba(92, 112, 128, 0.2); } }

/* stylelint-disable declaration-no-important */
.pt-skeleton {
  border-color: rgba(167, 182, 194, 0.2) !important;
  border-radius: 2px;
  box-shadow: none !important;
  background: rgba(167, 182, 194, 0.2) !important;
  background-clip: padding-box !important;
  cursor: default;
  color: transparent !important;
  animation: 2000ms linear infinite glow;
  pointer-events: none;
  user-select: none; }

/* stylelint-enable declaration-no-important */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.pt-slider {
  position: relative;
  cursor: default;
  width: 100%;
  min-width: 150px;
  height: 40px;
  outline: none;
  user-select: none; }
  .pt-slider:hover {
    cursor: pointer; }
  .pt-slider:active {
    cursor: grabbing; }
  .pt-slider.pt-disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .pt-slider.pt-slider-unlabeled {
    height: 16px; }

.pt-slider-track,
.pt-slider-progress {
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  border-radius: 3px;
  background: rgba(92, 112, 128, 0.2);
  height: 6px; }
  .pt-dark .pt-slider-track, .pt-dark
  .pt-slider-progress {
    background: rgba(16, 22, 26, 0.5); }

.pt-slider-progress,
.pt-dark .pt-slider-progress {
  background: #137cbd; }

.pt-slider-handle {
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  color: #182026;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
  cursor: pointer;
  width: 16px;
  height: 16px; }
  .pt-slider-handle:hover {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5; }
  .pt-slider-handle:active, .pt-slider-handle.pt-active {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #d8e1e8;
    background-image: none; }
  .pt-slider-handle:disabled, .pt-slider-handle.pt-disabled {
    outline: none;
    box-shadow: none;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
    .pt-slider-handle:disabled.pt-active, .pt-slider-handle.pt-disabled.pt-active {
      background: rgba(206, 217, 224, 0.7); }
  .pt-slider-handle:focus {
    z-index: 1; }
  .pt-slider-handle:hover {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-clip: padding-box;
    background-color: #ebf1f5;
    z-index: 2;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
    cursor: grab; }
  .pt-slider-handle.pt-active {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    background-color: #d8e1e8;
    background-image: none;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 1px rgba(16, 22, 26, 0.1);
    cursor: grabbing; }
  .pt-disabled .pt-slider-handle {
    box-shadow: none;
    background: #bfccd6;
    pointer-events: none; }
  .pt-dark .pt-slider-handle {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    background-color: #394b59;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    color: #f5f8fa; }
    .pt-dark .pt-slider-handle:hover, .pt-dark .pt-slider-handle:active, .pt-dark .pt-slider-handle.pt-active {
      color: #f5f8fa; }
    .pt-dark .pt-slider-handle:hover {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
      background-color: #30404d; }
    .pt-dark .pt-slider-handle:active, .pt-dark .pt-slider-handle.pt-active {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #202b33;
      background-image: none; }
    .pt-dark .pt-slider-handle:disabled, .pt-dark .pt-slider-handle.pt-disabled {
      box-shadow: none;
      background-color: rgba(57, 75, 89, 0.5);
      background-image: none;
      color: rgba(191, 204, 214, 0.5); }
      .pt-dark .pt-slider-handle:disabled.pt-active, .pt-dark .pt-slider-handle.pt-disabled.pt-active {
        background: rgba(57, 75, 89, 0.7); }
    .pt-dark .pt-slider-handle .pt-button-spinner .pt-spinner-head {
      background: rgba(16, 22, 26, 0.5);
      stroke: #8a9ba8; }
    .pt-dark .pt-slider-handle, .pt-dark .pt-slider-handle:hover {
      background-color: #394b59; }
    .pt-dark .pt-slider-handle.pt-active {
      background-color: #293742; }
  .pt-dark .pt-disabled .pt-slider-handle {
    border-color: #5c7080;
    box-shadow: none;
    background: #5c7080; }
  .pt-slider-handle .pt-slider-label {
    transform: translate(-50%, 20px);
    margin-left: 8px;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
    background: #394b59;
    color: #f5f8fa; }
    .pt-dark .pt-slider-handle .pt-slider-label {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
      background: #e1e8ed;
      color: #394b59; }
    .pt-disabled .pt-slider-handle .pt-slider-label {
      box-shadow: none; }

.pt-slider-label {
  display: inline-block;
  padding: 2px 5px;
  vertical-align: top;
  line-height: 1;
  font-size: 12px;
  position: absolute;
  transform: translate(-50%, 20px); }

.pt-range-slider .pt-slider-handle {
  width: 8px; }
  .pt-range-slider .pt-slider-handle:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .pt-range-slider .pt-slider-handle:last-of-type {
    margin-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    .pt-range-slider .pt-slider-handle:last-of-type .pt-slider-label {
      margin-left: 0; }

.pt-range-slider .pt-slider-progress {
  border-radius: 0; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Spinners

Markup:
<div class="pt-spinner {{.modifier}}">
  <div class="pt-spinner-svg-container">
    <svg viewBox="0 0 100 100">
      <path class="pt-spinner-track" d="M 50,50 m 0,-44.5 a 44.5,44.5 0 1 1 0,89 a 44.5,44.5 0 1 1 0,-89"></path>
      <path class="pt-spinner-head" d="M 94.5 50 A 44.5 44.5 0 0 0 50 5.5"></path>
    </svg>
  </div>
</div>

.pt-small - Small spinner
.pt-large - Large spinner

Styleguide pt-spinner
*/
@keyframes pt-spinner-animation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.pt-spinner:not(.pt-svg-spinner) {
  width: 50px; }
  .pt-spinner:not(.pt-svg-spinner) .pt-spinner-svg-container {
    animation-duration: 400ms; }

.pt-spinner.pt-svg-spinner {
  animation-duration: 400ms; }

.pt-spinner .pt-svg-spinner-transform-group {
  transform: scale(0.5); }

.pt-spinner path {
  stroke-width: 5; }

.pt-spinner path {
  fill-opacity: 0; }

.pt-spinner .pt-spinner-head {
  transition: stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  stroke: rgba(92, 112, 128, 0.8);
  stroke-linecap: round; }

.pt-spinner .pt-spinner-track {
  stroke: rgba(92, 112, 128, 0.2); }

.pt-spinner.pt-small:not(.pt-svg-spinner) {
  width: 24px; }
  .pt-spinner.pt-small:not(.pt-svg-spinner) .pt-spinner-svg-container {
    animation-duration: 400ms; }

.pt-spinner.pt-small.pt-svg-spinner {
  animation-duration: 400ms; }

.pt-spinner.pt-small .pt-svg-spinner-transform-group {
  transform: scale(0.24); }

.pt-spinner.pt-small path {
  stroke-width: 12; }

.pt-spinner.pt-large:not(.pt-svg-spinner) {
  width: 100px; }
  .pt-spinner.pt-large:not(.pt-svg-spinner) .pt-spinner-svg-container {
    animation-duration: 450ms; }

.pt-spinner.pt-large.pt-svg-spinner {
  animation-duration: 450ms; }

.pt-spinner.pt-large .pt-svg-spinner-transform-group {
  transform: scale(1); }

.pt-spinner.pt-large path {
  stroke-width: 3; }

.pt-spinner:not(.pt-svg-spinner) {
  display: inline-block; }
  .pt-spinner:not(.pt-svg-spinner) .pt-spinner-svg-container {
    position: relative;
    width: 100%;
    height: 0;
    padding: 0;
    padding-bottom: 100%;
    animation: pt-spinner-animation 400ms linear infinite; }
  .pt-spinner:not(.pt-svg-spinner).pt-no-spin .pt-spinner-svg-container {
    animation: none; }
  .pt-spinner:not(.pt-svg-spinner) svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.pt-svg-spinner {
  transform-origin: center;
  animation: pt-spinner-animation 400ms linear infinite; }
  .pt-svg-spinner.pt-no-spin {
    animation: none; }

.pt-svg-spinner-transform-group {
  transform-origin: center; }

.pt-dark .pt-spinner .pt-spinner-head {
  stroke: #8a9ba8; }

.pt-dark .pt-spinner .pt-spinner-track {
  stroke: rgba(16, 22, 26, 0.5); }

.pt-spinner.pt-intent-primary .pt-spinner-head {
  stroke: #137cbd; }

.pt-spinner.pt-intent-success .pt-spinner-head {
  stroke: #0f9960; }

.pt-spinner.pt-intent-warning .pt-spinner-head {
  stroke: #d9822b; }

.pt-spinner.pt-intent-danger .pt-spinner-head {
  stroke: #db3737; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Tables

Markup:
<table class="pt-table {{.modifier}}">
  <thead>
    <tr>
      <th>Project</th>
      <th>Description</th>
      <th>Technologies</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Blueprint</td>
      <td>CSS framework and UI toolkit</td>
      <td>Sass, TypeScript, React</td>
    </tr>
    <tr>
      <td>TSLint</td>
      <td>Static analysis linter for TypeScript</td>
      <td>TypeScript</td>
    </tr>
    <tr>
      <td>Plottable</td>
      <td>Composable charting library built on top of D3</td>
      <td>SVG, TypeScript, D3</td>
    </tr>
  </tbody>
</table>

.pt-condensed - Condensed appearance
.pt-striped   - Striped appearance
.pt-bordered  - Bordered appearance
.pt-interactive  - Enables hover styles on rows

Styleguide pt-table
*/
table.pt-table {
  border-spacing: 0;
  font-size: 14px; }
  table.pt-table th,
  table.pt-table td {
    padding: 11px;
    vertical-align: top;
    text-align: left; }
  table.pt-table th {
    color: #182026;
    font-weight: 600; }
  table.pt-table td {
    color: #182026; }
  table.pt-table tbody tr:first-child td {
    box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15); }
  table.pt-table.pt-condensed th,
  table.pt-table.pt-condensed td {
    padding-top: 6px;
    padding-bottom: 6px; }
  table.pt-table.pt-striped tbody tr:nth-child(odd) td {
    background: rgba(191, 204, 214, 0.2); }
  table.pt-table.pt-bordered th:not(:first-child) {
    box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15); }
  table.pt-table.pt-bordered tbody tr td {
    box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15); }
    table.pt-table.pt-bordered tbody tr td:not(:first-child) {
      box-shadow: inset 1px 1px 0 0 rgba(16, 22, 26, 0.15); }
  table.pt-table.pt-bordered.pt-striped tbody tr:not(:first-child) td {
    box-shadow: none; }
    table.pt-table.pt-bordered.pt-striped tbody tr:not(:first-child) td:not(:first-child) {
      box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15); }
  table.pt-table.pt-interactive tbody tr:hover td {
    background-color: rgba(191, 204, 214, 0.4);
    cursor: pointer; }
  .pt-dark table.pt-table th {
    color: #f5f8fa; }
  .pt-dark table.pt-table td {
    color: #f5f8fa; }
  .pt-dark table.pt-table tbody tr:first-child td {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }
  .pt-dark table.pt-table.pt-striped tbody tr:nth-child(odd) td {
    background: rgba(92, 112, 128, 0.15); }
  .pt-dark table.pt-table.pt-bordered th:not(:first-child) {
    box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }
  .pt-dark table.pt-table.pt-bordered tbody tr td {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }
    .pt-dark table.pt-table.pt-bordered tbody tr td:not(:first-child) {
      box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.15); }
  .pt-dark table.pt-table.pt-bordered.pt-striped tbody tr:not(:first-child) td {
    box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }
    .pt-dark table.pt-table.pt-bordered.pt-striped tbody tr:not(:first-child) td:first-child {
      box-shadow: none; }
  .pt-dark table.pt-table.pt-interactive tbody tr:hover td {
    background-color: rgba(92, 112, 128, 0.3);
    cursor: pointer; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Tabs

Markup:
<div class="pt-tabs">
    <ul class="pt-tab-list {{.modifier}}" role="tablist">
        <li class="pt-tab" role="tab" aria-selected="true">Selected tab</li>
        <li class="pt-tab" role="tab">Another tab</li>
        <li class="pt-tab" role="tab" aria-disabled="true">Disabled tab</li>
    </ul>
    <div class="pt-tab-panel" role="tabpanel">Selected panel</div>
    <div class="pt-tab-panel" role="tabpanel" aria-hidden="true">Another panel</div>
    <div class="pt-tab-panel" role="tabpanel" aria-hidden="true">Disabled panel</div>
</div>

.pt-large - Large tabs

Styleguide pt-tabs
*/
.pt-tabs.pt-vertical {
  display: flex; }
  .pt-tabs.pt-vertical > .pt-tab-list {
    flex-direction: column;
    align-items: flex-start; }
    .pt-tabs.pt-vertical > .pt-tab-list .pt-tab {
      width: 100%;
      padding: 0 10px; }
    .pt-tabs.pt-vertical > .pt-tab-list .pt-tab-indicator-wrapper .pt-tab-indicator {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 3px;
      background-color: rgba(19, 124, 189, 0.2);
      height: auto; }
  .pt-tabs.pt-vertical > .pt-tab-panel {
    margin-top: 0;
    padding-left: 20px; }

.pt-tab-list {
  display: flex;
  flex: 0 0 auto;
  align-items: flex-end;
  position: relative;
  margin: 0;
  border: none;
  padding: 0;
  list-style: none; }
  .pt-tab-list > *:not(:last-child) {
    margin-right: 20px; }

.pt-tab {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
  max-width: 100%;
  vertical-align: top;
  line-height: 30px;
  color: #182026;
  font-size: 14px; }
  .pt-tab-indicator-wrapper ~ .pt-tab {
    box-shadow: none !important; }
  .pt-tab[aria-disabled="true"] {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-tab[aria-selected="true"] {
    border-radius: 0;
    box-shadow: inset 0 -3px 0 #106ba3; }
  .pt-tab[aria-selected="true"], .pt-tab:not([aria-disabled="true"]):hover {
    color: #106ba3; }
  .pt-tab:focus {
    -moz-outline-radius: 0; }
  .pt-large > .pt-tab {
    line-height: 40px;
    font-size: 16px; }

.pt-tab-panel {
  margin-top: 20px; }
  .pt-tab-panel[aria-hidden="true"] {
    display: none; }

.pt-tab-indicator-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0), translateY(0);
  transition: height, transform, width;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
  pointer-events: none; }
  .pt-tab-indicator-wrapper .pt-tab-indicator {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #106ba3;
    height: 3px; }
  .pt-tab-indicator-wrapper.pt-no-animation {
    transition: none; }

.pt-dark .pt-tab {
  color: #f5f8fa; }
  .pt-dark .pt-tab[aria-disabled="true"] {
    color: rgba(191, 204, 214, 0.5); }
  .pt-dark .pt-tab[aria-selected="true"] {
    box-shadow: inset 0 -3px 0 #48aff0; }
  .pt-dark .pt-tab[aria-selected="true"], .pt-dark .pt-tab:not([aria-disabled="true"]):hover {
    color: #48aff0; }

.pt-dark .pt-tab-indicator {
  background-color: #48aff0; }

.pt-flex-expander {
  flex: 1 1; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Tags

Markup:
<p>
  <span class="pt-tag {{.modifier}}">125</span>
  <span class="pt-tag {{.modifier}}">Done</span>
  <span class="pt-tag pt-tag-removable {{.modifier}}">
    Tracking
    <button class="pt-tag-remove"></button>
  </span>
  <span class="pt-tag pt-tag-removable {{.modifier}}">
    Crushed
    <button class="pt-tag-remove"></button>
  </span>
</p>
<span class="pt-tag pt-tag-removable {{.modifier}}">
  A rather long string of text that wraps to multiple lines
  demonstrates the position of the remove button.
  <button class="pt-tag-remove"></button>
</span>

.pt-large - Large
.pt-round - Rounded corners, ideal for badges
.pt-intent-primary - Primary intent
.pt-intent-success - Success intent
.pt-intent-warning - Warning intent
.pt-intent-danger  - Danger intent

Styleguide pt-tag
*/
.pt-tag {
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  background-color: #5c7080;
  min-width: 20px;
  padding: 2px 6px;
  line-height: 16px;
  color: #f5f8fa;
  font-size: 12px;
  /*
  Minimal tags

  Markup:
  <div class="pt-tag pt-minimal {{.modifier}}">125</div>
  <div class="pt-tag pt-minimal {{.modifier}}">Done</div>
  <div class="pt-tag pt-minimal pt-tag-removable {{.modifier}}">
    Tracking
    <button class="pt-tag-remove"></button>
  </div>

  .pt-large - Large
  .pt-round - Rounded corners, ideal for badges
  .pt-intent-primary - Primary intent
  .pt-intent-success - Success intent
  .pt-intent-warning - Warning intent
  .pt-intent-danger  - Danger intent

  Styleguide pt-tag.pt-minimal
  */ }
  .pt-tag.pt-round {
    border-radius: 10px; }
  .pt-dark .pt-tag {
    background-color: #bfccd6;
    color: #182026; }
  .pt-tag.pt-tag-removable {
    padding-right: 20px; }
  .pt-tag.pt-active {
    outline: rgba(19, 124, 189, 0.5) auto 2px;
    outline-offset: 0;
    -moz-outline-radius: 6px; }
  .pt-tag.pt-large,
  .pt-large .pt-tag {
    min-width: 30px;
    padding: 5px 10px;
    line-height: 20px;
    font-size: 14px; }
    .pt-tag.pt-large.pt-round,
    .pt-large .pt-tag.pt-round {
      border-radius: 15px; }
    .pt-tag.pt-large.pt-tag-removable,
    .pt-large .pt-tag.pt-tag-removable {
      padding-right: 30px; }
  .pt-tag.pt-intent-primary {
    background: #137cbd;
    color: #ffffff; }
  .pt-tag.pt-intent-success {
    background: #0f9960;
    color: #ffffff; }
  .pt-tag.pt-intent-warning {
    background: #d9822b;
    color: #ffffff; }
  .pt-tag.pt-intent-danger {
    background: #db3737;
    color: #ffffff; }
  .pt-tag.pt-minimal:not([class*="pt-intent-"]) {
    background-color: rgba(138, 155, 168, 0.2);
    color: #182026; }
    .pt-dark .pt-tag.pt-minimal:not([class*="pt-intent-"]) {
      color: #f5f8fa; }
  .pt-tag.pt-minimal.pt-intent-primary {
    background-color: rgba(19, 124, 189, 0.15);
    color: #106ba3; }
    .pt-dark .pt-tag.pt-minimal.pt-intent-primary {
      background-color: rgba(19, 124, 189, 0.25);
      color: #48aff0; }
  .pt-tag.pt-minimal.pt-intent-success {
    background-color: rgba(15, 153, 96, 0.15);
    color: #0d8050; }
    .pt-dark .pt-tag.pt-minimal.pt-intent-success {
      background-color: rgba(15, 153, 96, 0.25);
      color: #3dcc91; }
  .pt-tag.pt-minimal.pt-intent-warning {
    background-color: rgba(217, 130, 43, 0.15);
    color: #bf7326; }
    .pt-dark .pt-tag.pt-minimal.pt-intent-warning {
      background-color: rgba(217, 130, 43, 0.25);
      color: #ffb366; }
  .pt-tag.pt-minimal.pt-intent-danger {
    background-color: rgba(219, 55, 55, 0.15);
    color: #c23030; }
    .pt-dark .pt-tag.pt-minimal.pt-intent-danger {
      background-color: rgba(219, 55, 55, 0.25);
      color: #ff7373; }

.pt-tag-remove {
  line-height: 1;
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  border: none;
  background: none;
  cursor: pointer;
  padding: 2px;
  color: inherit; }
  .pt-tag-remove:hover {
    opacity: 0.8;
    background: none;
    text-decoration: none; }
  .pt-tag-remove:active {
    opacity: 1; }
  .pt-tag-remove::before {
    content: ""; }
  .pt-large .pt-tag-remove {
    line-height: 1;
    font-family: "Icons20", sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    padding: 5px; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.pt-toast {
  display: flex;
  align-items: flex-start;
  position: relative !important;
  margin: 20px 0 0;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  background-color: #ffffff;
  min-width: 300px;
  max-width: 500px;
  pointer-events: all; }
  .pt-toast.pt-toast-enter, .pt-toast.pt-toast-appear {
    transform: translateY(-40px); }
  .pt-toast.pt-toast-enter-active, .pt-toast.pt-toast-appear-active {
    transform: translateY(0);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-toast.pt-toast-enter ~ .pt-toast, .pt-toast.pt-toast-appear ~ .pt-toast {
    transform: translateY(-40px); }
  .pt-toast.pt-toast-enter-active ~ .pt-toast, .pt-toast.pt-toast-appear-active ~ .pt-toast {
    transform: translateY(0);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
    transition-delay: 0; }
  .pt-toast.pt-toast-leave {
    opacity: 1;
    filter: blur(0); }
  .pt-toast.pt-toast-leave-active {
    opacity: 0;
    filter: blur(10px);
    transition-property: opacity, filter;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-toast.pt-toast-leave ~ .pt-toast {
    transform: translateY(0); }
  .pt-toast.pt-toast-leave-active ~ .pt-toast {
    transform: translateY(-40px);
    transition-property: transform;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 50ms; }
  .pt-toast .pt-button-group {
    flex: 0 0 auto;
    padding: 5px;
    padding-left: 0; }
  .pt-toast > .pt-icon-standard {
    padding: 12px;
    padding-right: 0;
    color: #5c7080; }
  .pt-toast.pt-dark,
  .pt-dark .pt-toast {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
    background-color: #394b59; }
    .pt-toast.pt-dark > .pt-icon-standard,
    .pt-dark .pt-toast > .pt-icon-standard {
      color: #bfccd6; }
  .pt-toast[class*="pt-intent-"] a {
    color: rgba(255, 255, 255, 0.7); }
    .pt-toast[class*="pt-intent-"] a:hover {
      color: #ffffff; }
  .pt-toast[class*="pt-intent-"] > .pt-icon-standard {
    color: #ffffff; }
  .pt-toast[class*="pt-intent-"] .pt-button, .pt-toast[class*="pt-intent-"] .pt-button::before, .pt-toast[class*="pt-intent-"] .pt-button:active {
    color: rgba(255, 255, 255, 0.7) !important; }
  .pt-toast[class*="pt-intent-"] .pt-button:focus {
    outline-color: rgba(255, 255, 255, 0.5); }
  .pt-toast[class*="pt-intent-"] .pt-button:hover {
    background-color: rgba(255, 255, 255, 0.15) !important;
    color: #ffffff !important; }
  .pt-toast[class*="pt-intent-"] .pt-button:active {
    background-color: rgba(255, 255, 255, 0.3) !important;
    color: #ffffff !important; }
  .pt-toast[class*="pt-intent-"] .pt-button::after {
    background: rgba(255, 255, 255, 0.3) !important; }
  .pt-toast.pt-intent-primary {
    background-color: #137cbd;
    color: #ffffff; }
  .pt-toast.pt-intent-success {
    background-color: #0f9960;
    color: #ffffff; }
  .pt-toast.pt-intent-warning {
    background-color: #d9822b;
    color: #ffffff; }
  .pt-toast.pt-intent-danger {
    background-color: #db3737;
    color: #ffffff; }

.pt-toast-message {
  flex: 1 1 auto;
  padding: 11px; }

.pt-toast-container {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 40;
  pointer-events: none; }
  .pt-toast-container > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 0 20px 20px; }
  .pt-toast-container.pt-toast-container-top {
    top: 0;
    bottom: auto; }
  .pt-toast-container.pt-toast-container-bottom {
    top: auto;
    bottom: 0; }
    .pt-toast-container.pt-toast-container-bottom > span {
      flex-direction: column-reverse; }
  .pt-toast-container.pt-toast-container-left > span {
    align-items: flex-start; }
  .pt-toast-container.pt-toast-container-right > span {
    align-items: flex-end; }

.pt-toast-container-bottom .pt-toast.pt-toast-enter:not(.pt-toast-enter-active),
.pt-toast-container-bottom .pt-toast.pt-toast-enter:not(.pt-toast-enter-active) ~ .pt-toast,
.pt-toast-container-bottom .pt-toast.pt-toast-leave-active ~ .pt-toast {
  transform: translateY(60px); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.pt-tooltip {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  transform: scale(1); }
  .pt-tooltip .pt-popover-arrow {
    position: absolute;
    width: 22px;
    height: 22px; }
    .pt-tooltip .pt-popover-arrow::before {
      margin: 4px;
      width: 14px;
      height: 14px; }
  .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-tooltip {
    margin-top: -11px;
    margin-bottom: 11px; }
    .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-tooltip > .pt-popover-arrow {
      bottom: -8px; }
      .pt-tether-element-attached-bottom.pt-tether-target-attached-top > .pt-tooltip > .pt-popover-arrow svg {
        transform: rotate(-90deg); }
  .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-tooltip {
    margin-left: 11px; }
    .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-tooltip > .pt-popover-arrow {
      left: -8px; }
      .pt-tether-element-attached-left.pt-tether-target-attached-right > .pt-tooltip > .pt-popover-arrow svg {
        transform: rotate(0); }
  .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-tooltip {
    margin-top: 11px; }
    .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-tooltip > .pt-popover-arrow {
      top: -8px; }
      .pt-tether-element-attached-top.pt-tether-target-attached-bottom > .pt-tooltip > .pt-popover-arrow svg {
        transform: rotate(90deg); }
  .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-tooltip {
    margin-right: 11px;
    margin-left: -11px; }
    .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-tooltip > .pt-popover-arrow {
      right: -8px; }
      .pt-tether-element-attached-right.pt-tether-target-attached-left > .pt-tooltip > .pt-popover-arrow svg {
        transform: rotate(180deg); }
  .pt-tether-element-attached-middle > .pt-tooltip > .pt-popover-arrow {
    top: 50%;
    transform: translateY(-50%); }
  .pt-tether-element-attached-center > .pt-tooltip > .pt-popover-arrow {
    right: 50%;
    transform: translateX(50%); }
  .pt-tether-element-attached-top.pt-tether-target-attached-top > .pt-tooltip > .pt-popover-arrow {
    top: -0.22183px; }
  .pt-tether-element-attached-right.pt-tether-target-attached-right > .pt-tooltip > .pt-popover-arrow {
    right: -0.22183px; }
  .pt-tether-element-attached-left.pt-tether-target-attached-left > .pt-tooltip > .pt-popover-arrow {
    left: -0.22183px; }
  .pt-tether-element-attached-bottom.pt-tether-target-attached-bottom > .pt-tooltip > .pt-popover-arrow {
    bottom: -0.22183px; }
  .pt-tether-element-attached-top.pt-tether-element-attached-left > .pt-tooltip {
    transform-origin: top left; }
  .pt-tether-element-attached-top.pt-tether-element-attached-center > .pt-tooltip {
    transform-origin: top center; }
  .pt-tether-element-attached-top.pt-tether-element-attached-right > .pt-tooltip {
    transform-origin: top right; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-left > .pt-tooltip {
    transform-origin: center left; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-center > .pt-tooltip {
    transform-origin: center center; }
  .pt-tether-element-attached-middle.pt-tether-element-attached-right > .pt-tooltip {
    transform-origin: center right; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-left > .pt-tooltip {
    transform-origin: bottom left; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-center > .pt-tooltip {
    transform-origin: bottom center; }
  .pt-tether-element-attached-bottom.pt-tether-element-attached-right > .pt-tooltip {
    transform-origin: bottom right; }
  .pt-tooltip .pt-popover-content {
    background: #394b59;
    color: #f5f8fa; }
  .pt-tooltip .pt-popover-arrow::before {
    box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2); }
  .pt-tooltip .pt-popover-arrow-border {
    fill: #10161a;
    fill-opacity: 0.1; }
  .pt-tooltip .pt-popover-arrow-fill {
    fill: #394b59; }
  .pt-popover-enter > .pt-tooltip, .pt-popover-appear > .pt-tooltip {
    transform: scale(0.8); }
  .pt-popover-enter-active > .pt-tooltip, .pt-popover-appear-active > .pt-tooltip {
    transform: scale(1);
    transition-property: transform;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-popover-leave > .pt-tooltip {
    transform: scale(1); }
  .pt-popover-leave-active > .pt-tooltip {
    transform: scale(0.8);
    transition-property: transform;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .pt-tooltip .pt-popover-content {
    padding: 10px 12px; }
  .pt-tooltip.pt-dark,
  .pt-dark .pt-tooltip {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .pt-tooltip.pt-dark .pt-popover-content,
    .pt-dark .pt-tooltip .pt-popover-content {
      background: #e1e8ed;
      color: #394b59; }
    .pt-tooltip.pt-dark .pt-popover-arrow::before,
    .pt-dark .pt-tooltip .pt-popover-arrow::before {
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4); }
    .pt-tooltip.pt-dark .pt-popover-arrow-border,
    .pt-dark .pt-tooltip .pt-popover-arrow-border {
      fill: #10161a;
      fill-opacity: 0.2; }
    .pt-tooltip.pt-dark .pt-popover-arrow-fill,
    .pt-dark .pt-tooltip .pt-popover-arrow-fill {
      fill: #e1e8ed; }
  .pt-tooltip.pt-intent-primary .pt-popover-content {
    background: #137cbd;
    color: #ffffff; }
  .pt-tooltip.pt-intent-primary .pt-popover-arrow-fill {
    fill: #137cbd; }
  .pt-tooltip.pt-intent-success .pt-popover-content {
    background: #0f9960;
    color: #ffffff; }
  .pt-tooltip.pt-intent-success .pt-popover-arrow-fill {
    fill: #0f9960; }
  .pt-tooltip.pt-intent-warning .pt-popover-content {
    background: #d9822b;
    color: #ffffff; }
  .pt-tooltip.pt-intent-warning .pt-popover-arrow-fill {
    fill: #d9822b; }
  .pt-tooltip.pt-intent-danger .pt-popover-content {
    background: #db3737;
    color: #ffffff; }
  .pt-tooltip.pt-intent-danger .pt-popover-arrow-fill {
    fill: #db3737; }

.pt-popover-target .pt-tooltip {
  white-space: nowrap; }

.pt-tooltip-indicator {
  border-bottom: dotted 1px;
  cursor: help; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 */
/*
Trees

Markup:
<div class="pt-tree pt-elevation-0">
  <ul class="pt-tree-node-list pt-tree-root">
    <li class="pt-tree-node pt-tree-node-expanded">
      <div class="pt-tree-node-content">
        <span class="pt-tree-node-caret pt-tree-node-caret-open pt-icon-standard"></span>
        <span class="pt-tree-node-icon pt-icon-standard pt-icon-folder-close"></span>
        <span class="pt-tree-node-label">Label</span>
        <span class="pt-tree-node-secondary-label">Secondary label</span>
      </div>
      <ul class="pt-tree-node-list">
        <li class="pt-tree-node">
          <div class="pt-tree-node-content">
            <span class="pt-tree-node-caret-none pt-icon-standard"></span>
            <span class="pt-tree-node-icon pt-icon-standard pt-icon-document"></span>
          <span class="pt-tree-node-label">A Document</span>
          </div>
        </li>
        <li class="pt-tree-node">
          <div class="pt-tree-node-content">
            <span class="pt-tree-node-caret-none pt-icon-standard"></span>
            <span class="pt-tree-node-icon pt-icon-standard pt-icon-document"></span>
            <span class="pt-tree-node-label">Another Document</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>

Styleguide pt-tree
*/
.pt-tree-node-list {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.pt-tree-root {
  position: relative;
  background-color: transparent;
  cursor: default;
  padding-left: 0; }

.pt-tree-node-content-0 {
  padding-left: 0px; }

.pt-tree-node-content-1 {
  padding-left: 23px; }

.pt-tree-node-content-2 {
  padding-left: 46px; }

.pt-tree-node-content-3 {
  padding-left: 69px; }

.pt-tree-node-content-4 {
  padding-left: 92px; }

.pt-tree-node-content-5 {
  padding-left: 115px; }

.pt-tree-node-content-6 {
  padding-left: 138px; }

.pt-tree-node-content-7 {
  padding-left: 161px; }

.pt-tree-node-content-8 {
  padding-left: 184px; }

.pt-tree-node-content-9 {
  padding-left: 207px; }

.pt-tree-node-content-10 {
  padding-left: 230px; }

.pt-tree-node-content-11 {
  padding-left: 253px; }

.pt-tree-node-content-12 {
  padding-left: 276px; }

.pt-tree-node-content-13 {
  padding-left: 299px; }

.pt-tree-node-content-14 {
  padding-left: 322px; }

.pt-tree-node-content-15 {
  padding-left: 345px; }

.pt-tree-node-content-16 {
  padding-left: 368px; }

.pt-tree-node-content-17 {
  padding-left: 391px; }

.pt-tree-node-content-18 {
  padding-left: 414px; }

.pt-tree-node-content-19 {
  padding-left: 437px; }

.pt-tree-node-content-20 {
  padding-left: 460px; }

.pt-tree-node-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-right: 5px; }
  .pt-tree-node-content:hover {
    background-color: rgba(191, 204, 214, 0.4); }

.pt-tree-node-caret,
.pt-tree-node-caret-none {
  position: relative;
  min-width: 30px;
  line-height: 30px !important; }

.pt-tree-node-caret {
  color: #5c7080;
  cursor: pointer;
  text-align: center; }
  .pt-tree-node-caret:hover {
    color: #182026; }
  .pt-dark .pt-tree-node-caret {
    color: #bfccd6; }
    .pt-dark .pt-tree-node-caret:hover {
      color: #f5f8fa; }
  .pt-tree-node-caret::before {
    display: inline-block;
    content: "";
    transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-tree-node-caret.pt-tree-node-caret-open::before {
    transform: rotate(90deg); }

.pt-tree-node-icon {
  position: relative;
  margin-right: 7px;
  color: #5c7080; }

.pt-tree-node-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 1 1 auto;
  position: relative;
  user-select: none; }
  .pt-tree-node-label span {
    display: inline; }

.pt-tree-node-secondary-label {
  padding: 0 5px;
  line-height: 30px;
  user-select: none; }

.pt-tree-node.pt-tree-node-selected > .pt-tree-node-content {
  background-color: #137cbd; }
  .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content,
  .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content .pt-icon, .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content .pt-icon-standard, .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content .pt-icon-large {
    color: #ffffff; }
  .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content .pt-tree-node-caret::before {
    color: rgba(255, 255, 255, 0.7); }
  .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content .pt-tree-node-caret:hover::before {
    color: #ffffff; }

.pt-dark .pt-tree-node-content:hover {
  background-color: rgba(92, 112, 128, 0.3); }

.pt-dark .pt-tree-node-icon {
  color: #bfccd6; }

.pt-dark .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content {
  background-color: #137cbd; }
